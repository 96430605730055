import axios, { AxiosResponse } from "axios";
import { SERVER_URL } from "../configuration";
import { UserObj } from "models/user";
import { getDeviceName, getClientUniqueId } from "shared/dataUtils";
import { DEBUG } from "configuration";
import Cookies from "js-cookie";

/**
 * We will use this call to first check if the upload link is not expired nor disabled
 * @returns
 */

export interface UploadLinkValidityCheckResult {
  valid: boolean;
  error: UploadLinkInvalidErrors | null;
}

export enum UploadLinkInvalidErrors {
  UPLOAD_LINK_EXPIRED = 1,
  UPLOAD_LINK_DISABLED = 2,
}

export const checkUploadLinkStatus = async (
  token: string
): Promise<UploadLinkValidityCheckResult> => {
  if (DEBUG) {
    console.log("checkUploadLinkStatus: ");
  }
  // const body = JSON.stringify({ token: token });
  try {
    let res = await axios.get(SERVER_URL + "/check-link/" + token, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (DEBUG) {
      console.log("Got user data is " + JSON.stringify(res));
    }
    return {
      valid: res.data.valid,
      error: res.data.error,
    };
  } catch (e) {
    throw e;
  }
};
