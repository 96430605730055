import React, { useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
//import DataUsageProgressBar from "components/DataUsageProgress/DataUsageProgressBar";
import {
  getUsageInHumanReadableFormat,
  getStartOfNextMonth,
  getDateFromIso8601,
  getDateAndTimeFromIso8601,
  getMonthFromMonthNumber,
  containsOnlyLettersNumbersUnderscore,
  isUsernameValid,
} from "shared/dataUtils";
import { UserObj, getUserInitials } from "models/user";
import { Theme, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import Link from "@mui/material/Link";
//import DeleteAccountConfirmationDialog from "components/UI/Modals/DeleteAccountConfirmationDialog";
import Avatar from "@mui/material/Avatar";
//import { getUserAvatarForEmail, getUserAvatarForId } from "configuration";
import { stringToColour } from "shared/colors";
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { useMutation } from "@apollo/client";
import { RootState } from "store";
import { getUserAvatarForId } from "configuration";
import DeleteAccountConfirmationDialog from "components/ui/dialogs/DeleteAccountConfirmationDialog";
import UploadProfileImage from "pages/user/UploadProfileImage";
import { updateUserInfoGql } from "graphql/mutations";
import { loginUser } from "store/auth";
import { showSnackbar } from "store/view";
import { LoadingButton } from "@mui/lab";
//import { updateUsernameGql } from "graphql/mutations";
//import UploadProfileImage from "containers/User/UploadProfileImage";

const MenuContainer = styled.div`
  display: flex;
  padding-bottom: 16px;
  flex: 1 1 auto;

  flex-direction: column;
  justify-content: space-between;
  padding-left: 48px;
  padding-right: 48px;
  @media (max-width: 780px) {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  subscriptionPlanContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  userFullNameContainer: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    margintop: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  userFullNameWithAvatarContainerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dataUsageContainer: {
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  avatarNotHovered: {
    transform: "scale(1)",
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: 200,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "10px",
      height: "10px",
    },
  },
  boxSectionContiner: {
    display: "flex",
    flexDirection: "column",

    textAlign: "start",
    width: "50%",
  },

  deleteAccountContainer: {
    padding: 0,
    display: "flex",
    marginTop: "36px",
    flexDirection: "row",
    width: "60%",
    cursor: "pointer",
    "& .MuiLink-root": {
      color: "#123456",
    },
  },
}));

const General = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [updateUserInfo, updateUserInfoResult] = useMutation(updateUserInfoGql);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  const [firstNameInputValue, setFirstNameInputValue] = useState<string | null>(
    null
  );
  const [lastNameInputValue, setLastNameInputValue] = useState<string | null>(
    null
  );
  const [enableSave, setEnableSave] = useState(false);

  const user: any = useSelector((state: RootState) => state.auth.user);
  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = React.useState(
    false
  );
  const [userAvatarUrl, setUserAvatarUrl] = React.useState("");

  const [editProfilePictureSrc, setEditProfilePicture] = React.useState<
    string | undefined
  >(undefined);

  function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setEditProfilePicture(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  React.useEffect(() => {
    if (user) {
      setUserAvatarUrl(getUserAvatarForId(user.id));
      if (!firstNameInputValue && !lastNameInputValue) {
        setFirstNameInputValue(user?.firstName);
        setLastNameInputValue(user?.lastName);
      }
    }
  }, [user]);

  const editUsernameTextFieldRef = React.useRef(null);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const avatarColor = stringToColour(user?.username);

  const handleSave = useCallback(() => {
    updateUserInfo({
      variables: {
        userInfo: {
          firstName:
            firstNameInputValue != user.firstName
              ? firstNameInputValue
              : undefined,
          lastName:
            firstNameInputValue != user.lastName
              ? lastNameInputValue
              : undefined,
        },
      },
    }).then((result) => {
      if (result.data.updateUserInfo.result) {
        dispatch(
          loginUser({
            ...user,
            firstName: user.firstName,
            lastName: user.lastName,
          })
        );
        dispatch(
          showSnackbar({
            message: t("update_successful_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      }
    });
  }, [user, firstNameInputValue, lastNameInputValue]);

  if (!user) {
    return (
      <>
        <MenuContainer>
          {/*  <Skeleton height="36px" width="60%" />
          <Skeleton height="36px" width="20%" /> */}
          <Box>
            <Box className={classes.userFullNameWithAvatarContainerContainer}>
              <Skeleton variant="circular" height="96px" width="96px" />
            </Box>
            <Box className={classes.userFullNameContainer}>
              <Skeleton height="36px" width="20%" />
              <Skeleton height="36px" width="40%" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: theme.spacing(2),
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Skeleton height="36px" width="20%" />
                  <Skeleton height="36px" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    marginLeft: theme.spacing(1),
                  }}
                >
                  <Skeleton height="36px" width="20%" />
                  <Skeleton height="36px" width="100%" />
                </Box>
              </Box>
            </Box>
          </Box>
        </MenuContainer>
      </>
    );
  } else {
    return (
      <MenuContainer>
        <Box>
          <Box className={classes.userFullNameWithAvatarContainerContainer}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Avatar
                //onClick={handleClick}
                className={classes.avatarNotHovered}
                color="red"
                src={userAvatarUrl}
                style={{
                  border: "thick double #fff",
                  fontSize: "3rem",
                  width: "96px",
                  height: "96px",
                  cursor: "pointer",
                  //filter: avatarFilter,
                  backgroundColor: avatarColor?.backgroundColor,
                  color: avatarColor?.textColor,
                  // backgroundColor: theme.palette.primary.main,
                }}
              >
                {getUserInitials(user).toUpperCase()}
              </Avatar>
              <input
                ref={hiddenFileInput}
                accept="image/*"
                onChange={onFileInputChange}
                type="file"
                style={{ display: "none" }}
              />
              <Button
                onClick={() => {
                  //console.log("image click %o", hiddenFileInput)
                  hiddenFileInput?.current?.click();
                }}
                sx={{
                  color: "grey",
                }}
              >
                {t("edit_action")}
              </Button>
            </Box>
          </Box>
          <Box className={classes.userFullNameContainer}>
            <Typography
              sx={{
                fontWeight: "bold",
                marginTop: theme.spacing(2),
              }}
            >
              {t("email")}
            </Typography>
            <Typography color="text.secondary">{user?.email}</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: theme.spacing(2),
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {t("first_name")}
                </Typography>
                <TextField
                  inputRef={firstNameRef}
                  placeholder={t("first_name")}
                  value={firstNameInputValue}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    setEnableSave(value && value.length > 0);

                    setFirstNameInputValue(event.target.value);
                  }}
                  fullWidth
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginLeft: theme.spacing(1),
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {t("last_name")}
                </Typography>
                <TextField
                  inputRef={lastNameRef}
                  value={lastNameInputValue}
                  placeholder={t("last_name")}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    setEnableSave(value && value.length > 0);
                    setLastNameInputValue(event.target.value);
                  }}
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
          <Link
            style={{ color: "#123456", marginTop: "48px" }}
            className={classes.deleteAccountContainer}
            onClick={() => {
              setOpenDeleteAccountDialog(true);
            }}
          >
            {t("delete_my_account_title")}
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "60%",
            justifyContent: "end",
          }}
        >
          <LoadingButton
            variant="contained"
            sx={{
              height: "auto",
              width: "auto",
            }}
            disabled={!enableSave}
            loading={updateUserInfoResult.loading}
            onClick={handleSave}
          >
            {t("save_action")}
          </LoadingButton>
        </Box>
        {openDeleteAccountDialog && (
          <DeleteAccountConfirmationDialog
            open={openDeleteAccountDialog}
            handleModalClose={(close: boolean) => {
              setOpenDeleteAccountDialog(false);
            }}
          />
        )}
        <UploadProfileImage
          open={Boolean(editProfilePictureSrc)}
          handleModalClose={(imgChanged) => {
            setEditProfilePicture(undefined);
            if (imgChanged) {
              setUserAvatarUrl(getUserAvatarForId(user?.id) + "?" + Date.now());
            }
          }}
          src={editProfilePictureSrc}
        />
      </MenuContainer>
    );
  }
};

export default React.memo(General);
