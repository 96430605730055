import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import UploadedFilesContainer from "./UploadedFilesContainer";

const AllUploadedItems = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        px: theme.spacing(8),
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "text.secondary",
          }}
        >
          {t("uploaded_files_title")}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: "1",
          overflow: "hidden",
        }}
      >
        <UploadedFilesContainer />
      </Box>
    </Box>
  );
};

export default React.memo(AllUploadedItems);
