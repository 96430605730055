import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import NotFoundIllustration from "res/illustrations/illustration_not_found.svg";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={NotFoundIllustration}
        style={{
          maxWidth: "500px",
          width: "auto",
          height: "auto",
          display: "block",
        }}
      />
      <Typography
        sx={{
          color: "gray",
          marginTop: "16px"
        }}
      >
        {t("page_not_found_title")}
      </Typography>
    </Box>
  );
};

export default React.memo(PageNotFound);
