import { useQuery } from "@apollo/client";
import { Box, IconButton, Link, Typography, useTheme } from "@mui/material";
import CopyButtonToolTipped from "components/ui/CopyButtonToolTipped";
import {
  DEBUG,
  getEmailUploadItemPreviewLink,
  getInternalFileAccessLink,
  getUploadItemPreviewLink,
} from "configuration";
import { BaseUploadItem } from "models/uploadly";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  formatIso8601date,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ClearIcon from "@mui/icons-material/Clear";
import UploadItemDetailMenu from "components/ui/menu/UploadItemDetailMenu";

interface UploadItemDetailContainerProps {
  uploadItem: BaseUploadItem;
  isEmailUploadItem?: boolean;
  onClose: () => void;
}

const UploadItemDetailContainer = ({
  uploadItem,
  isEmailUploadItem = false,
  onClose,
}: UploadItemDetailContainerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  /// const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>();
  const anchorRef = useRef<any>();
  // const [openMenu, setOpenMenu] = useState(false);
  const [anchorElement, setAnchorElement] = useState<any>(null);

  React.useEffect(() => {}, [uploadItem]);
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "top",
        width: "100%",
        overflowY: "auto",
        px: theme.spacing(3),
        py: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "gray",
            wordWrap: "break-word",
            width: "80%",
          }}
        >
          {uploadItem.fileName}
        </Typography>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <IconButton
            // ref={anchorRef}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              //  setMenuAnchor(event.currentTarget);
              anchorRef.current = event.currentTarget;
              setAnchorElement(event.currentTarget);
              //  setOpenMenu(true);
            }}
          >
            <MoreHorizIcon
              sx={{
                height: "24px",
                width: "24px",
              }}
            />
          </IconButton>
          {anchorElement && (
            <UploadItemDetailMenu
              uploadItem={uploadItem}
              isEmailUploadItem={isEmailUploadItem}
              onClose={() => {
                setAnchorElement(null);
              }}
              anchorElement={anchorElement}
              open={Boolean(anchorElement)}
              closeParent={() => {
                onClose();
              }}
            />
          )}

          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <ClearIcon
              sx={{
                height: "24px",
                width: "24px",
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "gray",
          }}
        >
          {getSizeInHumeanReadableFormat(uploadItem.fileSize)}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            marginLeft: theme.spacing(1),
            color: "gray",
          }}
        >
          •
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: "gray",
            marginLeft: theme.spacing(1),
          }}
        >
          {t("uploaded_at", { date: formatIso8601date(uploadItem.createdAt) })}
        </Typography>
      </Box>
      {uploadItem.fileUrl && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Link
            component="button"
            variant="body2"
            sx={{
              textAlign: "start",
              display: "flex",
              wordBreak: "break-word",
            }}
            onClick={() => {
              window.open(uploadItem.fileUrl, "_blank");
            }}
          >
            {uploadItem.fileUrl}
          </Link>

          <CopyButtonToolTipped
            sx={{
              marginLeft: theme.spacing(0.5),
            }}
            onClick={() => {
              navigator.clipboard.writeText(uploadItem.fileUrl);
            }}
          />
        </Box>
      )}
      {uploadItem.fileType.startsWith("image/") &&
        uploadItem.internalFileUpload && (
          <img
            style={{
              width: "100%",
            }}
            src={getInternalFileAccessLink(uploadItem.internalFileUpload.id)}
          ></img>
        )}
      {/*     {uploadItem.fileType.startsWith("application/pdf") &&
        uploadItem.internalFileUpload && (
          <img
            style={{
              width: "100%",
            }}
            src={getUploadItemPreviewLink(uploadItem.id)}
          ></img>
        )}
 */}
      {/*       {uploadItem.internalFileUpload &&
        uploadItem.hasPreview &&
        !uploadItem.fileType.startsWith("application/pdf") && (
          <img
            style={{
              width: "100%",
              maxHeight: "250px",
              objectFit: "cover",
            }}
            src={getUploadItemPreviewLink(uploadItem.id)}
            loading="lazy"
          ></img>
        )} */}
      {uploadItem.internalFileUpload &&
        uploadItem.hasPreview &&
        uploadItem.fileType.startsWith("application/pdf") && (
          <Box
            sx={{
              width: "100%",
              maxHeight: "250px",
              background: "#faf6e9",
              display: "flex",
              justifyContent: "center",
              paddingTop: "12px",
            }}
          >
            <img
              style={{
                width: "70%",
              }}
              src={
                isEmailUploadItem
                  ? getEmailUploadItemPreviewLink(uploadItem.id)
                  : getUploadItemPreviewLink(uploadItem.id)
              }
              loading="lazy"
            ></img>
          </Box>
        )}
      {uploadItem.fileType.startsWith("video/") &&
        uploadItem.internalFileUpload && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxHeight: "70%",
            }}
          >
            <video
              key={uploadItem.internalFileUpload.id}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              src={
                isEmailUploadItem
                  ? getEmailUploadItemPreviewLink(uploadItem.id)
                  : getUploadItemPreviewLink(uploadItem.id)
              }
              controls
            >
              <source
                src={getInternalFileAccessLink(
                  uploadItem.internalFileUpload.id
                )}
              />
            </video>
          </Box>
        )}
      <Box
        sx={{
          marginTop: theme.spacing(3),
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {!isEmailUploadItem && (
          <>
            <Typography
              variant="subtitle2"
              sx={{
                color: "gray",
              }}
            >
              {t("uploaded_by_title")}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                color: "gray",
              }}
            >
              {uploadItem.uploader
                ? uploadItem.uploader.firstName +
                  " " +
                  uploadItem.uploader.lastName
                : t("anonymous_user_title")}
            </Typography>
          </>
        )}
        {uploadItem.uploader && uploadItem.uploader?.email && (
          <Typography
            variant="caption"
            sx={{
              color: "gray",
            }}
          >
            {uploadItem.uploader?.email}
          </Typography>
        )}
      </Box>
      {uploadItem.uploader && uploadItem.uploader.message && (
        <Box
          sx={{
            marginTop: theme.spacing(3),
            // px: theme.spacing(4),
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: "gray",
              fontWeight: "bold",
            }}
          >
            {t("message_title")}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: "gray",
            }}
          >
            {uploadItem.uploader.message}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          marginTop: theme.spacing(3),
          // px: theme.spacing(4),
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: "gray",
            fontWeight: "bold",
          }}
        >
          {t("file_type_title")}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: "gray",
          }}
        >
          {uploadItem.fileType}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(UploadItemDetailContainer);
