import { DEBUG, DROPBOX_AUTH_REDIRECT_URL, DROPBOX_CLIENT_ID, ONEDRIVE_AUTH_REDIRECT_URL, ONEDRIVE_CLIENT_ID } from "configuration";
import { useCallback, useEffect, useState } from "react";


export function useOneDropboxAuthPopup(): [() => void, string | null] {
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [externalPopup, setExternalPopup] = useState<any>(null);

  const showPopup = useCallback(() => {
    const clientId = DROPBOX_CLIENT_ID;
    const redirectUri = DROPBOX_AUTH_REDIRECT_URL;
    const authUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${clientId}&token_access_type=offline&response_type=code&redirect_uri=${redirectUri}`;
    var popupWidth = 500;
    var popupHeight = 600;
    var left = window.screenX + (window.outerWidth - popupWidth) / 2;
    var top = window.screenY + (window.outerHeight - popupHeight) / 2.5;
    const title = `Auth`;
    const popup = window.open(
      authUrl,
      title,
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  }, [setExternalPopup]);

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("code");
        if (code) {
          externalPopup.close();
          if (DEBUG) {
            console.log(`The popup URL has URL code param = ${code}`);
          }
          setExternalPopup(null);
          clearInterval(timer);
          setAuthCode(code);
        }
      } catch (e) {
        console.log("Caught an exception! %o", e);
      }
    }, 500);
  }, [externalPopup]);

  return [showPopup, authCode];
}
