import React from "react";
import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { SubscriptionPlanIds, UserObj } from "models/user";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { logout, LogoutApiResult, LogoutStatusCodes } from "api/authApi";
import { useDispatch } from "react-redux";
import { logoutUser as logoutReducerAction } from "store/auth";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material/styles";
import BoltIcon from "@mui/icons-material/Bolt";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";
import { GENERAL_SETTINGS, PRICING, SETTINGS } from "navigation/Constants";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    // width: "100%",
    width: 400,
    // boxShadow: "0 1px 5px 0 rgba(60, 64, 67, 0.302)",
    zIndex: 1005,
  },

  progressbar: {
    width: "100%",
  },
}));

function ListItemLink(props: any) {
  return <ListItem button component="a" {...props} />;
}

interface PorpertyInterface {
  closeMenu: () => void;
}

export default function FloatingUserMenu(props: PorpertyInterface) {
  const classes = useStyles();
  const userObj = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useNavigate();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    logout().then((result: LogoutApiResult) => {
      if (result.statusCode == LogoutStatusCodes.SUCCESS) {
        dispatch(logoutReducerAction());
      }
    });
  }, []);

  const getSubscriptionPlanTitle = useCallback(() => {
    if (userObj?.subscriptionPlan.id == SubscriptionPlanIds.FREE) {
      return t("tier_free_full_title");
    } else if (userObj?.subscriptionPlan.id == SubscriptionPlanIds.STANDARD) {
      return t("tier_standard_full_title");
    } else if (userObj?.subscriptionPlan.id == SubscriptionPlanIds.BUSINESS) {
      return t("tier_business_full_title");
    } else {
      return "";
    }
    // return "dummy subscription";
  }, [userObj]);

  const handleUpgradeButtonClicked = useCallback(() => {
    navigate(PRICING);
  }, [history]);

  const handleGoToSettings = useCallback(() => {
    navigate(GENERAL_SETTINGS);
  }, []);

  //console.log("Usermomo %o" , userObj)
  return (
    <ClickAwayListener onClickAway={props.closeMenu}>
      <Paper
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
        }}
        className={classes.root}
      >
        <List component="nav" aria-label="main mailbox folders">
          <ListItem>
            <ListItemText
              secondary={getSubscriptionPlanTitle()}
              primary={userObj?.firstName + " " + userObj?.lastName}
              primaryTypographyProps={{
                variant: "h5",
              }}
            />
            {userObj && userObj.subscriptionPlan.id != SubscriptionPlanIds.BUSINESS && (
              <div style={{ textAlign: "center" }}>
                <IconButton
                  onClick={handleUpgradeButtonClicked}
                  aria-label="delete"
                >
                  <BoltIcon
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  ></BoltIcon>
                </IconButton>
              </div>
            )}
          </ListItem>
          {/*  <ListItem>
            <DataUsageProgressBar />
          </ListItem> */}
        </List>
        <Divider />
        <List component="nav" aria-label="secondary mailbox folders">
          <ListItemLink onClick={handleGoToSettings}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t("settings_action_title")} />
          </ListItemLink>
          <ListItemLink onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t("logout_action_title")} />
          </ListItemLink>
        </List>
      </Paper>
    </ClickAwayListener>
  );
}
