import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Typography, useTheme } from "@mui/material";
import { Link as TextLink } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import Header from "components/ui/Header";
import { fetchUserData } from "api/authApi";
import { UserObj } from "models/user";
import { loginUser, logoutUser } from "store/auth";
import UploadlyIcon from "res/icons/uploadly-logo.svg";

const MainContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding-right: 5%;
  padding-left: 15%;
  margin-bottom: 5%;
  @media (max-width: 780px) {
    padding-left: 8%;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 780px) {
    width: 90%;
  }
`;

const Space = styled.section`
  height: 7%;
`;

const TermsConditions = () => {
  const { t } = useTranslation();
  //const history = useTranslation();

  const theme = useTheme();

  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  React.useEffect(() => {
    if (!user && isUserLoggedIn) {
      fetchUserData()
        .then((user: UserObj) => {
          dispatch(loginUser(user));
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    }
  }, [user]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2)
      }}
    >
      <Helmet>
        <title>{t("terms_and_conditions")} | Uploadly</title>
      </Helmet>
      {isUserLoggedIn && <Header />}
      <img
        style={{ position: "absolute", top: "16px", left: "64px" }}
        height="48px"
        width="48px"
        src={UploadlyIcon}
      />
      <Typography
        variant="h2"
        sx={{
          fontSize: "5rem",
          fontFamily: "Trebuchet MS, sans-serif",
          textAlign: "center",
          marginTop: "5%",
          marginBottom: "10%",
        }}
      >
        Terms and conditions
      </Typography>
      <Space style={{ height: "20%" }} />
      <MainContainer>
        {/* {TEXT_CONTENT.map((content) => {
          return ( 
            <>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "3rem",
                  fontWeight: 530,
                  fontFamily: "Trebuchet MS, sans-serif",
                }}
              >
                {content.title}
              </Typography>
              {content.paragraphs.map((paragraph) => {
                return (
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Trebuchet MS, sans-serif",
                      marginTop: "2%",
                      fontSize: "2rem",
                    }}
                  >
                    {paragraph}
                  </Typography>
                );
              })}
            </>
          );
        })} */}

        <BodyContainer>
          <Typography
            variant="h3"
            sx={{
              fontSize: "1.6rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            <span style={{ background: "white", color: "black" }}>
              Effective from
            </span>{" "}
            July 4th, 2024
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Acceptance of Terms
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Welcome to Uploadly. By accessing or using the Uploadly service, you
            agree to be bound by these Terms of Service ("Terms"). If you do not
            agree to these Terms, please do not use our services.
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Description of Service
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Uploadly allows users to collect files from other users by creating
            an upload link that enables direct file uploads to the link
            creator's cloud storage solution (such as Google Drive, Dropbox, or
            OneDrive). Users may choose to store a copy of the files in
            Uploadly. Our service is subscription-based and uses Stripe for
            payment processing.
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            User Accounts
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Account Creation
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            To use our services, you must create an account and provide accurate
            and complete information. You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            that occur under your account
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Eligibility
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            You must be at least 13 years old to use Uploadly. By using our
            services, you represent and warrant that you meet this age
            requirement.
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Subscription and Payments
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Uploadly offers various subscription plans, including a freemium
            model with limited features. Details of the subscription plans and
            pricing can be found on our website.
            <br />
            Payments are processed through Stripe. By subscribing to our
            services, you agree to Stripe's terms and conditions. We do not
            store your payment information on our servers.
            <br />
            You may cancel your subscription at any time. Upon cancellation, you
            will retain access to your subscription features until the end of
            the current billing cycle. Refunds are not provided except as
            required by law.
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            User Conduct{" "}
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Prohibited Activities
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            You agree not to use Uploadly to: <br />
            • Upload or share any content that is illegal, harmful, or infringes
            on the rights of others.
            <br />
            • Engage in any activity that interferes with or disrupts our
            services.
            <br /> • Attempt to gain unauthorized access to our systems or other
            users' accounts.
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Responsibility for Content
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            You are solely responsible for the content you upload or share
            through Uploadly. We do not monitor or control the content uploaded
            by users, but we reserve the right to remove any content that
            violates these Terms.
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Privacy
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Your use of Uploadly is also governed by our Privacy Policy, which
            outlines how we collect, use, and protect your information. By using
            our services, you consent to our data practices as described in the
            Privacy Policy.
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Third-Party Services
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Uploadly integrates with Google Drive, OneDrive, and Dropbox. By
            using these integrations, you agree to the respective third-party
            service terms and privacy policies.
            <br />
            We securely store access tokens and necessary information to
            interact with third-party APIs. This allows us to upload files to
            your cloud storage on your behalf. You can revoke our access at any
            time through your cloud storage account settings.
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Intellectual Property
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            All content and materials provided by Uploadly, including but not
            limited to text, graphics, logos, and software, are the property of
            Uploadly or its licensors and are protected by intellectual property
            laws. You agree not to copy, modify, distribute, or create
            derivative works of our content without our express written
            permission.
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Disclaimers and Limitation of Liability
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            Uploadly is provided on an "as-is" and "as-available" basis. We make
            no warranties, express or implied, regarding the availability,
            accuracy, or reliability of our services.
            <br />
            To the maximum extent permitted by law, Uploadly shall not be liable
            for any indirect, incidental, special, consequential, or punitive
            damages, or any loss of profits or revenues, whether incurred
            directly or indirectly, or any loss of data, use, goodwill, or other
            intangible losses resulting from:
            <br />
            • Your use or inability to use our services. <br />
            • Any unauthorized access to or use of our servers and/or any
            personal information stored therein. <br />
            • Any interruption or cessation of transmission to or from our
            services. <br />
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Indemnification
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            You agree to indemnify and hold Uploadly, its affiliates, officers,
            agents, and employees harmless from any claims, damages, losses,
            liabilities, and expenses (including attorney's fees) arising out of
            your use of our services, your violation of these Terms, or your
            violation of any rights of another.
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Changes to These Terms
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            We may update these Terms from time to time. If we make significant
            changes, we will notify you by email or through a notice on our
            website. Your continued use of our services after such changes
            constitutes your acceptance of the new Terms.
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Contact Us
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            If you have any questions or concerns about these Terms, please
            contact us at:
            <br />
            <br />
            • Email: support@uploadly.io
            <br />
            Thank you for using Uploadly. We are committed to providing a secure
            and reliable service for your file upload needs.
            <br />
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontSize: "3rem",
              fontWeight: 540,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "4%",
            }}
          >
            DISCLAIMER
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
            }}
          >
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR
            USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
            WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
            WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </Typography>

          <Space />
          <Space />
          <Space />
        </BodyContainer>
      </MainContainer>
    </div>
  );
};

export default React.memo(TermsConditions);
