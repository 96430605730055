import "./App.css";
import { useTranslation } from "react-i18next";
import MainRouterConfig from "navigation/MainRouterConfig";
import { BrowserRouter } from "react-router-dom";

import { I18nextProvider } from "react-i18next";
import i18n from "i18n";
import { Box, Container } from "@mui/material";
/* import { ThemeProvider, createTheme } from '@mui/material/styles';
 */

import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  ApolloProvider,
  ApolloLink,
  useQuery,
  gql,
} from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import Cookies from "js-cookie";
import { DEBUG, SERVER_URL } from "configuration";
import { customFetch } from "shared/htmlutils";
import React, { useEffect } from "react";
import getLPTheme from "getLPTheme";
import ThemeProvider from "theme";
import { useDispatch, useSelector } from "react-redux";
import { resetViewStore } from "store/view";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from 'react-ga4';

function App() {
  ReactGA.initialize('G-J64YGR87EC');

  const { t } = useTranslation();
  const uri = SERVER_URL + "/graphql";
  const dispatch = useDispatch();
  React.useEffect(() => {
    console.log("Rendering  App  .....");
  }, []);
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);

  const uploadLink = createUploadLink({
    uri: uri,
    credentials: "include",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
    //fetch: customFetch,
  });
  const publicLink = createUploadLink({
    uri: SERVER_URL + "/pgraphql",
    credentials: "include",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
    fetch: customFetch,
  });
  const client = new ApolloClient({
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === "public",
      publicLink, //if above
      uploadLink
    ),
    cache: new InMemoryCache({
      dataIdFromObject(object) {
        /* if (object.__typename === "UserType") {
          return object.id;
        } */
        return defaultDataIdFromObject(object);
      },
      addTypename: true,
      typePolicies: {
        Query: {
          fields: {
            allConnectedStoragesPaged: {
              //The keyArgs is what apollo cache relies on to create separate caches
              //keyArgs: ["dataId"],
              keyArgs: false,
              merge(existing = [], incoming) {
                console.log(
                  "cachemerge: merging existing " +
                    existing.length +
                    " into incoming " +
                    incoming.length
                );
                return [...existing, ...incoming];
              },
            },
            uploadLinksForConnectedStoragePaged: {
              //The keyArgs is what apollo cache relies on to create separate caches
              //keyArgs: ["dataId"],
              keyArgs: ["id"],
              merge(existing = [], incoming) {
                // console.log("cachemerge: merging existing " + existing.length + " into incoming " + incoming.length )
                return [...existing, ...incoming];
              },
            },
            uploadItemsForConnectedStoragePaged: {
              //The keyArgs is what apollo cache relies on to create separate caches
              //keyArgs: ["dataId"],
              keyArgs: ["id"],
              merge(existing = [], incoming) {
                // console.log("cachemerge: merging existing " + existing.length + " into incoming " + incoming.length )
                return [...existing, ...incoming];
              },
            },
            uploadItemsPaged: {
              //The keyArgs is what apollo cache relies on to create separate caches
              //keyArgs: ["dataId"],
              keyArgs: false,
              merge(existing = [], incoming) {
                // console.log("cachemerge: merging existing " + existing.length + " into incoming " + incoming.length )
                return [...existing, ...incoming];
              },
            },
            uploadItemsForLinkPaged: {
              //The keyArgs is what apollo cache relies on to create separate caches
              //keyArgs: ["dataId"],
              keyArgs: ["linkId"],
              merge(existing = [], incoming) {
                // console.log("cachemerge: merging existing " + existing.length + " into incoming " + incoming.length )
                return [...existing, ...incoming];
              },
            },
            allUploadLinksPaged: {
              //The keyArgs is what apollo cache relies on to create separate caches
              //keyArgs: ["dataId"],
              keyArgs: false,
              merge(existing = [], incoming, { args, variables, context }) {
                if (variables.mergeIncomingFirst) {
                  if (DEBUG) {
                    console.log("Will merge incoming first");
                  }
                  return [...incoming, ...existing];
                }

                // console.log("cachemerge: merging existing " + existing.length + " into incoming " + incoming.length )
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });

  useEffect(() => {
    if (!isUserLoggedIn) {
      client.clearStore();
      dispatch(resetViewStore());
    } else {
      /* const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app); */
    }
  }, [isUserLoggedIn]);
  /*   const theme = createTheme({
    palette: {
      primary: {
        main: "#1877F2",
      },
      secondary: {
        main: "#ff0000",
      },
    },
  });
 */
  /*  const [mode, setMode] = React.useState('dark');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode)); */
  return (
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <ThemeProvider>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <MainRouterConfig />
            </BrowserRouter>
          </ApolloProvider>
        </ThemeProvider>
      </HelmetProvider>
    </I18nextProvider>
  );
}

export default App;
