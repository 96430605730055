import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  Container,
  Grow,
  Input,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  RequestResetPasswordApiResult,
  RequestResetStatusCodes,
  requestResetPassword,
} from "api/authApi";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import { isValidEmail } from "shared/dataUtils";
import EmailSentIllustration from "res/illustrations/email_sent_illustration.svg";

interface SignUpResult {
  requestResetFailed: boolean;
  errorMsg: string;
}

const RequestResetPasswordPage = () => {
 
  const { t } = useTranslation();
  const theme = useTheme();
  const emailRef = React.useRef<any>();
  const [error, setError] = useState<any>(null);
  const [requestResetStatus, setRequestResetStatus] = useState<SignUpResult>({
    requestResetFailed: false,
    errorMsg: "",
  });
  const [requestResetSuccess, setRequestResetSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setRequestResetFailed = useCallback(
    (errorMsgArg: string) => {
      setRequestResetStatus({
        requestResetFailed: true,
        errorMsg: errorMsgArg,
      });
      setIsLoading(false);
    },
    [requestResetStatus]
  );
  const handleRequestReset = useCallback(() => {
    const email = emailRef.current.value;
    setIsLoading(true);
    setError(null);
    if (!isValidEmail(email)) {
      setError(t("email_invalid"));
      return;
    }

    requestResetPassword(email)
      .then((result: RequestResetPasswordApiResult) => {
        switch (result.statusCode) {
          case RequestResetStatusCodes.USER_DOES_NOT_EXIST.valueOf():
            setRequestResetFailed(t("reset_password_email_does_not_exist"));
            break;
          case RequestResetStatusCodes.UNKOWN.valueOf():
            setRequestResetFailed(t("reset_password_email_failed_unknown"));
            break;
          case RequestResetStatusCodes.SUCCESS.valueOf():
            setIsLoading(false);
            setRequestResetSuccess(true);
            break;
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [emailRef.current, setError]);

  if (requestResetSuccess) {
    return (
      <Container
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        maxWidth="sm"
      >
        <Helmet>
          <title>{t("reset_password_title")} | Uploadly</title>
        </Helmet>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            background: "#f8fbf8",
            borderRadius: "16px",
            marginTop: "24px",
            minWidth: "30%",
            "@media (max-width: 780px)": {
              minWidth: "80%",
            },
          }}
          elevation={3}
        >
          <img
            style={{ position: "absolute", top: "16px", left: "64px" }}
            height="48px"
            width="48px"
            src={UploadlyIcon}
          />
          <img
            id="logo"
            width="196px"
            height="196px"
            style={{ marginTop: "36px", marginBottom: "36px" }}
            src={EmailSentIllustration}
            alt="Memento logo"
          />
          <Typography
            sx={{
              marginBottom: "24px",
              textAlign: "center",
            }}
            variant="subtitle1"
            gutterBottom
          >
            {t("request_reset_password_success_msg")}
          </Typography>
        </Paper>
      </Container>
    );
  } else {
    return (
      <Container
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        maxWidth="xs"
      >
        <Helmet>
          <title>{t("reset_password_title")} | Uploadly</title>
        </Helmet>
        <img
          style={{ position: "absolute", top: "16px", left: "64px" }}
          height="48px"
          width="48px"
          src={UploadlyIcon}
        />

        <Card
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            px: theme.spacing(4),
            py: theme.spacing(2),
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
        >
          <Typography
            sx={{
              marginBottom: "16px",
            }}
            variant="h4"
          >
            {t("reset_password_title")}
          </Typography>
          {requestResetStatus.requestResetFailed && (
            <Alert
              sx={{ marginBottom: theme.spacing(2) }}
              id="error-alert"
              severity="error"
            >
              {requestResetStatus.errorMsg}
            </Alert>
          )}

          <TextField
            inputRef={emailRef}
            name="email"
            type="text"
            placeholder={t("email")}
            error={Boolean(error)}
            helperText={error}
          />
          <Typography
            sx={{ marginTop: "12px", fontWeight: "300", textAlign: "center" }}
            variant="caption"
            color="text.body"
          >
            {t("reset_password_helper_text")}
          </Typography>
          <LoadingButton
            sx={{
              marginTop: theme.spacing(2),
            }}
            type="submit"
            onClick={handleRequestReset}
            loading={isLoading}
          >
            {t("reset_password_action")}
          </LoadingButton>
        </Card>
      </Container>
    );
  }
};

export default React.memo(RequestResetPasswordPage);
