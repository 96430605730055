export interface UploadLink {
  id: string;
  createdAt: string;
  name: string;
  token: string;
  uploadsCount: number;
  allowedFiles: string;
  expiryDate: string;
  totalUploadsSize: number
  maxUploadsCount: number;
  remainingSpace: number;
  maxTotalUploadsSize: number;
  status: UploadLinkStatus;
  integrations: UploadLinkConnectedStorage[];
}

export interface Owner {
  firstName: String;
  lastName: String;
}

export interface UploadLinkLayoutSettings {
  showUserInfo: boolean;
  description: string;
  enableUserNameField: boolean;
  enableUserEmailField: boolean;
  enableUserMessageField: boolean;
  messageTitle: string;
  owner?: Owner | undefined | null;
}

export interface InternalFileUpload {
  id: string;
}

export interface DestinationFolderInformation {
  name: string;
  url: string;
}

export interface UploadLinkConnectedStorage {
  id: string;
  storageProvider: StorageProviderType;
  destinationFolder: DestinationFolderInformation;
}

export interface ConnectedStorage {
  id: string;
  storageProvider: StorageProviderType;
  destinationFolder: DestinationFolderInformation;
  createdAt: string;
  accountEmail: string;
}

export interface BaseUploadItem {
  id: string;
  fileSize: number;
  fileName: string;
  fileType: string;
  fileUrl: string;
  createdAt: string;
  uploader: Uploader;
  internalFileUpload: InternalFileUpload;
  hasPreview: boolean;
}

export interface UploadItem extends BaseUploadItem {
  uploader: Uploader;
}

export interface EmailUploadItem extends BaseUploadItem {}


interface Uploader {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

export enum StorageProviderType { //should be in sync with values from the backend
  UPLOADLY_STORAGE = 0,
  GOOGLE_DRIVE = 1,
  ONEDRIVE = 2,
  DROPBOX = 3,
  FTP = 4,
  GOOGLE_PHOTOS = 5,
}

export enum UploadLinkStatus { //should be in sync with values from the backend
  ACTIVE = 0,
  DISABLED = 1,
  EXPIRED = 2,
}
