import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GoogleDriveIcon from "res/icons/google-drive-icon.png";
import DropBoxIcon from "res/icons/dropbox-logo.svg";
import OneDriveIcon from "res/icons/one-drive-logo.svg";
import {
  DEBUG,
  getConnectedStorageDetailViewRelativeUrlFromId,
} from "configuration";
import { useQuery } from "@apollo/client";
import { retrieveConnectedStoragesPagedGql } from "graphql/queries";
import {
  DROPBOX_INTEGRATION,
  GOOGLE_DRIVE_INTEGRATION,
  ONEDRIVE_INTEGRATION,
  getIconForStorageProvider,
} from "./storageProviderIds";
import { formatIso8601date } from "shared/dataUtils";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DestinationFolderBadge from "components/ui/DestinationFolderBadge";
import { ConnectedStorage } from "models/uploadly";
import { useNavigate } from "react-router-dom";

const PAGE_SIZE = 15;

const ConnectedStoragesContainer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const fetchMoreOngoing = React.useRef(false);

  const [allItemsRetrieved, setAllItemsRetrieved] = React.useState(false);
  const [itemsListEmpty, setItemsListEmpty] = React.useState(false);
  const lastFetchedPage = React.useRef(1);

  const [linearLayout, setLinearLayout] = useState(false);
  const [remainingHeight, setRemainingHeight] = useState(0);
  const targetRef = useRef<any>(null);

  const storagesQueryResult = useQuery(retrieveConnectedStoragesPagedGql, {
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
    },
  });

/*   React.useEffect(() => {
    console.log("Will set tabvalue render connected ");
  }, []); */
  if (
    storagesQueryResult.data &&
    storagesQueryResult.data.allConnectedStoragesPaged
  )
    console.log(
      "fetch Storage integrations are count is" +
        storagesQueryResult.data.allConnectedStoragesPaged.length
    );

  React.useEffect(() => {
    if (
      storagesQueryResult.data &&
      !storagesQueryResult.loading &&
      !storagesQueryResult.error
    ) {
      setAllItemsRetrieved(
        storagesQueryResult.data.allConnectedStoragesPaged.length % PAGE_SIZE !=
          0 || storagesQueryResult.data.allConnectedStoragesPaged.length == 0
      );
      setItemsListEmpty(
        storagesQueryResult.data.allConnectedStoragesPaged.length == 0
      );
    }
  }, [storagesQueryResult]);

  const fetchMoreItems = React.useCallback(() => {
    if (fetchMoreOngoing.current) {
      if (DEBUG) {
        console.log("fetchMoreItems already ongoing");
      }
      return;
    }
    fetchMoreOngoing.current = true;

    if (allItemsRetrieved) {
      if (DEBUG) {
        console.log("Already retrieved everything");
      }
      fetchMoreOngoing.current = false;
      return;
    }
    const nextPageToFetch = lastFetchedPage.current + 1;

    if (DEBUG) {
      console.log("Fetch more data for page " + nextPageToFetch);
    }
    storagesQueryResult
      .fetchMore({
        variables: {
          page: nextPageToFetch,
          pageSize: PAGE_SIZE,
        },
      })
      .then((result) => {
        if (DEBUG) {
          console.log("Fetch more result %o", result);
        }
        if (
          result.data &&
          result.data.allConnectedStoragesPaged &&
          result.data.allConnectedStoragesPaged.length == 0
        ) {
          setAllItemsRetrieved(true);
        }
        lastFetchedPage.current = nextPageToFetch;
        fetchMoreOngoing.current = false;
      })
      .catch((e) => {
        //setFetchOngoing(false)
        fetchMoreOngoing.current = false;
      });
    //}
  }, [
    storagesQueryResult,
    storagesQueryResult.data,
    allItemsRetrieved,
    fetchMoreOngoing.current,
  ]);

  const handleOnScroll = React.useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        fetchMoreItems();
      }
    },
    [fetchMoreItems]
  );
/* 
  const getIconForStorageProvider = useCallback((storagePoviderId: number) => {
    switch (storagePoviderId) {
      case GOOGLE_DRIVE_INTEGRATION:
        return (
          <img
            src={GoogleDriveIcon}
            style={{
              width: "32px",
              height: "32px",
            }}
          ></img>
        );
      case DROPBOX_INTEGRATION:
        return (
          <img
            src={DropBoxIcon}
            style={{
              width: "32px",
              height: "32px",
            }}
          ></img>
        );
      case ONEDRIVE_INTEGRATION:
        return (
          <img
            src={OneDriveIcon}
            style={{
              width: "32px",
              height: "32px",
            }}
          ></img>
        );
      default:
        return <></>;
    }
  }, []);
 */
  const calculateHeight = () => {
    const viewportHeight = window.innerHeight;
    const topOffset = targetRef.current.getBoundingClientRect().top;
    const computedHeight = viewportHeight - topOffset;

    setRemainingHeight(computedHeight);
  };

  useEffect(() => {
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Box
        ref={targetRef}
        onScroll={handleOnScroll}
        sx={{
          display: "flex",
          width: "100%",
          //  background: "yellow",
          height: remainingHeight,
          overflowX: "hidden",
          overflowY: "auto",
          px: theme.spacing(4),
          paddingTop: theme.spacing(4),
          [theme.breakpoints.down("lg")]: {
            px: theme.spacing(0),
          },
        }}
      >
        <List
          sx={{
            width: "100%",
          }}
        >
          {storagesQueryResult &&
            storagesQueryResult.data &&
            storagesQueryResult.data.allConnectedStoragesPaged &&
            storagesQueryResult.data.allConnectedStoragesPaged.map(
              (item: ConnectedStorage, index: number) => {
                return (
                  <ListItem
                    key={item.id}
                    sx={{
                      width: "100%",  
                    }}
                  >
                    <Card
                      sx={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                        borderRadius: "8px",
                        width: "100%",  
                      }}
                    >
                      <CardActionArea
                        sx={{
                          //padding: "24px",
                          py: theme.spacing(1),
                          px: theme.spacing(4),
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          //justifyContent: "space",
                          width: "100%",
                        }}
                        onClick={() => {
                          // handleIntegrationClicked(item.id);
                          navigate(
                            getConnectedStorageDetailViewRelativeUrlFromId(
                              item.id
                            )
                          );
                        }}
                      >
                        <Box
                          sx={{
                            width: "10%",
                            boxSizing: "border-box",
                          }}
                        >
                          {getIconForStorageProvider(item.storageProvider)}
                        </Box>
                        <Typography
                          sx={{
                            width: "25%",
                            flex: "1",
                            wordWrap: "break-word",
                            textOverflow: "ellipsis",
                            fontWeight: "bold",
                          }}
                          variant="caption"
                        >
                          {item.accountEmail}
                        </Typography>
                        <Typography
                          sx={{
                            flex: "1",
                            boxSizing: "border-box",
                            wordWrap: "break-word",
                            [theme.breakpoints.down("md")]: {
                              display: "none",
                            },
                          }}
                          variant="caption"
                        >
                           {t('created_at', {date:formatIso8601date(item.createdAt)})}
                        </Typography>
                        <Box
                          sx={{
                            flex: "1",
                          }}
                        >
                          <DestinationFolderBadge
                            destinationFolder={item.destinationFolder}
                            storageProviderId={item.storageProvider}
                          />
                        </Box>
{/* 
                        <IconButton>
                          <DeleteOutlineIcon />
                        </IconButton> */}
                      </CardActionArea>
                    </Card>
                  </ListItem>
                );
              }
            )}
        </List>
      </Box>
    </Box>
  );
};

export default React.memo(ConnectedStoragesContainer);
