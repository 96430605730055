import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  Container,
  Grow,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  RequestResetPasswordApiResult,
  RequestResetStatusCodes,
  ResetPasswordApiResult,
  ResetPasswordStatusCodes,
  requestResetPassword,
  resetPassword,
} from "api/authApi";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Form, useNavigate, useParams } from "react-router-dom";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import { isValidEmail } from "shared/dataUtils";
import EmailSentIllustration from "res/illustrations/email_sent_illustration.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LOGIN } from "navigation/Constants";
import { PASSWORD_MIN_LENGTH } from "configuration";

interface SignUpResult {
  requestResetFailed: boolean;
  errorMsg: string;
}

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const passwordRef = React.useRef<any>();
  const passwordConfirmationRef = React.useRef<any>();
  const [error, setError] = useState<any>(null);
  const [requestResetStatus, setRequestResetStatus] = useState<SignUpResult>({
    requestResetFailed: false,
    errorMsg: "",
  });
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setRequestResetFailed = useCallback(
    (errorMsgArg: string) => {
      setRequestResetStatus({
        requestResetFailed: true,
        errorMsg: errorMsgArg,
      });
      setIsLoading(false);
    },
    [requestResetStatus]
  );
  const [showPassword, setShowPassword] = useState(false);

  const handleResetPassword = useCallback(() => {
    const password = passwordRef.current.value;
    const passWordConfirmation = passwordConfirmationRef.current.value;
    setIsLoading(true);
    if (password.length < PASSWORD_MIN_LENGTH) {
      setError(t("password_invalid"));
      setIsLoading(false);
      return;
    }
    if (passWordConfirmation != password) {
      setError(t("register_password_confirmation_not_match"));
      setIsLoading(false);
      return;
    }

    const data = {
      password: password as string,
      password_confirmation: passWordConfirmation as string,
      uid: params.uid as string,
      token: params.token as string,
    };

    resetPassword(data).then((result: ResetPasswordApiResult) => {
      switch (result.statusCode) {
        case ResetPasswordStatusCodes.FAILURE.valueOf():
          setRequestResetFailed(t("reset_password_failed_err_msg"));
          setIsLoading(false);
          break;
        case ResetPasswordStatusCodes.SUCCESS.valueOf():
          setResetSuccess(true);
          setIsLoading(false);
          break;
      }
    }).catch((e) => {
        setIsLoading(false);
    });
  }, [setError, passwordRef.current, passwordConfirmationRef.current, params]);

  if (resetSuccess) {
    return (
      <Container
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        maxWidth="sm"
      >
        <Helmet>
          <title>{t("signup_action")} | Uploadly</title>
        </Helmet>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            background: "#f8fbf8",
            borderRadius: "16px",
            marginTop: "24px",
            minWidth: "30%",
            "@media (max-width: 780px)": {
              minWidth: "80%",
            },
          }}
          elevation={3}
        >
          <img
            style={{ position: "absolute", top: "16px", left: "64px" }}
            height="48px"
            width="48px"
            src={UploadlyIcon}
          />
          <Grow in style={{ transformOrigin: "0 0 0" }}>
            <CheckCircleIcon
              style={{
                height: "148px",
                width: "148px",
                color: theme.palette.primary.main,
              }}
            />
          </Grow>

          <Typography
            sx={{
              marginBottom: "24px",
              textAlign: "center",
            }}
            variant="subtitle1"
            gutterBottom
          >
            {t("reset_password_success_msg")}
          </Typography>
          <Button
            onClick={() => {
              navigate(LOGIN);
            }}
          >
            {t("go_to_login")}
          </Button>
        </Paper>
      </Container>
    );
  } else {
    return (
      <Container
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        maxWidth="xs"
      >
        <Helmet>
          <title>{t("reset_password_title")} | Uploadly</title>
        </Helmet>
        <img
          style={{ position: "absolute", top: "16px", left: "64px" }}
          height="48px"
          width="48px"
          src={UploadlyIcon}
        />

        <Card
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            px: theme.spacing(4),
            py: theme.spacing(2),
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
        >
          <Typography
            sx={{
              marginBottom: "16px",
            }}
            variant="h4"
          >
            {t("enter_new_password_msg")}
          </Typography>
          
          {requestResetStatus.requestResetFailed && (
            <Alert
              sx={{ marginBottom: theme.spacing(2) }}
              id="error-alert"
              severity="error"
            >
              {requestResetStatus.errorMsg}
            </Alert>
          )}

          <TextField
            inputRef={passwordRef}
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder={t("password")}
            error={Boolean(error)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            inputRef={passwordConfirmationRef}
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder={t("password_confirmation")}
            error={Boolean(error)}
            helperText={error}
            sx={{
              marginTop: theme.spacing(1),
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton
            sx={{
              marginTop: theme.spacing(2),
            }}
            type="submit"
            loading={isLoading}
            onClick={handleResetPassword}
          >
            {t("send_action")}
          </LoadingButton>
        </Card>
      </Container>
    );
  }
};

export default React.memo(ResetPasswordPage);
