import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  Link,
  List,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import {
  LAST_UPLOAD_ITEMS_RETRIEVED_IN_DASHBOARD,
  LAST_UPLOAD_LINKS_RETRIEVED_IN_DASHBOARD,
  getConnectedStorageDetailViewRelativeUrlFromId,
  getUploadItemPreviewLink,
  getUploadLinkDetailViewRelativeUrlFromId,
} from "configuration";
import {
  retrieveLastNConnectedStoragesGql,
  retrieveLastNUploadItemsGql,
  retrieveLastNUploadLinksGql,
} from "graphql/queries";
import {
  ConnectedStorage,
  StorageProviderType,
  UploadItem,
  UploadLink,
  UploadLinkStatus,
} from "models/uploadly";
import { getIconForStorageProvider } from "pages/storages/storageProviderIds";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  bytesToGigabytes,
  formatIso8601date,
  getRemainingTime,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import LinkIllustration from "res/illustrations/link_illustration.svg";
import ConnectedStoragesIllustration from "res/illustrations/connected_storages_illustration.svg";
import EmptyUploadItemsIllustration from "res/illustrations/empty_upload_items_illustration.svg";
import {
  CONNECTED_STORAGES_FULL_PATH,
  CONNECT_NEW_STORAGES_FULL_PATH,
  PRICING,
  UPLOAD,
  UPLOADED_FILES_FULL_PATH,
  UPLOADLY_LINKS_FULL_PATH,
} from "navigation/Constants";
import { getLinkStatusColor, getLinkStatusString } from "shared/utility";
import DestinationFolderBadge from "components/ui/DestinationFolderBadge";
import CreateUploadLinkModal from "components/modals/CreateUploadLinkModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { SubscriptionPlanIds, getUserMaxStorageUsage } from "models/user";

import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { setOpenedUploadItem } from "store/view";
import { checkIfUploadItemIsPresentInLastUploadItems } from "graphql/gqlcachehelper";

const Dashboard = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const userObj = useSelector((state: RootState) => state.auth.user);
  const [displayCreateNewLink, setDisplayCreateNewLink] = React.useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        px: theme.spacing(4),
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(8),
        flexDirection: "column",
        justifyContent: "top",
        [theme.breakpoints.down("md")]: {
          px: theme.spacing(0),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "start",
            py: theme.spacing(2),
            color: "gray",
          }}
          variant="subtitle1"
        >
          {t("hi") + " " + userObj?.firstName} 👋
        </Typography>

        <Button
          variant="contained"
          sx={{
            display: "none",
            [theme.breakpoints.down("md")]: {
              display: "block",
            },
          }}
          onClick={() => {
            setDisplayCreateNewLink(true);
          }}
        >
          + {t("new_upload_link_title")}
        </Button>
      </Box>
      {displayCreateNewLink && (
        <CreateUploadLinkModal
          open={displayCreateNewLink}
          handleModalClose={() => {
            setDisplayCreateNewLink(false);
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          minHeight: "300px",
          marginBottom: theme.spacing(2),
          justifyContent: "space-between",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Card
          sx={{
            width: "70%",
            height: "100%",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <UploadItemsContainer />
        </Card>
        <Card
          sx={{
            flex: "1",
            marginLeft: theme.spacing(2),
            height: "100%",
            display: "flex",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            [theme.breakpoints.down("md")]: {
              marginTop: theme.spacing(2),
              marginLeft: 0,
              height: "auto",
            },
          }}
        >
          <StorageUsageContainer />
        </Card>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          minHeight: "400px",
          justifyContent: "space-between",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Card
          sx={{
            width: "60%",
            height: "100%",

            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <UploadLinksContainer />
        </Card>
        <Card
          sx={{
            flex: "1",
            marginLeft: theme.spacing(2),
            height: "100%",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            [theme.breakpoints.down("md")]: {
              width: "100%",
              height: "auto",
              marginLeft: 0,
              marginTop: theme.spacing(2),
            },
          }}
        >
          <ConnectedStoragesContainer />
        </Card>
      </Box>
      <Box
        sx={{
          height: "50px",
          width: "100%",
        }}
      ></Box>
    </Box>
  );
};

const UploadItemsContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const [emptyResult, setEmptyResult] = useState(false);
  const dispatch = useDispatch();
  const uploadItemsQueryResult = useQuery(retrieveLastNUploadItemsGql, {
    variables: {
      n: LAST_UPLOAD_ITEMS_RETRIEVED_IN_DASHBOARD,
    },
  });

  React.useEffect(() => {
    const isFound = checkIfUploadItemIsPresentInLastUploadItems(
      uploadItemsQueryResult.client.cache,
      "66991b0a-d853-4688-93f8-e0a581e2debc"
    );
  }, [uploadItemsQueryResult.data]);

  React.useEffect(() => {
    if (
      uploadItemsQueryResult.data &&
      uploadItemsQueryResult.data.lastNUploadItems
    ) {
      if (uploadItemsQueryResult.data.lastNUploadItems.length == 0) {
        setEmptyResult(true);
      } else {
        setEmptyResult(false);
      }
    }
  }, [uploadItemsQueryResult.data]);

  const getIconForFileType = useCallback((fileType: string) => {
    if (fileType.startsWith("image/")) {
      return <ImageOutlinedIcon />;
    } else if (fileType.startsWith("video/")) {
      return <VideocamOutlinedIcon />;
    } else if (fileType.startsWith("application/pdf")) {
      return <PictureAsPdfOutlinedIcon />;
    } else if (fileType.startsWith("application/zip")) {
      return <FolderZipOutlinedIcon />;
    } else if (fileType.startsWith("audio/")) {
      return <AudiotrackOutlinedIcon />;
    } else {
      return <InsertDriveFileOutlinedIcon />;
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "top",
        py: theme.spacing(2),
        px: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
          px: theme.spacing(1),
        },
      }}
    >
      <Typography
        sx={{
          textAlign: "start",
        }}
        variant="subtitle1"
      >
        {t("dashboard_upload_items")}
      </Typography>
      {uploadItemsQueryResult.loading && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={"96px"} />
        </Box>
      )}
      {!uploadItemsQueryResult.loading && (
        <List
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flex: "1",
            flexDirection: "row",
            marginTop: "8px",
            marginBottom: theme.spacing(1),
          }}
        >
          {uploadItemsQueryResult &&
            uploadItemsQueryResult.data &&
            uploadItemsQueryResult.data.lastNUploadItems &&
            uploadItemsQueryResult.data.lastNUploadItems.map(
              (item: UploadItem, index: number) => {
                return (
                  <Card
                    sx={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
                      borderRadius: "8px",
                      width: "100%",
                      height: "auto",
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "top",
                      alignItems: "top",
                      marginLeft: theme.spacing(1),
                      maxWidth: "25%"
                    }}
                  >
                    <CardActionArea
                      sx={{
                        //padding: "24px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        height: "100%",
                        justifyContent: "space-between",
                        paddingBottom: theme.spacing(1),
                        //justifyContent: "space",
                        width: "100%",
                      }}
                      onClick={() => {
                        dispatch(setOpenedUploadItem(item));
                      }}
                    >
                      {item.hasPreview &&
                        !item.fileType.startsWith("application/pdf") && (
                          <img
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              maxHeight: "180px",
                              flex: "1",
                            }}
                            src={getUploadItemPreviewLink(item.id)}
                            loading="lazy"
                          ></img>
                        )}
                      {item.hasPreview &&
                        item.fileType.startsWith("application/pdf") && (
                          <Box
                            sx={{
                              width: "100%",
                              background: "#faf6e9",
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "12px",
                              flex: "1",
                            }}
                          >
                            <img
                              style={{
                                width: "70%",
                                height: "100%",
                              }}
                              src={getUploadItemPreviewLink(item.id)}
                              loading="lazy"
                            ></img>
                          </Box>
                        )}
                      {!item.hasPreview && (
                        <Box
                          sx={{
                            width: "100%",
                            height: "150px",
                            background: "#faf6e9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "12px",
                          }}
                        >
                          <InsertDriveFileOutlinedIcon />
                        </Box>
                      )}
                      <Box
                        sx={{
                          px: theme.spacing(0.5),
                          marginTop: theme.spacing(1),
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            flex: "1",
                            display: "flex",
                            flexDirection: "rown",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {getIconForFileType(item.fileType)}
                          <Typography
                            sx={{
                              marginLeft: theme.spacing(1),
                              display: "-webkit-box",
                              wordWrap: "break-word",
                              textOverflow: "ellipsis",
                              fontWeight: "bold",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              overflow: "hidden",
                              maxLines: 2,
                            }}
                            variant="caption"
                          >
                            {item.fileName}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "end",
                            width: "100%",
                            marginTop: "4px",
                          }}
                        >
                          <Typography
                            sx={{
                              boxSizing: "border-box",
                              wordWrap: "break-word",
                              marginLeft: "4px",
                              marginRight: "8px",
                            }}
                            variant="caption"
                          >
                            {formatIso8601date(item.createdAt)}
                          </Typography>

                          {getIconForStorageProvider(
                            (item as any).uploadLink.integrations.length > 0
                              ? (item as any).uploadLink.integrations[0]
                                  .storageProvider
                              : 0,
                            {
                              width: "16px",
                              height: "16px",
                            }
                          )}
                        </Box>
                      </Box>
                    </CardActionArea>
                  </Card>
                );
              }
            )}
        </List>
      )}

      {!emptyResult && !uploadItemsQueryResult.loading && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              navigate(UPLOADED_FILES_FULL_PATH);
            }}
          >
            {" "}
            {t("view_all_action")}
            <EastIcon
              sx={{
                marginLeft: theme.spacing(1),
              }}
            />
          </Button>
        </Box>
      )}

      {emptyResult && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: theme.spacing(4),
          }}
        >
          <img
            src={EmptyUploadItemsIllustration}
            style={{
              width: "300px",
              height: "200px",
            }}
          />
          <Typography variant="subtitle2">
            {t("dashboard_last_received_files_empty_title")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const StorageUsageContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);

  const computeStorageUsagePercentage = useCallback(() => {
    if (!user) {
      return 0;
    }
    const maxStorage = getUserMaxStorageUsage(user);
    const percentage = Math.round(
      (user?.uploadlyStorageUsedSpace / maxStorage) * 100
    );
    return percentage > 100 ? 100 : percentage;
  }, [user]);

  const usedStoragePercentage = useMemo(() => computeStorageUsagePercentage(), [
    user,
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "top",
        py: theme.spacing(2),
        px: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
          px: theme.spacing(1),
        },
      }}
    >
      <Typography
        sx={{
          textAlign: "start",
        }}
        variant="subtitle1"
      >
        {t("dashboard_my_uploadly_storage_title")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "default",
          [theme.breakpoints.down("md")]: {
            minHeight: "250px",
          },
        }}
      >
        <CircularProgress
          variant="determinate"
          value={100}
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#a1caff", // Color of the remaining progress track
          }}
          size={"196px"}
        />
        <CircularProgress
          variant="determinate"
          size={"196px"}
          value={usedStoragePercentage}
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color:
              usedStoragePercentage >= 100
                ? "red"
                : usedStoragePercentage >= 80
                ? "orange"
                : theme.palette.primary.main,
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* {`${Math.round(40)}%`}
           */}
          <Typography
            sx={{
              color: "gray",
            }}
            variant="subtitle2"
            component="div"
            color="text.secondary"
          >
            {bytesToGigabytes(user?.uploadlyStorageUsedSpace) +
              "/" +
              getSizeInHumeanReadableFormat(getUserMaxStorageUsage(user))}
          </Typography>
        </Box>
      </Box>{" "}
      <Typography
        sx={{
          width: "100%",
          textAlign: "center",
        }}
        variant="caption"
      >
        {user?.subscriptionPlan.id != SubscriptionPlanIds.BUSINESS && (
          <Link
            sx={{ cursor: "pointer", color: "gray" }}
            onClick={() => {
              navigate(PRICING);
            }}
          >
            {t("increase_my_uploadly_storage")}
          </Link>
        )}
      </Typography>
    </Box>
  );
};

const ConnectedStoragesContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const storagesQueryResult = useQuery(retrieveLastNConnectedStoragesGql, {
    variables: {
      n: 5,
    },
  });

  const [emptyResult, setEmptyResult] = useState(false);

  React.useEffect(() => {
    if (
      storagesQueryResult.data &&
      storagesQueryResult.data.lastNConnectedStorages
    ) {
      if (storagesQueryResult.data.lastNConnectedStorages.length == 0) {
        setEmptyResult(true);
      } else {
        setEmptyResult(false);
      }
    }
  }, [storagesQueryResult.data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        py: theme.spacing(2),
        px: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
          px: theme.spacing(1),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography sx={{}} variant="subtitle1">
          {t("dashboard_last_connected_storages_title")}
        </Typography>
        {!emptyResult && !storagesQueryResult.loading && (
          <Button
            variant="contained"
            onClick={() => {
              navigate(CONNECT_NEW_STORAGES_FULL_PATH);
            }}
          >
            + {t("connect_action")}
          </Button>
        )}
      </Box>

      {storagesQueryResult.loading && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={"96px"} />
        </Box>
      )}
      {!storagesQueryResult.loading && (
        <List sx={{ width: "100%", height: "100%" }}>
          {storagesQueryResult &&
            storagesQueryResult.data &&
            storagesQueryResult.data.lastNConnectedStorages &&
            storagesQueryResult.data.lastNConnectedStorages.map(
              (item: ConnectedStorage, index: number) => {
                return (
                  <Grid item key={item.id} xs={12} sm={12} md={12}>
                    <CardActionArea
                      sx={{
                        //padding: "24px",
                        py: theme.spacing(1),
                        px: theme.spacing(1),
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: "16px",
                        width: "100%",
                      }}
                      onClick={() => {
                        navigate(
                          getConnectedStorageDetailViewRelativeUrlFromId(
                            item.id
                          )
                        );
                      }}
                    >
                      <Box
                        sx={{
                          width: "10%",
                          boxSizing: "border-box",
                        }}
                      >
                        {getIconForStorageProvider(item.storageProvider)}
                      </Box>
                      <Typography
                        sx={{
                          flex: "2",
                          wordWrap: "break-word",
                          textOverflow: "ellipsis",
                          fontWeight: "bold",
                          maxWidth: "50%",
                          paddingRight: theme.spacing(2),
                          [theme.breakpoints.down("md")]: {
                            ml: theme.spacing(1),
                          },
                        }}
                        variant="caption"
                      >
                        {item.accountEmail}
                      </Typography>
                      <Typography
                        sx={{
                          flex: "1",
                          boxSizing: "border-box",
                          wordWrap: "break-word",
                          [theme.breakpoints.down("md")]: {
                            display: "none",
                          },
                        }}
                        variant="caption"
                      >
                        {/*  {t("created_at", {
                          date: formatIso8601date(item.createdAt),
                        })} */}
                        {formatIso8601date(item.createdAt)}
                      </Typography>
                      <Box
                        sx={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "center",
                        }}
                      >
                        <DestinationFolderBadge
                          destinationFolder={item.destinationFolder}
                          storageProviderId={item.storageProvider}
                        />
                      </Box>
                    </CardActionArea>
                  </Grid>
                );
              }
            )}
        </List>
      )}

      {emptyResult && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={ConnectedStoragesIllustration}
            style={{
              width: "300px",
              height: "200px",
            }}
          />
          <Typography variant="subtitle2">
            {t("dashboard_last_connected_storages_empty_title")}
          </Typography>
          <Button
            variant="contained"
            sx={{
              marginTop: theme.spacing(1),
            }}
            onClick={() => {
              navigate(CONNECT_NEW_STORAGES_FULL_PATH);
            }}
          >
            + {t("connect_action")}
          </Button>
        </Box>
      )}

      {!emptyResult && !storagesQueryResult.loading && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              navigate(CONNECTED_STORAGES_FULL_PATH);
            }}
          >
            {" "}
            {t("view_all_action")}
            <EastIcon
              sx={{
                marginLeft: theme.spacing(1),
              }}
            />
          </Button>
        </Box>
      )}
    </Box>
  );
};
const UploadLinksContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const uploadLinksQueryResult = useQuery(retrieveLastNUploadLinksGql, {
    variables: {
      n: LAST_UPLOAD_LINKS_RETRIEVED_IN_DASHBOARD,
    },
  });

  const [emptyResult, setEmptyResult] = useState(false);
  const [displayCreateNewLink, setDisplayCreateNewLink] = React.useState(false);

  React.useEffect(() => {
    if (
      uploadLinksQueryResult.data &&
      uploadLinksQueryResult.data.lastNUploadLinks
    ) {
      if (uploadLinksQueryResult.data.lastNUploadLinks.length == 0) {
        setEmptyResult(true);
      } else {
        setEmptyResult(false);
      }
    }
  }, [uploadLinksQueryResult.data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        py: theme.spacing(2),
        px: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
          px: theme.spacing(1),
        },
      }}
    >
      <Typography
        sx={{
          marginBottom: theme.spacing(2),
        }}
        variant="subtitle1"
      >
        {t("dashboard_last_upload_links_title")}
      </Typography>

      {uploadLinksQueryResult.loading && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={"96px"} />
        </Box>
      )}
      {!uploadLinksQueryResult.loading && (
        <List sx={{ width: "100%", height: "100%" }}>
          {uploadLinksQueryResult &&
            uploadLinksQueryResult.data &&
            uploadLinksQueryResult.data.lastNUploadLinks &&
            uploadLinksQueryResult.data.lastNUploadLinks.map(
              (item: UploadLink, index: number) => {
                return (
                  <ListItemButton
                    sx={{
                      //padding: "24px",
                      px: theme.spacing(4),
                      py: theme.spacing(1),
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      //justifyContent: "space",
                      width: "100%",
                      maxWidth: "100%",
                      borderRadius: "16px",
                      [theme.breakpoints.down("md")]: {
                        px: theme.spacing(1),
                      },
                    }}
                    onClick={() => {
                      navigate(
                        getUploadLinkDetailViewRelativeUrlFromId(item.id)
                      );
                    }}
                  >
                    <Box
                      sx={{
                        width: "10%",
                        boxSizing: "border-box",
                      }}
                    >
                      {item.integrations.length > 0
                        ? getIconForStorageProvider(
                            item.integrations[0].storageProvider
                          )
                        : getIconForStorageProvider(
                            StorageProviderType.UPLOADLY_STORAGE
                          )}
                    </Box>
                    <Typography
                      sx={{
                        flex: "1",
                        boxSizing: "border-box",
                        wordWrap: "break-word",
                        [theme.breakpoints.down("md")]: {
                          ml: theme.spacing(1),
                        },
                      }}
                      variant="subtitle2"
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        boxSizing: "border-box",
                        wordWrap: "break-word",
                        [theme.breakpoints.down("md")]: {
                          display: "none",
                        },
                      }}
                      variant="caption"
                    >
                      {t("created_at", {
                        date: formatIso8601date(item.createdAt),
                      })}
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        boxSizing: "border-box",
                        wordWrap: "break-word",
                      }}
                      variant="caption"
                    >
                      {item.uploadsCount + " " + t("uploads")}
                    </Typography>
                    <Box
                      sx={{
                        flex: "1",
                      }}
                    >
                      <Typography
                        sx={{
                          flex: "1",
                          boxSizing: "border-box",
                          wordWrap: "break-word",
                          background: getLinkStatusColor(item.status),
                          padding: "4px",
                          borderRadius: "4px",
                          color: "black",
                        }}
                        variant="caption"
                      >
                        {item.status == UploadLinkStatus.ACTIVE
                          ? t("expires_in", {
                              expiry: getRemainingTime(item.expiryDate, t),
                            })
                          : getLinkStatusString(item.status, t)}
                      </Typography>
                    </Box>{" "}
                    {/* <Box
                        sx={{
                          flex: "1",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <DestinationFolderBadge
                          sx={{
                            alignItems: "center",
                            background: "red",
                          }}
                          destinationFolder={item.integration.destinationFolder}
                          storageProviderId={item.integration.storageProvider}
                        />
                      </Box> */}
                  </ListItemButton>
                );
              }
            )}
        </List>
      )}
      {/*  </Grid> */}
      {emptyResult && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={LinkIllustration}
            style={{
              width: "156px",
              height: "156px",
            }}
          />
          <Typography
            sx={{
              marginTop: theme.spacing(1),
            }}
            variant="subtitle2"
          >
            {t("dashboard_last_upload_links_empty_title")}
          </Typography>
          <Button
            variant="contained"
            sx={{
              marginTop: theme.spacing(1),
            }}
            onClick={() => {
              setDisplayCreateNewLink(true);
            }}
          >
            + {t("create_action")}
          </Button>
        </Box>
      )}
      {!emptyResult && !uploadLinksQueryResult.loading && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              navigate(UPLOADLY_LINKS_FULL_PATH);
            }}
          >
            {" "}
            {t("view_all_action")}
            <EastIcon
              sx={{
                marginLeft: theme.spacing(1),
              }}
            />
          </Button>
        </Box>
      )}
      {displayCreateNewLink && (
        <CreateUploadLinkModal
          open={displayCreateNewLink}
          handleModalClose={() => {
            setDisplayCreateNewLink(false);
          }}
        />
      )}
    </Box>
  );
};

export default React.memo(Dashboard);
