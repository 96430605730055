import axios from "axios";
import { DEBUG, SERVER_URL } from "configuration";
import Cookies from "js-cookie";

const GOOGLE_AUTH_USER_API_SUBPATH = "/integrations/drive-auth";
const GOOGLE_PHOTOS_AUTH_USER_API_SUBPATH = "/integrations/gphotos-auth";
const UPDATE_ONEDRIVE_DESTINATION_FOLDER_API_SUBPATH =
  "/integrations/gdrive-dest-folder";
const UPDATE_GPHOTOS_DESTINATION_ALBUM_API_SUBPATH =
  "/integrations/gphotos-dest-album";

export interface AuthenticateUserResponse {
  success: boolean;
  authToken: string;
  integrationId: string;
  //integrationInfo: NotionIntegrationInformation;
}

export interface UpdateGoogleDriveDestinationFolderResponse {
  resultCode: number;
  success: boolean;
}

export const updateGoogleDriveDestinationFolder = async (
  integrationId: string,
  folderName: string,
  folderId: string,
  folderUrl: string
): Promise<UpdateGoogleDriveDestinationFolderResponse> => {
  try {
    let res = await axios.post(
      SERVER_URL + UPDATE_ONEDRIVE_DESTINATION_FOLDER_API_SUBPATH,
      {
        id: integrationId,
        folder_name: folderName,
        folder_url: folderUrl,
        folder_id: folderId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log(
        "updateOnedriveDestinationFolder: result" + JSON.stringify(res)
      );
    }
    return {
      resultCode: res.status,
      success: res.data.success,
    };
  } catch (e) {
    throw e;
  }
};

export const authenticateUser = async (
  code: string
): Promise<AuthenticateUserResponse> => {
  try {
    let res = await axios.post(
      SERVER_URL + GOOGLE_AUTH_USER_API_SUBPATH,
      {
        code: code,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log("authenticateUser: result" + JSON.stringify(res));
    }
    return {
      success: res.data.success,
      authToken: res.data.access_token,
      integrationId: res.data.id,
    };
  } catch (e) {
    throw e;
  }
};

export const authenticateGooglePhotosUser = async (
  code: string
): Promise<AuthenticateUserResponse> => {
  try {
    let res = await axios.post(
      SERVER_URL + GOOGLE_PHOTOS_AUTH_USER_API_SUBPATH,
      {
        code: code,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log("authenticateUser: result" + JSON.stringify(res));
    }
    return {
      success: res.data.success,
      authToken: res.data.access_token,
      integrationId: res.data.id,
    };
  } catch (e) {
    throw e;
  }
};

export const updateGooglePhotosDestinationAlbum = async (
  integrationId: string,
  albumName: string
): Promise<UpdateGoogleDriveDestinationFolderResponse> => {
  try {
    let res = await axios.post(
      SERVER_URL + UPDATE_GPHOTOS_DESTINATION_ALBUM_API_SUBPATH,
      {
        id: integrationId,
        album_name: albumName,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log(
        "updateOnedriveDestinationFolder: result" + JSON.stringify(res)
      );
    }
    return {
      resultCode: res.status,
      success: res.data.success,
    };
  } catch (e) {
    throw e;
  }
};
