import React, { forwardRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";

import { usePathname } from "navigation/hooks";
import RouterLink from "navigation/routes/RouterLink";
import { useResponsive } from "hooks/use-responsive";
import SvgColor from "./svg-color/svg-color";
import Scrollbar from "./scrollbar/scrollbar";
import { useTranslation } from "react-i18next";
import {
  CONNECTED_STORAGE_BASE_PATH,
  EMAIL_FORWARDING_FULL_PATH,
  MAINBOARD,
  PRICING,
  STORAGES_FULL_PATH,
  UPLOADED_FILES_FULL_PATH,
  UPLOADLY_LINKS_FULL_PATH,
} from "navigation/Constants";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import CreateUploadLinkModal from "./modals/CreateUploadLinkModal";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { SubscriptionPlanIds } from "models/user";
import { useNavigate } from "react-router-dom";

interface SideNavProps {
  openNav: boolean;
  onCloseNav: () => void;
}

export default function SideNav({ openNav, onCloseNav }: SideNavProps) {
  const pathname = usePathname();
  const theme = useTheme();
  const [displayCreateNewLink, setDisplayCreateNewLink] = React.useState(false);
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const upLg = useResponsive("up", "lg");

  const { t } = useTranslation();

  const navConfig = [
    {
      title: "dashboard",
      path: MAINBOARD,
      icon: icon("ic_analytics"),
    },
    {
      title: t("side_nav_upload_links"),
      path: UPLOADLY_LINKS_FULL_PATH,
      icon: icon("ic_cart"),
    },
    {
      title: t("side_nav_email_integration"),
      path: EMAIL_FORWARDING_FULL_PATH,
      icon: icon("ic_user"),
    },
    {
      title: t("side_nav_connected_storages"),
      path: STORAGES_FULL_PATH,
      icon: icon("ic_user"),
    },
    {
      title: t("side_nav_uploady_storage"),
      path: UPLOADED_FILES_FULL_PATH,
      icon: icon("ic_blog"),
    },
  ];

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
      {navConfig.map((item) => (
        <NavItem key={item.title} item={item} />
      ))}
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "32px",
          marginLeft: "32px",
        }}
      >
        <img style={{}} height="48px" width="48px" src={UploadlyIcon} />
        <Typography
          sx={{
            height: "auto",
            textAlign: "center",
            fontSize: "1.6rem",
            marginLeft: "16px",
            color: theme.palette.primary.main,
            textTransform: "capitalize",
          }}
          variant="subtitle2"
        >
          {t("app_name")}
        </Typography>
      </Box>

      {/*   {renderAccount} */}

      <Button
        variant="contained"
        sx={{
          background: theme.palette.primary.main,
          marginTop: theme.spacing(2),
        }}
        fullWidth
        onClick={() => {
          setDisplayCreateNewLink(true);
        }}
      >
        + {t("new_upload_link_title")}
      </Button>

      <Box
        sx={{
          marginTop: theme.spacing(4),
        }}
      >
        {renderMenu}
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      {displayCreateNewLink && (
        <CreateUploadLinkModal
          open={displayCreateNewLink}
          handleModalClose={() => {
            setDisplayCreateNewLink(false);
          }}
        />
      )}
    </Scrollbar>
  );

  const handleUpgradeClicked = useCallback(() => {
    navigate(PRICING);
  }, []);

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
          {user?.subscriptionPlan.id != SubscriptionPlanIds.BUSINESS && (
            <Button
              sx={{
                mb: theme.spacing(2),
                width: "80%",
              }}
              variant="outlined"
              onClick={handleUpgradeClicked}
            >
              <OfflineBoltOutlinedIcon
                sx={{
                  mx: theme.spacing(1),
                }}
              />
              {t("upgrade_title")}
            </Button>
          )}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          slotProps={{
            backdrop: {
              invisible: true,
            },
          }}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
        >
          {renderContent}
          {user?.subscriptionPlan.id == SubscriptionPlanIds.FREE && (
            <Button
              sx={{
                mb: theme.spacing(2),
                width: "80%",
              }}
              variant="outlined"
              onClick={handleUpgradeClicked}
            >
              <OfflineBoltOutlinedIcon
                sx={{
                  mx: theme.spacing(1),
                }}
              />
              {t("upgrade_title")}
            </Button>
          )}
        </Drawer>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function NavItem({ item }: any) {
  const pathname = usePathname();

  // const active = pathname.includes(item.path);

  const isActive = () => {
    if (pathname == item.path) {
      return true;
    } else if (item.path != MAINBOARD) {
      return pathname.includes(item.path);
    }
    return false;
  };

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "text.secondary",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(isActive() && {
          color: "primary.main",
          fontWeight: "fontWeightSemiBold",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

// ----------------------------------------------------------------------

const icon = (name: any) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

export const NAV = {
  WIDTH: 280,
};
