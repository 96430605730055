import axios, { AxiosResponse } from "axios";
import { SERVER_URL, DEBUG } from "configuration";
import Cookies from "js-cookie";
import { SubscriptionInformation, SubscriptionPlanIds } from "models/user";

const SAVE_PAYMENT_API_SUBPATH = "/payments/save-payment";
const SUBSCRIPTION_INFORMATION_API_SUBPATH =
  "/payments/subscription-information";
const CANCEL_SUBSCRIPTION_API_SUBPATH = "/payments/cancel-subscription";
const EDIT_SUBSCRIPTION_CARD_API_SUBPATH = "/payments/edit-subscription-card";
const CREATE_SUBCRIPTION_API_SUBPATH = "/payments/create-subscription";
const CREATE_SETUP_INTENT_API_SUBPATH = "/payments/create-intent";

export interface SavePaymentResult {
  result: string; //when successful will be 0, otherwise > 0
}

export interface SubscriptionInformationResult {
  result: string; //when successful will be 0, otherwise > 0
  subscriptionInformation: SubscriptionInformation;
}

export interface CreateSubscriptionResult {
  result: string; //when successful will be 0, otherwise > 0
  subscriptionId: string;
  clientSecret: string;
}

export interface EditSubscriptionPaymentResult {
  result: string; //when successful will be 0, otherwise > 0
  subscriptionId: string;
  clientSecret: string;
}

export interface CreateSetupIntent {
  result: string; //when successful will be 0, otherwise > 0
  clientSecret: string;
}

export const createSetupIntent = async (
  currency: string = "USD"
): Promise<any> => {
  try {
    let res = await axios.post(SERVER_URL + CREATE_SETUP_INTENT_API_SUBPATH, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
      },
      withCredentials: true,
    });
    if (DEBUG) {
      console.log(
        "edit subscription card api call result " + JSON.stringify(res)
      );
    }
    return {
      clientSecret: res.data.clientSecret,
    };
  } catch (e) {
    throw e;
  }
};

export const createSubscriptionForCustomer = async (
  currency: string = "USD",
  subPlanId: SubscriptionPlanIds,
  isYearly: boolean
): Promise<CreateSubscriptionResult> => {
  try {
    let res = await axios.post(
      SERVER_URL + CREATE_SUBCRIPTION_API_SUBPATH,
      {
        currency: currency ? currency : "USD",
        sub_plan_id: subPlanId,
        is_yearly: isYearly,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log(
        "edit subscription card api call result " + JSON.stringify(res)
      );
    }
    return {
      result: res.data.result,
      subscriptionId: res.data.subscriptionId,
      clientSecret: res.data.clientSecret,
    };
  } catch (e) {
    throw e;
  }
};

// export const editSubscriptionCard = async (paymentMethodId: string): Promise<EditSubscriptionPaymentResult> => {
//     try {
//         let res = await axios.post(SERVER_URL + EDIT_SUBSCRIPTION_CARD_API_SUBPATH,
//             {
//                 payment_method_id: paymentMethodId
//             }, {
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             withCredentials: true
//         })
//         if (DEBUG) {
//             console.log("edit subscription card api call result " + JSON.stringify(res));
//         }
//         return {
//             result: res.data.result,
//             subscriptionId: res.data.subscriptionId,
//             clientSecret: res.data.clientSecret,
//         }

//     } catch (e) {
//         throw (e)
//     }
// }

export const editSubscriptionCardV3 = async (): Promise<CreateSetupIntent> => {
  try {
    let res = await axios.post(
      SERVER_URL + EDIT_SUBSCRIPTION_CARD_API_SUBPATH,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log(
        "edit subscription card api call result " + JSON.stringify(res)
      );
    }
    return {
      result: res.data.result,
      clientSecret: res.data.clientSecret,
    };
  } catch (e) {
    throw e;
  }
};

export const savePayment = async (
  paymentMethodId: string,
  currency: string = "USD"
): Promise<SavePaymentResult> => {
  try {
    let res = await axios.post(
      SERVER_URL + SAVE_PAYMENT_API_SUBPATH,
      {
        payment_method_id: paymentMethodId,
        currency: currency ? currency : "USD",
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log("save payment api call result " + JSON.stringify(res));
    }
    return { result: res.data.result };
  } catch (e) {
    throw e;
  }
};

export const cancelSubscription = async () => {
  try {
    let res = await axios.get(SERVER_URL + CANCEL_SUBSCRIPTION_API_SUBPATH, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
      },
      withCredentials: true,
    });
    if (DEBUG) {
      console.log("cancelSubscription result " + JSON.stringify(res));
    }
    return { result: res.data.result };
  } catch (e) {
    throw e;
  }
};

export const getSubscriptionInformation = async (): Promise<
  SubscriptionInformationResult
> => {
  try {
    let res = await axios.get(
      SERVER_URL + SUBSCRIPTION_INFORMATION_API_SUBPATH,
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log("getSubscriptionInformation " + JSON.stringify(res));
    }
    return {
      result: res.data.result,
      subscriptionInformation: {
        subscriptionStart: res.data.sub_start,
        lastPaymentDate: res.data.last_payment_date,
        nextPaymentDate: res.data.next_payment_date,
        isCancelled: res.data.is_cancelled,
        paymentInformation: {
          cardBrand: res.data.card_brand,
          cardExpiryMonth: res.data.card_exp_month,
          cardExpiryYear: res.data.card_exp_year,
          cardLast4Digits: res.data.card_last4,
        },
      },
    };
  } catch (e) {
    throw e;
  }
};
