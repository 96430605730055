import { Box, Typography, useTheme } from "@mui/material";
import {
  DestinationFolderInformation,
  StorageProviderType,
} from "models/uploadly";
import { getStorageRootLocation } from "pages/storages/storageProviderIds";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { stringToColour } from "shared/colors";

interface DestinationFolderBadgeProps {
  destinationFolder: DestinationFolderInformation | null;
  storageProviderId: number;
  sx?: object;
}

const DestinationFolderBadge = ({
  destinationFolder,
  storageProviderId,
  sx,
}: DestinationFolderBadgeProps) => {
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        transform:
          hovered && storageProviderId != StorageProviderType.FTP
            ? "scale(1.1)"
            : "scale(1)",
        transformOrigin: "center",
        transition: theme.transitions.create("all", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
      onMouseEnter={() => setHovered(Boolean(destinationFolder?.url))}
      onMouseLeave={() => setHovered(false)}
      {...sx}
    >
      <Typography
        sx={{
          boxSizing: "border-box",
          wordWrap: "break-word",
          background: destinationFolder ? "#FF9505" : "#FFC971",
          padding: "4px",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (storageProviderId == StorageProviderType.FTP) {
            return;
          }
          if (destinationFolder) {
            window.open(destinationFolder.url, "_blank");
          } else {
            window.open(getStorageRootLocation(storageProviderId), "_blank");
          }
        }}
        variant="caption"
      >
        {destinationFolder
          ? "📂   " + destinationFolder.name
          : t("root_location_title")}
      </Typography>
    </Box>
  );
};

export default React.memo(DestinationFolderBadge);
