import Button from "@mui/material/Button";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export interface OpenSimpleConfirmationDialogPayload {
  open: boolean;
  description: any;
}

interface SimpleConfirmationDialogProps {
  open: boolean;
  title: string;
  description: any;
  hideCancel?: boolean;
  handleOk: () => void;
  handleModalClose: (close: boolean) => void;
}

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    addButton: {
      marginEnd: "12px",
      textTransform: "uppercase",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: "24px",
    },
  })
);

export default function SimpleConfirmationDialog({
  open,
  title,
  description,
  hideCancel,
  handleOk,
  handleModalClose,
}: SimpleConfirmationDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography
              variant="h5"
              sx={{
                width: "100",
                textAlign: "center",
                marginBottom: theme.spacing(2),
              }}
            >
              {title}
            </Typography>
            <Typography variant="body1">{description}</Typography>
            <div className={classes.buttonContainer}>
              {!hideCancel && (
                <Button
                  className={classes.addButton}
                  color="primary"
                  onClick={() => handleModalClose(false)}
                >
                  {t("cancel_action")}
                </Button>
              )}
              <Button
                className={classes.addButton}
                color="primary"
                onClick={handleOk}
              >
                {t("ok")}{" "}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
