import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grow from "@mui/material/Grow";
import { STRIPE_API_KEY } from "configuration";
import DialogCheckoutForm from "./DialogCheckoutForm";

interface PaymentDialogProps {
  open: boolean;
  handleOk: () => void;
  onCardChangeSuccess: () => void;
  handleModalClose: (close: boolean) => void;
}

const stripePromise = loadStripe(STRIPE_API_KEY!!);

export const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  margin-bottom: 16px;
  padding: 12px 12px 0 12px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      padding: "48px",
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "50%",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    addButton: {
      marginEnd: "12px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: "24px",
    },
    editSuccessTop: {
      wdith: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      marginTop: "24px",
    },
  })
);

export default function PaymentDialog({
  open,
  handleOk,
  onCardChangeSuccess,
  handleModalClose,
}: PaymentDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [displayCheckoutForm, setDisplayCheckoutForm] = useState(true);
  const [hideCheckoutView, setHideCheckoutView] = useState(false);
  const [displayCheckIcon, setDisplayCheckIcon] = useState(false);

  // const [displayCheckoutForm, setDisplayCheckoutForm] = useState(false);
  // const [hideCheckoutView, setHideCheckoutView] = useState(true);
  // const [displayCheckIcon, setDisplayCheckIcon] = useState(true);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => {
        if (hideCheckoutView) handleModalClose(false);
      }}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        {!hideCheckoutView && (
          <Elements stripe={stripePromise}>
            <DialogCheckoutForm
              onCloseClicked={() => handleModalClose(false)}
              onSuccess={() => {
                setHideCheckoutView(true);
                onCardChangeSuccess();
              }}
            />
          </Elements>
        )}
        {hideCheckoutView && (
          <Grow
            addEndListener={(node, done) => {
              node.addEventListener(
                "transitionend",
                (e) => {
                  if (!displayCheckoutForm) {
                    setDisplayCheckIcon(true);
                  }
                  done();
                },
                false
              );
            }}
            in={hideCheckoutView}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "36px",
              }}
            >
              <CheckCircleIcon
                sx={{
                  width: "128px",
                  height: "128px",
                  color: theme.palette.primary.main,
                }}
              />
              <Typography
                style={{ marginTop: "32px" }}
                component="h2"
                variant="h5"
                color={"gray"}
                textAlign="center"
              >
                {t("edit_payment_success_msg")}
              </Typography>
            </div>
          </Grow>
        )}
      </div>
    </Modal>
  );
}
