import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UploadItem } from "models/uploadly";

interface SnackbarState {
  message: any;
  action?: string;
  onAction?: (() => void) | null;
  loading?: boolean | null;
}

interface ShowUpgradePlanDialogState {
  show: boolean;
  message: string|null ;
}

interface ViewState {
  pathAfterLogin: string | null;
  startGoogleLogin: boolean;
  openedUploadItem: UploadItem | null;
  snackBarState: SnackbarState;
  upgradePlanDialogState: ShowUpgradePlanDialogState;
}

const initialState = {
  pathAfterLogin: "",
  startGoogleLogin: false,
  openedUploadItem: null,
  snackBarState: {
    message: "",
    action: "",
    onAction: null,
  },
  upgradePlanDialogState: {
    show: false,
    message: null,
  },
} as ViewState;

export const viewSlice = createSlice({
  name: "viewSlice",
  initialState: initialState,
  reducers: {
    startGooglelogin: (state, action: PayloadAction<boolean>) => {
      state.startGoogleLogin = action.payload;
    },
    setPathAfterLogin: (state, action: PayloadAction<string | null>) => {
      state.pathAfterLogin = action.payload;
    },
    setOpenedUploadItem: (state, action: PayloadAction<UploadItem | null>) => {
      state.openedUploadItem = action.payload;
    },
    showSnackbar: (state, action: PayloadAction<SnackbarState>) => {
      state.snackBarState = action.payload;
    },
    showUpgradePlanDialog: (state, action: PayloadAction<ShowUpgradePlanDialogState>) => {
      state.upgradePlanDialogState = action.payload;
    },
    resetViewStore: (state) => {
      state = { ...initialState, pathAfterLogin: state.pathAfterLogin };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOpenedUploadItem,
  resetViewStore,
  setPathAfterLogin,
  startGooglelogin,
  showSnackbar,
  showUpgradePlanDialog
} = viewSlice.actions;

export default viewSlice.reducer;
