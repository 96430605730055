import Button from "@mui/material/Button";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import { REQUEST_RESET_PASSWORD, LOGIN } from "navigation/Constants";
import { deleteAccount } from "api/authApi";
import CircularProgress from "@mui/material/CircularProgress";
import { DEBUG } from "configuration";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch } from "react-redux";
import { logoutUser as logoutReducerAction } from "store/auth";
import { useNavigate } from "react-router-dom";

interface SimpleConfirmationDialogProps {
  open: boolean;
  handleModalClose: (close: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    root: {
      width: "50%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    paper: {
      width: "100%",
    },
    addButton: {
      marginEnd: "12px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: "24px",
    },
    passwordTextfield: {
      display: "flex",
      "& .MuiInputBase-input": {
        paddingTop: "6px",
        paddingBottom: "6px",
        "&::placeholder": {},
      },
    },
    helpTextContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: "12px",
    },
    deletionConfirmedContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  })
);

export default function DeleteAccountConfirmationDialog({
  open,
  handleModalClose,
}: SimpleConfirmationDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const [userPassword, setUserPassword] = React.useState("");
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [accountDeletionOk, setAccountDeletionOk] = React.useState(false);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOkClick = React.useCallback(() => {
    if (accountDeletionOk) {
      //When deletion is performed, go back to login
      dispatch(logoutReducerAction());
      navigate(LOGIN);
      return;
    }
    if (userPassword.length == 0) {
      setError(t("password_required_error"));
      return;
    }
    setIsLoading(true);
    setError(undefined);

    deleteAccount(userPassword)
      .then((result) => {
        setIsLoading(false);

        if (result.statusCode == 0) {
          if (DEBUG) {
            console.log("Account deletion ok");
          }
          setAccountDeletionOk(true);
          //;
        } else if (result.statusCode == 1) {
          setError(t("login_wrong_password_error"));
        } else {
          setError(t("delete_account_error_unknown"));
        }
      })
      .catch((exception) => {
        setIsLoading(false);
        setError(t("delete_account_error_unknown"));
      });
  }, [userPassword, setShowPassword, accountDeletionOk]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          if (accountDeletionOk) {
            //When deletion is performed, go back to login
            dispatch(logoutReducerAction());
            navigate(LOGIN);
          }
          handleModalClose(false);
        }}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.root}>
            {!accountDeletionOk && (
              <div className={classes.paper}>
                <Typography
                  variant="h6"
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    marginBottom: theme.spacing(2),
                  }}
                >
                  {t("delete_account_dialog_title")}
                </Typography>
                <Typography variant="body1">
                  {t("delete_account_dialog_description")}
                </Typography>

                <TextField
                  error={Boolean(error && error.length > 0)}
                  helperText={error}
                  fullWidth
                  className={classes.passwordTextfield}
                  placeholder={t("hint_enter_password")}
                  value={userPassword}
                  onChange={handleChange}
                  style={{ marginTop: "16px" }}
                  id="outlined-password-input"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{
                    style: {
                      color: "red",
                    },
                  }}
                />
                <Box className={classes.helpTextContainer}>
                  <InfoOutlinedIcon />
                  <Typography
                    variant="body1"
                    style={{
                      textAlign: "center",
                      marginLeft: "12px",
                    }}
                  >
                    {t("delete_account_dialog_help")}
                    <Link
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(REQUEST_RESET_PASSWORD);
                      }}
                    >
                      {" "}
                      {t("delete_account_dialog_help_reset_password")}
                    </Link>
                  </Typography>
                </Box>
                {!isLoading && (
                  <div className={classes.buttonContainer}>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      onClick={() => handleModalClose(false)}
                    >
                      {t("cancel_action")}
                    </Button>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      onClick={handleOkClick}
                    >
                      {t("ok")}
                    </Button>
                  </div>
                )}
                {isLoading && (
                  <div className={classes.buttonContainer}>
                    <CircularProgress
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "12px",
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {accountDeletionOk && (
              <Fade addEndListener={(node, done) => {}} in={accountDeletionOk}>
                <Box className={classes.deletionConfirmedContainer}>
                  <CheckCircleIcon
                    sx={{
                      width: "64px",
                      height: "64px",
                    }}
                  />
                  <Typography
                    variant="h5"
                    style={{
                      marginTop: "12px",
                      marginBottom: "12px",
                      textAlign: "center",
                      marginLeft: "12px",
                    }}
                  >
                    {t("delete_account_dialog_confirmed_1")}
                    <br />
                    {t("delete_account_dialog_confirmed_2")}
                    <br />
                    {t("delete_account_dialog_confirmed_3")}
                  </Typography>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    onClick={handleOkClick}
                  >
                    {t("ok")}
                  </Button>
                </Box>
              </Fade>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}
