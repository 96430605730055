import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Fade,
  Modal,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { updateGooglePhotosDestinationAlbum } from "api/googleAuthApi";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/view";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      display: "flex",
      flexDirection: "column",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    addButton: {
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingTop: "24px",
    },
  })
);

interface GooglePhotosDestAlbumDialogProps {
  open: boolean;
  inetgrationId: string;
  handleModalClose: (b: boolean) => void;
}

export default function GooglePhotosDestAlbumDialog({
  open,
  inetgrationId,
  handleModalClose,
}: GooglePhotosDestAlbumDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const albumNameInputRef = useRef<any>(null);
  const [updateOngoing, setUpdateOngoing] = useState(false);

  const [albumNameNotValidError, setAlbumNotValidError] = React.useState<
    string | null
  >(null);

  const handleUpdateAlbum = useCallback(() => {
    setUpdateOngoing(true);
    const albumName = albumNameInputRef.current.value;

    if (albumName.trim().length < 1) {
      setAlbumNotValidError(t("album_name_empty_error"));
      return;
    }
    updateGooglePhotosDestinationAlbum(inetgrationId, albumName)
      .then((result) => {
        setUpdateOngoing(false);
        dispatch(
          showSnackbar({
            message: t("album_update_success_msg"),
            loading: false,
          })
        );
        handleModalClose(false);
      })
      .catch((e) => {
        setUpdateOngoing(false);
        dispatch(
          showSnackbar({
            message: t("operation_failed_generic"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        handleModalClose(false);
      });
  }, [albumNameInputRef.current]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => {
        // handleModalClose(false);
      }}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <>
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {t("coonfigure_gphotos_album_dialog_title")}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                marginTop: theme.spacing(2),
                px: theme.spacing(2),
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  width: "100%",
                  textAlign: "start",
                  marginBottom: theme.spacing(2),
                }}
              >
                 {t("coonfigure_gphotos_album_dialog_description_1")} <br />{" "}
                {t("coonfigure_gphotos_album_dialog_description_2")} <br />{" "}
                {t("coonfigure_gphotos_album_dialog_description_3")}
              </Typography>
              <TextField
                placeholder={t("album_name_placeholder")}
                inputRef={albumNameInputRef}
                error={Boolean(albumNameNotValidError)}
                helperText={albumNameNotValidError}
                sx={{
                  width: "100%",
                  color: "white",
                  marginBottom: theme.spacing(1),
                }}
                inputProps={{
                  color: "white",
                }}
              />
            </Box>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.addButton}
                color="primary"
                onClick={() => handleModalClose(false)}
                sx={{ marginRight: "12px" }}
              >
                {t("skip_action")}
              </Button>

              <LoadingButton
                className={classes.addButton}
                color="primary"
                variant="contained"
                onClick={handleUpdateAlbum}
                loading={updateOngoing}
              >
                {t("configure_action")}
              </LoadingButton>
            </div>
          </>
        </div>
      </Fade>
    </Modal>
  );
}
