import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GoogleDriveIcon from "res/icons/google-drive-icon.png";
import GooglePhotosIcon from "res/icons/google-photos.svg";
import FTPIcon from "res/icons/ftp-icon.svg";
import DropBoxIcon from "res/icons/dropbox-logo.svg";
import OneDriveIcon from "res/icons/one-drive-logo.svg";
import { AuthType, useGoogleAuthPopup } from "hooks/useGoogleAuthPopup";
import {
  authenticateGooglePhotosUser,
  authenticateUser,
  updateGoogleDriveDestinationFolder,
} from "api/googleAuthApi";
import {
  DEBUG,
  DROPBOX_CLIENT_ID,
  GOOGLE_DEVELOPER_KEY,
  GOOGLE_LOGIN_KEY,
  ONEDRIVE_AUTH_REDIRECT_URL,
  ONEDRIVE_CLIENT_ID,
} from "configuration";
import GoogleDrivePicker from "google-drive-picker";
import { useOnedriveAuthPopup } from "hooks/useOnedriveAuthPopup";
import {
  authenticateOnedriveUser,
  updateOnedriveDestinationFolder,
} from "api/onedriveApi";
import { useOneDropboxAuthPopup } from "hooks/useDropboxAuthPopup";
import {
  authenticateDropboxUser,
  updateDropboxDestinationFolder,
} from "api/dropboxApi";
import { loadScript } from "shared/htmlutils";
import {
  DROPBOX_INTEGRATION,
  FTP_INTEGRATION,
  GOOGLE_DRIVE_INTEGRATION,
  GOOGLE_PHOTOS_INTEGRATION,
  ONEDRIVE_INTEGRATION,
} from "./storageProviderIds";
import ConfigureStorageFolderDialog, {
  ConfigureStoragePayload,
} from "components/ui/dialogs/ConfigureStorageFolderDialog";
import { StorageProviderType } from "models/uploadly";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/view";
import ConfigureFtpStorageDialog from "components/ui/dialogs/ConfigureFtpStorageDialog";
import GooglePhotosDestAlbumDialog from "components/ui/dialogs/GooglePhotosDestAlbumDialog";

interface GooglePhotosDestinationAlbumPayload {
  integrationId: string;
}

const ConnectNewStorageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showGoogleAuthPopup, authCode] = useGoogleAuthPopup({
    mode: AuthType.GOOGLE_DRIVE,
  });
  const [showGooglePhotosAuthPopup, googlePhotosAuthCode] = useGoogleAuthPopup({
    mode: AuthType.GOOGLE_PHOTOS,
  });
  const [showOnedriveAuthPopup, onedriveAuthCode] = useOnedriveAuthPopup();
  const [showDropboxAuthPopup, dropboxAuthCode] = useOneDropboxAuthPopup();
  const [openConfigureFtpStorage, setOpenConfigureFtpStorage] = useState(false);
  const [
    destinationFolderPaylaod,
    setDestinationFolderPayload,
  ] = useState<ConfigureStoragePayload | null>(null);
  const [
    destinationAlbumGPhotosPayload,
    setDestinationAlbumGPhotosPaylaod,
  ] = useState<GooglePhotosDestinationAlbumPayload | null>(null);

  const AVAILABLE_INTEGRATIONS = [
    {
      id: GOOGLE_DRIVE_INTEGRATION,
      icon: GoogleDriveIcon,
      title: t("integration_google_drive_title"),
      description: t("integration_google_drive_description"),
    },
    {
      id: ONEDRIVE_INTEGRATION,
      icon: OneDriveIcon,
      title: t("integration_one_drive_title"),
      description: t("integration_one_drive_description"),
    },
    {
      id: DROPBOX_INTEGRATION,
      icon: DropBoxIcon,
      title: t("integration_dropbox_title"),
      description: t("integration_dropbox_description"),
    },
    {
      id: FTP_INTEGRATION,
      icon: FTPIcon,
      title: t("integration_ftp_title"),
      description: t("integration_ftp_description"),
    },
    {
      id: GOOGLE_PHOTOS_INTEGRATION,
      icon: GooglePhotosIcon,
      title: t("integration_google_photos_title"),
      description: t("integration_google_photos_description"),
    },
  ];

  React.useEffect(() => {
    if (dropboxAuthCode) {
      if (DEBUG) {
        console.log("Will auth user with code " + dropboxAuthCode);
      }
      authenticateDropboxUser(dropboxAuthCode).then((result) => {
        // handleGoogleDrivePickerOpen(result.authToken);
        if (result.resultCode == 200 && result.integrationId) {
          // handleDropboxPickerOpen(result.integrationId);
          setDestinationFolderPayload({
            storageType: StorageProviderType.DROPBOX,
            data: {
              integrationId: result.integrationId,
            },
          });
        }
      });
    }
  }, [dropboxAuthCode]);

  React.useEffect(() => {
    if (googlePhotosAuthCode) {
      if (DEBUG) {
        console.log("Will auth user with code " + authCode);
      }
      authenticateGooglePhotosUser(googlePhotosAuthCode).then((result) => {
        if (result.success) {
          const integrationId = result.integrationId;
          //handleGoogleDrivePickerOpen(result.authToken, integrationId);
          setDestinationAlbumGPhotosPaylaod({
            integrationId: result.integrationId,
          });
        }
      });
    }
  }, [googlePhotosAuthCode]);

  React.useEffect(() => {
    if (authCode) {
      if (DEBUG) {
        console.log("Will auth user with code " + authCode);
      }
      authenticateUser(authCode).then((result) => {
        if (result.success) {
          const integrationId = result.integrationId;
          //handleGoogleDrivePickerOpen(result.authToken, integrationId);
          setDestinationFolderPayload({
            storageType: StorageProviderType.GOOGLE_DRIVE,
            data: {
              integrationId: result.integrationId,
              authToken: result.authToken,
            },
          });
        }
      });
    }
  }, [authCode]);

  React.useEffect(() => {
    if (onedriveAuthCode) {
      if (DEBUG) {
        console.log("Will auth user with code " + onedriveAuthCode);
      }
      authenticateOnedriveUser(onedriveAuthCode)
        .then((result) => {
          // console.log("got the result from the promise %o", result);
          //handleOnedrivePickerOpen(result.authToken, result.integrationId);
          setDestinationFolderPayload({
            storageType: StorageProviderType.ONEDRIVE,
            data: {
              integrationId: result.integrationId,
              authToken: result.authToken,
            },
          });
        })
        .catch((e) => {
          console.log("Got the error %o", e);
        });
    }
  }, [onedriveAuthCode]);

  const handleDropboxPickerOpen = useCallback((integrationId: string) => {
    loadScript("https://www.dropbox.com/static/api/2/dropins.js", "dropboxjs", {
      "data-app-key": DROPBOX_CLIENT_ID!!,
    }).then(() => {
      const options = {
        // Required. Called when a user selects an item in the Chooser.
        success: function (files: any) {
          console.log("Selected the following %o", files);
          console.log("Selected the following " + JSON.stringify(files));
          //alert("Here's the file link: " + files[0].link);
          if (files && files.length > 0) {
            const folderId = files[0].id;
            const name = files[0].name;
            const folderUrl = files[0].link;
            updateDropboxDestinationFolder(
              integrationId,
              name,
              folderId,
              folderUrl
            );
          }
        },
        cancel: function () {},
        multiselect: false,
        folderselect: true,
        fileselect: false,
      };
      (window as any).Dropbox.choose(options);
    });
  }, []);

  const [openPicker, authRes] = GoogleDrivePicker();

  const handleOnedrivePickerOpen = useCallback(
    (authToken: string, integrationId: string) => {
      if (DEBUG) {
        console.log("Will display files picker");
      }
      const pickerOptions = {
        clientId: ONEDRIVE_CLIENT_ID,
        action: "query",
        viewType: "folders",
        //view: "folders",
        folderView: "open", // This option displays only folders,
        multiSelect: false,
        redirectUri: ONEDRIVE_AUTH_REDIRECT_URL,
        accessToken: authToken,
        advanced: {
          accessToken: authToken,
          redirectUri: ONEDRIVE_AUTH_REDIRECT_URL,
          folderView: "open",
          queryParameters: "select=id,name,folder,webUrl",
        },
        success: function (files: any) {
          if (DEBUG) {
            console.log("Files picked: ", files);
            console.log("picked file id " + files.value[0].id);
          }
          if (files.value && files.value.length > 0) {
            const folderId = files.value[0].id;
            const name = files.value[0].name;
            const folderUrl = files.value[0].webUrl;

            updateOnedriveDestinationFolder(
              integrationId,
              name,
              folderId,
              folderUrl
            ).then((result) => {
              console.log("updated the parent id %o", result);
            });
          }

          /* updateOnedriveDestinationFolder(
            integrationId,
            files.value[0].id
          ).then((result) => {
            console.log("updated the parent id %o", result);
          }); */
          // setFiles(files.value);
        },
        cancel: function () {
          console.log("File picking cancelled");
        },
        error: function (e: any) {
          console.error(e);
        },
      };

      (window as any).OneDrive.open(pickerOptions);
    },
    []
  );

  const handleGoogleDrivePickerOpen = (
    authToken: string,
    integrationId: string
  ) => {
    console.log("Passing the token " + authToken);
    openPicker({
      clientId: GOOGLE_LOGIN_KEY!!,
      developerKey: GOOGLE_DEVELOPER_KEY!!,
      viewId: "FOLDERS",
      token: authToken,
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: false,
      setSelectFolderEnabled: true,
      // customScopes: ["https://www.googleapis.com/auth/drive.readonly"],
      // setParentFolder:"Your-Folder-ID",
      // Other configuration options...
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        } else if (data.docs && data.docs.length > 0) {
          console.log("Got the files here %o", data);
          const folderId = data.docs[0].id;
          const name = data.docs[0].name;
          const folderUrl = data.docs[0].url;
          updateGoogleDriveDestinationFolder(
            integrationId,
            name,
            folderId,
            folderUrl
          );
          console.log(data);
        }
      },
    });
  };

  const handleIntegrationClicked = React.useCallback(
    (integrationId: number) => {
      switch (integrationId) {
        case FTP_INTEGRATION:
          setOpenConfigureFtpStorage(true);
          break;
        case GOOGLE_PHOTOS_INTEGRATION:
          showGooglePhotosAuthPopup();
          break;
        case GOOGLE_DRIVE_INTEGRATION:
          showGoogleAuthPopup();
          break;
        case DROPBOX_INTEGRATION:
          showDropboxAuthPopup();
          break;
        case ONEDRIVE_INTEGRATION:
          showOnedriveAuthPopup();
          break;
        default:
          break;
      }
    },
    [setOpenConfigureFtpStorage]
  );

  return (
    <>
      {" "}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Grid
          sx={{
            marginTop: "16px",
          }}
          container
          spacing={3}
        >
          {AVAILABLE_INTEGRATIONS.map((item) => {
            return (
              <Grid item key={item.title} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                  }}
                >
                  <CardActionArea
                    sx={{
                      padding: "24px",
                      height: "100%",
                    }}
                    onClick={() => {
                      handleIntegrationClicked(item.id);
                    }}
                  >
                    <img
                      src={item.icon}
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    ></img>
                    <Typography
                      sx={{
                        marginTop: "16px",
                      }}
                      variant="subtitle1"
                    >
                      {item.title}
                    </Typography>
                    <Typography sx={{}} variant="caption">
                      {item.description}
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {openConfigureFtpStorage && (
          <ConfigureFtpStorageDialog
            open={openConfigureFtpStorage}
            handleModalClose={() => setOpenConfigureFtpStorage(false)}
          />
        )}
        {destinationAlbumGPhotosPayload && (
          <GooglePhotosDestAlbumDialog
            inetgrationId={destinationAlbumGPhotosPayload.integrationId}
            open={Boolean(destinationAlbumGPhotosPayload)}
            handleModalClose={() => {
              setDestinationAlbumGPhotosPaylaod(null);
            }}
          />
        )}
        {destinationFolderPaylaod && (
          <ConfigureStorageFolderDialog
            payload={destinationFolderPaylaod}
            open={Boolean(destinationFolderPaylaod)}
            handleModalClose={() => {
              setDestinationFolderPayload(null);
              dispatch(
                showSnackbar({
                  message: t("storage_connected_confirmation"),
                  loading: false,
                })
              );
            }}
            onConfigureDestinationFolder={(
              payload: ConfigureStoragePayload
            ) => {
              setDestinationFolderPayload(null);
              const integrationId: string = payload.data.integrationId;
              const authToken: string = payload.data.authToken;
              switch (payload.storageType) {
                case StorageProviderType.GOOGLE_DRIVE:
                  handleGoogleDrivePickerOpen(authToken, integrationId);
                  break;
                case StorageProviderType.DROPBOX:
                  handleDropboxPickerOpen(integrationId);
                  break;
                case StorageProviderType.ONEDRIVE:
                  handleOnedrivePickerOpen(authToken, integrationId);
                  break;
              }
            }}
          />
        )}
      </Box>
    </>
  );
};

export default React.memo(ConnectNewStorageContainer);
