import { useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardActionArea,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CopyButtonToolTipped from "components/ui/CopyButtonToolTipped";
import { DEBUG, getUploadItemPreviewLink } from "configuration";
import {
  retrieveUploadItemsForConnectedStoragePagedGql,
  retrieveUploadItemsPagedGql,
} from "graphql/queries";
import { UploadItem, UploadLink } from "models/uploadly";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatIso8601date } from "shared/dataUtils";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import UploadedItemDetailContainer from "./UploadedItemDetailContainer";
import EmptyUploadItemsIllustration from "res/illustrations/empty_upload_items_illustration.svg";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import WindowIcon from "@mui/icons-material/Window";
import MenuIcon from "@mui/icons-material/Menu";

const PAGE_SIZE = 20;

export enum UploadItemsQueryType {
  ALL = 0,
  FOR_CONNECTED_STORAGE = 1,
}

interface UploadItemsContainerProps {
  queryType?: UploadItemsQueryType;
  connectedStorageId?: string | null;
}

const UploadedFilesContainer = ({
  queryType = UploadItemsQueryType.ALL,
  connectedStorageId = null,
}: UploadItemsContainerProps) => {
  const theme = useTheme();
  const fetchMoreOngoing = React.useRef(false);

  const [allItemsRetrieved, setAllItemsRetrieved] = React.useState(false);
  const [itemsListEmpty, setItemsListEmpty] = React.useState(false);
  const [
    clickedUploadItem,
    setClickedUploadItem,
  ] = React.useState<UploadItem | null>(null);
  const { t } = useTranslation();
  const lastFetchedPage = React.useRef(1);
  const [emptyResult, setEmptyResult] = useState(false);

  const [linearLayout, setLinearLayout] = useState(false);
  const [remainingHeight, setRemainingHeight] = useState(0);
  const targetRef = useRef<any>(null);

  const getQueryType = useCallback(
    (queryType: UploadItemsQueryType) => {
      if (queryType == UploadItemsQueryType.FOR_CONNECTED_STORAGE) {
        return retrieveUploadItemsForConnectedStoragePagedGql;
      }
      return retrieveUploadItemsPagedGql;
    },
    [queryType]
  );

  const getVariables = useCallback(
    (queryType: UploadItemsQueryType, page: number) => {
      if (queryType == UploadItemsQueryType.FOR_CONNECTED_STORAGE) {
        return {
          variables: {
            id: connectedStorageId,
            page: page,
            pageSize: PAGE_SIZE,
          },
        };
      }
      return {
        variables: {
          page: page,
          pageSize: PAGE_SIZE,
        },
      };
    },
    [queryType, lastFetchedPage.current]
  );

  const uploadItemsResult = useQuery(
    getQueryType(queryType),
    getVariables(queryType, 1)
  );

  const getUploadLinksArray = (queryResultData: any) => {
    if (queryResultData) {
      if (queryResultData.uploadItemsPaged) {
        return queryResultData.uploadItemsPaged;
      } else if (queryResultData.uploadItemsForConnectedStoragePaged) {
        return queryResultData.uploadItemsForConnectedStoragePaged;
      }
    }
    return null;
  };

  const uploadItemsArray = getUploadLinksArray(uploadItemsResult.data);

  React.useEffect(() => {
    if (
      uploadItemsResult.data &&
      !uploadItemsResult.loading &&
      !uploadItemsResult.error
    ) {
      setAllItemsRetrieved(
        uploadItemsArray.length % PAGE_SIZE != 0 || uploadItemsArray.length == 0
      );
      setItemsListEmpty(uploadItemsArray.length == 0);
    }
  }, [uploadItemsResult]);

  const fetchMoreItems = React.useCallback(() => {
    if (fetchMoreOngoing.current) {
      if (DEBUG) {
        console.log("fetchMoreItems already ongoing");
      }
      return;
    }
    fetchMoreOngoing.current = true;

    if (allItemsRetrieved) {
      if (DEBUG) {
        console.log("Already retrieved everything");
      }
      fetchMoreOngoing.current = false;
      return;
    }
    const nextPageToFetch = lastFetchedPage.current + 1;

    if (DEBUG) {
      console.log("Fetch more data for page " + nextPageToFetch);
    }
    uploadItemsResult
      .fetchMore(getVariables(queryType, nextPageToFetch))
      .then((result) => {
        if (DEBUG) {
          console.log("Fetch more result %o", result);
        }
        const uploadItemsArray = getUploadLinksArray(result.data);

        if (result.data && uploadItemsArray && uploadItemsArray.length == 0) {
          setAllItemsRetrieved(true);
        }
        lastFetchedPage.current = nextPageToFetch;
        fetchMoreOngoing.current = false;
      })
      .catch((e) => {
        //setFetchOngoing(false)
        fetchMoreOngoing.current = false;
      });
    //}
  }, [
    uploadItemsResult,
    uploadItemsResult.data,
    allItemsRetrieved,
    fetchMoreOngoing.current,
    getVariables,
    queryType,
  ]);

  const handleOnScroll = React.useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        fetchMoreItems();
      }
    },
    [fetchMoreItems]
  );

  React.useEffect(() => {
    if (uploadItemsResult.data && uploadItemsArray) {
      if (uploadItemsArray.length == 0) {
        setEmptyResult(true);
      } else {
        setEmptyResult(false);
      }
    }
  }, [uploadItemsResult.data]);

  const calculateHeight = () => {
    const viewportHeight = window.innerHeight;
    const topOffset = targetRef.current.getBoundingClientRect().top;
    const computedHeight = viewportHeight - topOffset;

    setRemainingHeight(computedHeight);
  };

  useEffect(() => {
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      {!emptyResult && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            width: "100",
            px: theme.spacing(4),
          }}
        >
          <IconButton
            onClick={() => {
              setLinearLayout(!linearLayout);
            }}
          >
            {linearLayout ? <WindowIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
      )}
      <Box
        ref={targetRef}
        onScroll={handleOnScroll}
        sx={{
          display: "flex",
          width: "100%",
          //  background: "yellow",
          height: remainingHeight,
          overflowX: "hidden",
          overflowY: "scroll",
          justifyContent: "top",
          flexDirection: "column",
          paddingTop: theme.spacing(4),
          py: theme.spacing(1),
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        {uploadItemsResult.loading && (
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              paddingTop: "10%",
            }}
          >
            <CircularProgress size={"96px"} />
          </Card>
        )}
        {!emptyResult && !uploadItemsResult.loading && (
          <Grid container spacing={1}>
            {uploadItemsResult &&
              uploadItemsResult.data &&
              uploadItemsArray &&
              uploadItemsArray.map((item: UploadItem, index: number) => {
                if (linearLayout) {
                  return (
                    <UploadedFileListItemLayout
                      key={item.id}
                      item={item}
                      onClick={(item: UploadItem) => {
                        setClickedUploadItem(item);
                      }}
                    />
                  );
                } else {
                  return (
                    <UploadedFileGridItemLayout
                      key={item.id}
                      item={item}
                      onClick={(item: UploadItem) => {
                        setClickedUploadItem(item);
                      }}
                    />
                  );
                }
              })}
          </Grid>
        )}
        {emptyResult && (
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              paddingTop: "10%",
              paddingBottom: theme.spacing(4),
            }}
          >
            <img
              src={EmptyUploadItemsIllustration}
              style={{
                width: "300px",
                height: "200px",
              }}
            />
            <Typography variant="subtitle2">
              {t("dashboard_last_received_files_empty_title")}
            </Typography>
          </Card>
        )}

        <Drawer
          anchor={"right"}
          open={Boolean(clickedUploadItem)}
          onClose={() => {
            setClickedUploadItem(null);
          }}
          ModalProps={{
            BackdropProps: {
              style: {
                backgroundColor: "transparent", // Make the backdrop transparent
              },
            },
          }}
          // onClose={toggleDrawer(anchor, false)}
        >
          {/*   {list(anchor)} */}
          <Box
            sx={{
              width: "35vw",
              [theme.breakpoints.down("lg")]: {
                width: "65vw",
              },
              [theme.breakpoints.down("md")]: {
                width: "65vw",
              },
              [theme.breakpoints.down("sm")]: {
                width: "85vw",
              },
            }}
          >
            {clickedUploadItem && (
              <UploadedItemDetailContainer
                uploadItem={clickedUploadItem}
                onClose={() => {
                  setClickedUploadItem(null);
                }}
              />
            )}
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

interface UploadedFileItemLayoutProps {
  item: UploadItem;
  onClick: (item: UploadItem) => void;
}

const UploadedFileListItemLayout = ({
  item,
  onClick,
}: UploadedFileItemLayoutProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const getIconForFileType = useCallback((fileType: string) => {
    if (fileType.startsWith("image/")) {
      return <ImageOutlinedIcon />;
    } else if (fileType.startsWith("video/")) {
      return <VideocamOutlinedIcon />;
    } else if (fileType.startsWith("application/pdf")) {
      return <PictureAsPdfOutlinedIcon />;
    } else if (fileType.startsWith("application/zip")) {
      return <FolderZipOutlinedIcon />;
    } else if (fileType.startsWith("audio/")) {
      return <AudiotrackOutlinedIcon />;
    } else {
      return <InsertDriveFileOutlinedIcon />;
    }
  }, []);
  return (
    <Grid item key={item.id} xs={12} sm={12} md={12}>
      <Card
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
          borderRadius: "8px",
        }}
      >
        <CardActionArea
          sx={{
            //padding: "24px",
            py: theme.spacing(1),
            px: theme.spacing(4),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            //justifyContent: "space",
            width: "100%",
          }}
          onClick={() => {
            onClick(item);
          }}
        >
          <Box
            sx={{
              width: "25%",
              flex: "1",
              display: "flex",
              flexDirection: "rown",
              alignItems: "center",
            }}
          >
            {" "}
            {getIconForFileType(item.fileType)}
            <Typography
              sx={{
                marginLeft: theme.spacing(2),
                width: "25%",
                flex: "1",
                wordWrap: "break-word",
                textOverflow: "ellipsis",
                fontWeight: "bold",
              }}
              variant="caption"
            >
              {item.fileName}
            </Typography>
          </Box>
          <Typography
            sx={{
              flex: "1",
              boxSizing: "border-box",
              wordWrap: "break-word",
            }}
            variant="caption"
          >
            {t("uploaded_at", {
              date: formatIso8601date(item.createdAt),
            })}
          </Typography>
          {/*   <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
          }}
          variant="caption"
        >
          {item.fileType}
        </Typography> */}

          <Typography
            sx={{
              flex: "1",
              boxSizing: "border-box",
              wordWrap: "break-word",
            }}
            variant="caption"
          >
            {t("by")}{" "}
            {item.uploader
              ? item.uploader.firstName + " " + item.uploader.lastName
              : t("anonymous_user_title")}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const UploadedFileGridItemLayout = ({
  item,
  onClick,
}: UploadedFileItemLayoutProps) => {
  const theme = useTheme();

  const getIconForFileType = useCallback((fileType: string) => {
    if (fileType.startsWith("image/")) {
      return <ImageOutlinedIcon />;
    } else if (fileType.startsWith("video/")) {
      return <VideocamOutlinedIcon />;
    } else if (fileType.startsWith("application/pdf")) {
      return <PictureAsPdfOutlinedIcon />;
    } else if (fileType.startsWith("application/zip")) {
      return <FolderZipOutlinedIcon />;
    } else if (fileType.startsWith("audio/")) {
      return <AudiotrackOutlinedIcon />;
    } else {
      return <InsertDriveFileOutlinedIcon />;
    }
  }, []);

  return (
    <Grid item key={item.id} xs={12} sm={4} md={3}>
      <Card
        sx={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
          borderRadius: "8px",
          height: "100%",
          /* border:
      clickedUploadItem && clickedUploadItem.id == item.id
        ? `2px solid ${theme.palette.primary.main}`
        : "none", */
        }}
      >
        <CardActionArea
          sx={{
            //padding: "24px",
            display: "flex",
            height: "100%",
            flexDirection: "column",
            justifyContent: "start",
            "& .MuiButtonBase-root": {
              flexDirection: "column",
              justifyContent: "start",
            },
          }}
          onClick={() => {
            onClick(item);
          }}
        >
          {item.internalFileUpload &&
            item.hasPreview &&
            !item.fileType.startsWith("application/pdf") && (
              <img
                style={{
                  width: "100%",
                  maxHeight: "150px",
                  objectFit: "cover",
                }}
                src={getUploadItemPreviewLink(item.id)}
                loading="lazy"
              ></img>
            )}
          {item.internalFileUpload &&
            item.hasPreview &&
            item.fileType.startsWith("application/pdf") && (
              <Box
                sx={{
                  width: "100%",
                  maxHeight: "150px",
                  background: "#faf6e9",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "12px",
                }}
              >
                <img
                  style={{
                    width: "70%",
                  }}
                  src={getUploadItemPreviewLink(item.id)}
                  loading="lazy"
                ></img>
              </Box>
            )}
          {(!item.internalFileUpload || !item.hasPreview) && (
            <Box
              sx={{
                width: "100%",
                height: "150px",
                background: "#faf6e9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "12px",
              }}
            >
              {getIconForFileType(item.fileType)}
            </Box>
          )}
          <Box
            sx={{
              py: theme.spacing(1),
              px: theme.spacing(1),
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                wordWrap: "break-word",
                textOverflow: "ellipsis",
                fontWeight: "bold",
                textAlign: "start",
              }}
              variant="caption"
            >
              {item.fileName}
            </Typography>
            <Typography
              sx={{
                width: "100%",
                boxSizing: "border-box",
                wordWrap: "break-word",
                textAlign: "start",
              }}
              variant="caption"
            >
              {formatIso8601date(item.createdAt)}
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
export default React.memo(UploadedFilesContainer);
