import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateUploadLinkModal from "components/modals/CreateUploadLinkModal";
import { useNavigate } from "react-router-dom";
import UploadlyLinksContainer from "./UploadlyLinksContainer";

const AllUploadlyLinksPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();

  const [displayCreateNewLink, setDisplayCreateNewLink] = React.useState(false);
  const [emptyResult, setEmptyResult] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "100%",
        px: theme.spacing(8),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down("md")]: {
          px: theme.spacing(0),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "text.secondary",
          }}
        >
          🔗 {t("upload_links_view_title")}
        </Typography>
        {!emptyResult && (
          <Button
            variant="contained"
            sx={{
              background: theme.palette.primary.main,
            }}
            onClick={() => {
              setDisplayCreateNewLink(true);
            }}
          >
            + {t("add_action")}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          flex: "1",
        }}
      >
        <UploadlyLinksContainer />
      </Box>
      {displayCreateNewLink && (
        <CreateUploadLinkModal
          open={displayCreateNewLink}
          handleModalClose={() => {
            setDisplayCreateNewLink(false);
          }}
        />
      )}
    </Box>
  );
};

export default React.memo(AllUploadlyLinksPage);
