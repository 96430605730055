import { useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardActionArea,
  Drawer,
  Grid,
  IconButton,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { DEBUG } from "configuration";
import { retrieveUploadItemForLinkIdPagedGql } from "graphql/queries";
import { UploadItem, UploadLink } from "models/uploadly";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { formatIso8601date } from "shared/dataUtils";
import { useTranslation } from "react-i18next";

import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import EmptyUploadItemsResult from "res/illustrations/illustration_upload_items_empty.svg";
import UploadedItemDetailContainer from "./UploadedItemDetailContainer";
import { useResponsive } from "hooks/use-responsive";

interface UploadItemsContainerProps {
  uploadLinkId: string;
}

const PAGE_SIZE = 20;
const UploadItemsContainer = ({ uploadLinkId }: UploadItemsContainerProps) => {
  const fetchMoreOngoing = React.useRef(false);
  const lgUp = useResponsive("up", "lg");

  const { t } = useTranslation();
  const theme = useTheme();
  const [allItemsRetrieved, setAllItemsRetrieved] = React.useState(false);
  const [itemsListEmpty, setItemsListEmpty] = React.useState(false);
  // const [selectedItem, setSelectedItemContainer] = React.useState<UploadItem | null>(null);
  const [clickedUploadItem, setClickedUploadItem] = React.useState<
    UploadItem | undefined
  >();
  const lastFetchedPage = React.useRef(1);

  const uploadItemsResult = useQuery(retrieveUploadItemForLinkIdPagedGql, {
    variables: {
      linkId: uploadLinkId,
      page: 1,
      pageSize: PAGE_SIZE,
    },
  });

  React.useEffect(() => {
    if (
      uploadItemsResult.data &&
      !uploadItemsResult.loading &&
      !uploadItemsResult.error
    ) {
      setAllItemsRetrieved(
        uploadItemsResult.data.uploadItemsForLinkPaged.length % PAGE_SIZE !=
          0 || uploadItemsResult.data.uploadItemsForLinkPaged.length == 0
      );
      setItemsListEmpty(
        uploadItemsResult.data.uploadItemsForLinkPaged.length == 0
      );
    }
  }, [uploadItemsResult]);

  const fetchMoreItems = React.useCallback(() => {
    if (fetchMoreOngoing.current) {
      if (DEBUG) {
        console.log("fetchMoreItems already ongoing");
      }
      return;
    }
    fetchMoreOngoing.current = true;

    if (allItemsRetrieved) {
      if (DEBUG) {
        console.log("Already retrieved everything");
      }
      fetchMoreOngoing.current = false;
      return;
    }
    const nextPageToFetch = lastFetchedPage.current + 1;

    if (DEBUG) {
      console.log("Fetch more data for page " + nextPageToFetch);
    }
    uploadItemsResult
      .fetchMore({
        variables: {
          linkId: uploadLinkId,
          page: nextPageToFetch,
          pageSize: PAGE_SIZE,
        },
      })
      .then((result) => {
        if (DEBUG) {
          console.log("Fetch more result %o", result);
        }
        if (
          result.data &&
          result.data.uploadItemsForLinkPaged &&
          result.data.uploadItemsForLinkPaged.length == 0
        ) {
          setAllItemsRetrieved(true);
        }
        lastFetchedPage.current = nextPageToFetch;
        fetchMoreOngoing.current = false;
      })
      .catch((e) => {
        //setFetchOngoing(false)
        fetchMoreOngoing.current = false;
      });
    //}
  }, [
    uploadItemsResult,
    uploadItemsResult.data,
    allItemsRetrieved,
    fetchMoreOngoing.current,
    uploadLinkId,
  ]);

  const handleOnScroll = React.useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        fetchMoreItems();
      }
    },
    [fetchMoreItems]
  );

  const getIconForFileType = useCallback((fileType: string) => {
    if (fileType.startsWith("image/")) {
      return <ImageOutlinedIcon />;
    } else if (fileType.startsWith("video/")) {
      return <VideocamOutlinedIcon />;
    } else if (fileType.startsWith("application/pdf")) {
      return <PictureAsPdfOutlinedIcon />;
    } else if (fileType.startsWith("application/zip")) {
      return <FolderZipOutlinedIcon />;
    } else if (fileType.startsWith("audio/")) {
      return <AudiotrackOutlinedIcon />;
    } else {
      return <InsertDriveFileOutlinedIcon />;
    }
  }, []);

  const [remainingHeight, setRemainingHeight] = useState(0);
  const targetRef = useRef<any>(null);
  const calculateHeight = () => {
    const viewportHeight = window.innerHeight;
    const topOffset = targetRef.current.getBoundingClientRect().top;
    const computedHeight = viewportHeight - topOffset;

    setRemainingHeight(computedHeight);
  };

  useEffect(() => {
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  return (
    <Box
      sx={{
        py: theme.spacing(1),
        paddingBottom: theme.spacing(4),
        // position: "relative",
        display: "flex",
        flexDirection: "row",
        // overflowY: !isIntersecting ? "scroll" : undefined,
        // overflowY: undefined,
        // overflowY: "hidden",
        //  overflow: 'scroll',
        width: "100%",
        flex: "1",
      }}
    >
      <Box
        ref={targetRef}
        onScroll={handleOnScroll}
        sx={{
          display: "flex",
          width: clickedUploadItem ? "50%" : "100%",
          //  background: "yellow",
          height: remainingHeight,
          flex: "1",
          overflowX: "hidden",
          overflowY: "auto",
          justifyContent: "top",
          flexDirection: "column",
          paddingTop: theme.spacing(4),
          py: theme.spacing(1),
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Grid container spacing={1}>
          {uploadItemsResult &&
            uploadItemsResult.data &&
            uploadItemsResult.data.uploadItemsForLinkPaged &&
            uploadItemsResult.data.uploadItemsForLinkPaged.map(
              (item: UploadItem, index: number) => {
                return (
                  <Grid item key={item.id} xs={12} sm={12} md={12}>
                    <Card
                      sx={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                        borderRadius: "8px",
                        border:
                          clickedUploadItem && clickedUploadItem.id == item.id
                            ? `2px solid ${theme.palette.primary.main}`
                            : "none",
                      }}
                    >
                      <CardActionArea
                        sx={{
                          //padding: "24px",
                          py: theme.spacing(1),
                          px: theme.spacing(4),
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          //justifyContent: "space",
                          width: "100%",
                        }}
                        onClick={() => {
                          setClickedUploadItem(item);
                        }}
                      >
                        <Box
                          sx={{
                            width: "25%",
                            flex: "1",
                            display: "flex",
                            flexDirection: "rown",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {getIconForFileType(item.fileType)}
                          <Typography
                            sx={{
                              marginLeft: theme.spacing(2),
                              width: "25%",
                              flex: "1",
                              wordWrap: "break-word",
                              textOverflow: "ellipsis",
                              fontWeight: "bold",
                            }}
                            variant="caption"
                          >
                            {item.fileName}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            flex: "1",
                            boxSizing: "border-box",
                            wordWrap: "break-word",
                            textAlign: "center",
                          }}
                          variant="caption"
                        >
                          {t("uploaded_at", {
                            date: formatIso8601date(item.createdAt),
                          })}
                        </Typography>
                        {/*   <Typography
                          sx={{
                            flex: "1",
                            boxSizing: "border-box",
                            wordWrap: "break-word",
                          }}
                          variant="caption"
                        >
                          {item.fileType}
                        </Typography> */}

                        <Typography
                          sx={{
                            flex: "1",
                            boxSizing: "border-box",
                            wordWrap: "break-word",
                            textAlign: "center",
                          }}
                          variant="caption"
                        >
                          {t("by")}{" "}
                          {item.uploader
                            ? item.uploader.firstName +
                              " " +
                              item.uploader.lastName
                            : t("anonymous_user_title")}
                        </Typography>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              }
            )}
        </Grid>

        {itemsListEmpty && (
          <Card
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              paddingTop: "10%",
            }}
          >
            <img
              src={EmptyUploadItemsResult}
              style={{
                width: "400px",
              }}
            />
            <Typography
              sx={{
                marginTop: theme.spacing(1),
              }}
              variant="subtitle2"
            >
              {t("upload_link_detail_empty_upload_items_title")}
            </Typography>
          </Card>
        )}
      </Box>
      {lgUp ? (
        <Box
          sx={{
            display: "flex",
            height: "90vh",
            px: theme.spacing(2),
            width: clickedUploadItem ? "50%" : "0%",
            position: "sticky",
            top: "2vh",
            overflowY: "auto",
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          {clickedUploadItem && (
            <Card
              sx={{
                flex: "1",
              }}
            >
              <UploadedItemDetailContainer
                uploadItem={clickedUploadItem}
                onClose={() => {
                  setClickedUploadItem(undefined);
                }}
              />
            </Card>
          )}
        </Box>
      ) : (
        <Drawer
          anchor={"right"}
          open={Boolean(clickedUploadItem)}
          onClose={() => {
            setClickedUploadItem(undefined);
          }}
          ModalProps={{
            BackdropProps: {
              style: {
                backgroundColor: "transparent", // Make the backdrop transparent
              },
            },
          }}
          // onClose={toggleDrawer(anchor, false)}
        >
          {/*   {list(anchor)} */}
          <Box
            sx={{
              width: "35vw",
              [theme.breakpoints.down("lg")]: {
                width: "65vw",
              },
              [theme.breakpoints.down("md")]: {
                width: "65vw",
              },
              [theme.breakpoints.down("sm")]: {
                width: "85vw",
              },
            }}
          >
            {clickedUploadItem && (
              <UploadedItemDetailContainer
                uploadItem={clickedUploadItem}
                onClose={() => {
                  setClickedUploadItem(undefined);
                }}
              />
            )}
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default UploadItemsContainer;
