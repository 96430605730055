import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  getWebClientUniqueId,
  setWebClientUniqueId,
} from "localstorage/localstorage";
import { DEBUG } from "configuration";

export const DELIMITER = "|_";
export const A_DAY_IN_MS = 60 * 60 * 24 * 1000; //in ms
export const A_WEEK_IN_MS = A_DAY_IN_MS * 7; //in ms
export const A_MONTH_IN_MS = A_WEEK_IN_MS * 4; //in ms
export const A_YEAR_IN_MS = 60 * 60 * 24 * 1000; //in ms

export const ONE_KB = 1024;
export const ONE_MB = 1024 * ONE_KB;
export const ONE_GB = 1024 * ONE_MB;

export function isValidEmail(email: string | null): boolean {
  if (!email) {
    return false;
  }
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email.toLowerCase());
}

export function isUrlValid(userInput: string) {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
}

export function isValidUnixPath(path: string) {
  const unixPathPattern = /^(\.?\/)?([a-zA-Z0-9._-]+\/?)+$/;
  return unixPathPattern.test(path);
}


export function isValidHttpUrl(input: string): boolean {
  let url;
  try {
    url = new URL(input);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

//validates if url is youtube video url, and if it's the case returns the url of the embed that will be used to play in iframe
export function checkIfYoutubeVideoUrl(url: string): string | null {
  if (url != undefined || url != "") {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return (
        "https://www.youtube.com/embed/" +
        match[2] +
        "?autoplay=1&enablejsapi=1"
      );
    } else {
      return null;
    }
  }
  return null;
}

export function containsOnlyLettersNumbersUnderscore(str: string): boolean {
  // Define the regular expression pattern to match letters, numbers, and underscores
  const regex = /^[a-zA-Z0-9_]+$/;
  // Test if the string matches the pattern
  return regex.test(str);
}

// a username must contain at least 5 characters, must be letters all lower case
export function isUsernameValid(str: string): boolean {
  // Define the regular expression pattern to match letters, numbers, and underscores
  const regex = /^[a-z0-9]*(?:_[a-z0-9]*){0,1}(?:\.[a-z0-9]*){0,1}$/;
  // Test if the string matches the pattern
  if (DEBUG) {
    console.log("isUsernameValid: " + str);
  }
  return regex.test(str) && str.length > 4 && str.length < 26;
}

export function currentTimestamp() {
  return moment().unix();
}

export function getUsageInHumanReadableFormat(dataUsage: number) {
  if (dataUsage < ONE_GB) {
    return (dataUsage / ONE_MB).toPrecision(2) + " Mo";
  } else {
    return (dataUsage / ONE_GB).toPrecision(2) + " Go";
  }
}

export function bytesToGigabytes(bytes: number | null | undefined) {
  const gigabytes = bytes ? bytes / ONE_GB : 0;
  return gigabytes.toFixed(2);
}

export function getSizeInHumeanReadableFormat(
  bytes: number | null | undefined
) {
  if (!bytes) {
    return 0;
  }
  const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let index = 0;
  let size = bytes;

  while (size >= 1024 && index < units.length - 1) {
    size /= 1024;
    index++;
  }
  // Use at most two decimal places for better readability
  return `${Math.trunc(
    parseInt(size.toFixed(size < 10 && index > 0 ? 2 : 1))
  )} ${units[index]}`;
}

export function iso8601dateToTimestamp(iso8601date: string) {
  return moment(iso8601date).unix();
}

export function getTimeElapsedsince(iso8601date: string) {
  const momentTime = moment(iso8601date);
  const delta = moment.duration(moment().diff(momentTime));
  const deltaInSeconds = delta.asSeconds();
  if (deltaInSeconds < 60) {
    return Math.round(delta.asSeconds()) + "s";
  } else if (deltaInSeconds < 60 * 60) {
    return Math.round(delta.asMinutes()) + "m";
  } else if (deltaInSeconds < 60 * 60 * 24) {
    return Math.round(delta.asHours()) + "h";
  } else if (deltaInSeconds < 60 * 60 * 24 * 7) {
    return Math.round(delta.asDays()) + "d";
  } else {
    return Math.round(delta.asWeeks()) + "w";
  }
}

export function getInChronometerFormat(timestampInSeconds: number) {
  const hoursCount = Math.floor(timestampInSeconds / 3600);
  const minutesCount = Math.floor(
    (timestampInSeconds - hoursCount * 3600) / 60
  );
  const secondsCount = Math.floor(timestampInSeconds - minutesCount * 60);
  var result =
    (minutesCount > 9 ? minutesCount : "0" + minutesCount) +
    ":" +
    (secondsCount > 9 ? secondsCount : "0" + secondsCount);
  if (hoursCount > 0) {
    result = (hoursCount > 9 ? hoursCount : "0" + hoursCount) + ":" + result;
  }
  return result;
}

export function formatIso8601date(iso8601date: string): string {
  const momentTime = moment(iso8601date);
  if (momentTime.isSame(new Date(), "day")) {
    // if the timestamp is today
    return momentTime.format("hh:mm A");
  } else if (momentTime.isSame(new Date(), "year")) {
    return momentTime.format("Do MMM");
  } else {
    return momentTime.format("Do MMM,YYYY");
  }
}
export function formatIso8601dateWithTime(iso8601date: string): string {
  const momentTime = moment(iso8601date);
  if (momentTime.isSame(new Date(), "day")) {
    // if the timestamp is today
    return momentTime.format("hh:mm A");
  } else if (momentTime.isSame(new Date(), "year")) {
    return momentTime.format("Do MMM hh:mm A");
  } else {
    return momentTime.format("Do MMM,YYYY hh:mm A");
  }
}
export function formatTimeStamp(timestamp: number): string {
  const momentTime = moment.unix(timestamp);
  if (momentTime.isSame(new Date(), "day")) {
    // if the timestamp is today
    return momentTime.format("hh:mm A");
  } else if (momentTime.isSame(new Date(), "year")) {
    return momentTime.format("Do MMM, hh:mm A");
  } else {
    return momentTime.format("Do MMM,YYYY hh:mm A");
  }
}

export function formatIso8601(iso8601date: string) {
  //console.log("formatIso8601" + iso8601date)
  const momentTime = moment(iso8601date);
  return formatTimeStamp(momentTime.unix());
}

export function isDateFromYesterday(date: Date): boolean {
  const yesterdaysDate = moment().subtract(1, "days").startOf("day");
  return yesterdaysDate.isSame(date, "d");
}

export function isDateFromTomorrow(date: Date): boolean {
  const tomorrowsDate = moment().add(1, "days").startOf("day");
  return tomorrowsDate.isSame(date, "d");
}

export function isDateFromToday(date: Date): boolean {
  const tomorrowsDate = moment().startOf("day");
  return tomorrowsDate.isSame(date, "d");
}

export function formatToContextualDate(date: Date): string {
  const currentDate = moment();
  const momentTime = moment.unix(date.getTime() / 1000);
  if (currentDate.year() != momentTime.year()) {
    return momentTime.format("Do MMMM YYYY");
  } else {
    return momentTime.format("Do MMM");
  }
}

export function formatToContextualDateIntervalForWeek(
  startDate: Date,
  endDate: Date
): string {
  if (!startDate || !endDate) {
    return "";
  }
  const startDateM = moment.unix(startDate.getTime() / 1000);
  const endDateM = moment.unix(endDate.getTime() / 1000);
  if (startDate.getDate() > endDate.getDate()) {
    return startDateM.format("D MMMM") + " - " + endDateM.format("D MMMM");
  } else {
    return (
      startDateM.format("D") +
      " - " +
      endDateM.format("D ") +
      startDateM.format("MMMM")
    );
  }
}

/**
 * Returns month name for a monthNumber
 * @param monthNumber value between 1-12
 * @returns
 */
export function getMonthFromMonthNumber(monthNumber: number) {
  return moment(monthNumber, "M").format("MMMM");
}

export function getDateFromTimestamp(timestamp: number): string {
  const momentTime = moment.unix(timestamp);
  return momentTime.format("Do MMM YYYY");
}

export function formatTimeStampForCyclePicker(timestamp: number): string {
  const momentTime = moment.unix(timestamp);
  return momentTime.format("ddd Do MMM, hh:mm A");
}

export function isFromPast(timestamp: number): Boolean {
  return new Date().getTime() - timestamp > 0;
}

export function getTodaysDate() {
  const momentTime = moment();
  return momentTime.format("DD/MM/YYYY");
}

export function getDateFromIso8601(iso8601date: string) {
  const momentTime = moment(iso8601date);
  return momentTime.format("DD MMM YYYY");
}

export function getDateAndTimeFromIso8601(iso8601date: string) {
  const momentTime = moment(iso8601date);
  return momentTime.format("DD MMM YYYY [at] HH:mm");
}

export function getStartOfNextMonth() {
  const momentTime = moment().endOf("month").add(1, "days");
  return momentTime.format("DD MMM YYYY");
}

export function getDateInOneMonth() {
  const momentTime = moment().add(1, "M");
  return momentTime.format("DD/MM/YYYY");
}

export function getDateInOneYear() {
  const momentTime = moment().add(12, "M");
  return momentTime.format("DD/MM/YYYY");
}

export function isDifferenceSmallerThanDelta(
  date1: Date,
  date2: Date,
  delta: number
): boolean {
  const difference = Math.abs(date1.getTime() - date2.getTime());
  return difference < delta;
}

export function appendDeltaAndFormatDate(
  iso8601date: string,
  deltaInMs: number
): string {
  const momentTime = moment(iso8601date);
  momentTime.add(deltaInMs, "milliseconds");
  if (momentTime.isSame(new Date(), "day")) {
    // if the timestamp is today
    return momentTime.format("hh:mm A");
  } else if (momentTime.isSame(new Date(), "year")) {
    return momentTime.format("Do MMMM");
  } else {
    return momentTime.format("Do MMM,YYYY");
  }
}

export function getDeviceName() {
  const browser = getBrowser();
  if (browser && browser.length > 0) {
    return getOperatingSystem() + " (" + browser + ")";
  } else {
    return getOperatingSystem();
  }
}

export function getClientUniqueId() {
  const clientUniqueId = getWebClientUniqueId();
  if (clientUniqueId) {
    return clientUniqueId;
  } else {
    const uuid = uuidv4();
    //We persist this uuid and reuse it if required next time
    setWebClientUniqueId(uuid);
    return uuid;
  }
}

export function getOperatingSystem() {
  var OSName = "Web";
  if (window.navigator.userAgent.indexOf("Windows NT 11.0") != -1)
    OSName = "Windows 11";
  if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
    OSName = "Windows 10";
  if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
    OSName = "Windows 8.1";
  if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
    OSName = "Windows 8";
  if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
    OSName = "Windows 7";
  if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
    OSName = "Windows Vista";
  if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
    OSName = "Windows XP";
  if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
    OSName = "Windows 2000";
  if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
  if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
  if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

  return OSName;
}

export function getBrowser() {
  let currentBrowser = null;
  if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
    currentBrowser = "Google Chrome";
  } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
    currentBrowser = "Mozilla Firefox";
  } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
    currentBrowser = "Internet Exployer";
  } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
    currentBrowser = "Edge";
  } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
    currentBrowser = "Safari";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "Opera";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "YaBrowser";
  }
  return currentBrowser;
}

export function getRemainingTime(isoDate: string, translation?: any) {
  const userLocale = navigator.language;

  // Set the locale for moment
  moment.locale(userLocale);
  const now = moment();
  const futureDate = moment(isoDate);

  if (!futureDate.isValid()) {
    return translation("invalid_date_format");
  }

  const duration = moment.duration(futureDate.diff(now));

  if (duration.asMilliseconds() <= 0) {
    return translation("date_already_passed");
  }

  let result = "";

  if (duration.asYears() >= 1) {
    const years = Math.floor(duration.asYears());
    result = `${years} ${
      years === 1 ? translation("year") : translation("years")
    }`;
  } else if (duration.asMonths() >= 1) {
    const months = Math.floor(duration.asMonths());
    result = `${months} ${
      months === 1 ? translation("month") : translation("months")
    }`;
  } else if (duration.asDays() >= 1) {
    const days = Math.floor(duration.asDays());
    result = `${days} ${days === 1 ? translation("day") : translation("days")}`;
  } else if (duration.asHours() >= 1) {
    const hours = Math.floor(duration.asHours());
    result = `${hours} ${
      hours === 1 ? translation("hour") : translation("hours")
    }`;
  } else if (duration.asMinutes() >= 1) {
    const minutes = Math.floor(duration.asMinutes());
    result = `${minutes} ${
      minutes === 1 ? translation("minute") : translation("minutes")
    }`;
  } else {
    const seconds = Math.floor(duration.asSeconds());
    result = `${seconds} ${
      seconds === 1 ? translation("second") : translation("seconds")
    }`;
  }

  return result;
}

const otanAlphabet: string[] = [
  "alpha",
  "bravo",
  "charlie",
  "delta",
  "echo",
  "foxtrot",
  "golf",
  "hotel",
  "india",
  "juliet",
  "kilo",
  "lima",
  "mike",
  "november",
  "oscar",
  "papa",
  "quebec",
  "romeo",
  "sierra",
  "tango",
  "uniform",
  "victor",
  "whiskey",
  "x-ray",
  "yankee",
  "zulu",
];
// Function to generate a random combination of two words
export function generateRandomCombination(): string {
  // Select two random words from the otanAlphabet array
  const randomIndex1: number = Math.floor(Math.random() * otanAlphabet.length);
  const randomIndex2: number = Math.floor(Math.random() * otanAlphabet.length);

  // Construct the combination of two words
  const combination: string = `${otanAlphabet[randomIndex1]}-${otanAlphabet[randomIndex2]}`;

  return combination;
}
