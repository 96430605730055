import React, { useCallback, useState } from "react";
import GoogleIcon from "res/icons/google_icon.svg";
import { useTranslation } from "react-i18next";
import {
  LoginApiResult,
  SignupApiResult,
  SignupStatusCodes,
  fetchUserData,
  getCsrf,
  getGoogleUserInfoFromAccessToken,
  handleGoogleLoginResponse,
  signUp,
} from "api/authApi";
import {
  DEBUG,
  FIRST_NAME_LAST_NAME_MIN_LENGTH,
  GOOGLE_LOGIN_KEY,
  PASSWORD_MIN_LENGTH,
} from "configuration";
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserObj } from "models/user";
import { loginUser as loginReducerAction } from "store/auth";
import { setPathAfterLogin } from "store/view";
import { RootState } from "store";
import { MAINBOARD } from "navigation/Constants";
import {
  CredentialResponse,
  GoogleLogin,
  TokenResponse,
  useGoogleLogin,
} from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Link from "@mui/material/Link";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import EmailSentIllustration from "res/illustrations/email_sent_illustration.svg";
import { isValidEmail } from "shared/dataUtils";
import { Helmet } from "react-helmet-async";

const INITIAL_SIGNUP_STATUS = {
  signupFailed: false,
  errorMsg: "",
};

interface SignUpResult {
  signupFailed: boolean;
  errorMsg: string;
}

interface InputErrors {
  firstNameError: string | null;
  lastNameError: string | null;
  emailError: string | null;
  passwordError: string | null;
  passwordConfirmationError: string | null;
}

const INPUT_ERRORS_INITIAL_STATE = {
  firstNameError: null,
  lastNameError: null,
  emailError: null,
  passwordError: null,
  passwordConfirmationError: null,
} as InputErrors;
const SignupPage = () => {
  const userGoogleAccountFirstName = React.useRef();
  const userGoogleAccountEmail = React.useRef();
  const userGoogleAccessToken = React.useRef();

  const [signupsuccess, setSignupSuccess] = useState(false);

  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const afterLoginPath = useSelector(
    (state: RootState) => state.view.pathAfterLogin
  );
  const [signupStatus, setSignupStatus] = useState<SignUpResult>(
    INITIAL_SIGNUP_STATUS
  );

  const [inputsErrors, setInputsErrors] = useState<InputErrors>(
    INPUT_ERRORS_INITIAL_STATE
  );

  const firstNameRef = React.useRef<any>();
  const lastNameRef = React.useRef<any>();
  const emailRef = React.useRef<any>();
  const passwordRef = React.useRef<any>();
  const passwordConfirmationRef = React.useRef<any>();

  const validateInputs = useCallback(() => {
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const passWordConfirmation = passwordConfirmationRef.current.value;

    var localInputErrors = INPUT_ERRORS_INITIAL_STATE;
    if (firstName.length < FIRST_NAME_LAST_NAME_MIN_LENGTH) {
      localInputErrors = {
        ...localInputErrors,
        firstNameError: t("first_name_last_name_too_short_error", {
          characters_count: FIRST_NAME_LAST_NAME_MIN_LENGTH,
        }),
      };
    }
    if (lastName.length < FIRST_NAME_LAST_NAME_MIN_LENGTH) {
      localInputErrors = {
        ...localInputErrors,
        lastNameError: t("first_name_last_name_too_short_error", {
          characters_count: FIRST_NAME_LAST_NAME_MIN_LENGTH,
        }),
      };
    }
    if (!isValidEmail(email)) {
      localInputErrors = {
        ...localInputErrors,
        emailError: t("email_invalid"),
      };
    }

    if (password.length < PASSWORD_MIN_LENGTH) {
      localInputErrors = {
        ...localInputErrors,
        passwordError: t("password_invalid"),
      };
    }
    if (passWordConfirmation != password) {
      localInputErrors = {
        ...localInputErrors,
        passwordConfirmationError: t(
          "register_password_confirmation_not_match"
        ),
      };
    }
    setInputsErrors(localInputErrors);

    if (localInputErrors != INPUT_ERRORS_INITIAL_STATE) {
      return false;
    }
    return true;
  }, [
    inputsErrors,
    firstNameRef.current,
    lastNameRef.current,
    passwordConfirmationRef.current,
    passwordRef.current,
    emailRef.current,
  ]);

  const handleRegister = useCallback(() => {
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const passWordConfirmation = passwordConfirmationRef.current.value;
    const result = validateInputs();
    if (!result) {
      return;
    }

    setIsLoading(true);

    signUp({
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      password_confirmation: passWordConfirmation,
    })
      .then((result: SignupApiResult) => {
        setIsLoading(false);
        switch (result.statusCode) {
          case SignupStatusCodes.USER_EXISTS_ALREADY.valueOf():
            setSignupFailed(t("signup_user_already_exists_error"));
            break;
          case SignupStatusCodes.UNKOWN.valueOf():
            setSignupFailed(t("signup_user_unknown_error"));
            break;
          case SignupStatusCodes.SUCCESS.valueOf():
            setSignupSuccess(true);
            break;
        }
        console.log(
          "Signup result from register " + result.statusCode.toString()
        );
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [
    validateInputs,
    firstNameRef.current,
    lastNameRef.current,
    emailRef.current,
    passwordRef.current,
    passwordConfirmationRef.current,
  ]);

  const [showPassword, setShowPassword] = useState(false);

  const setSignupFailed = useCallback(
    (errorMsgArg: string) => {
      setSignupStatus({ signupFailed: true, errorMsg: errorMsgArg });
      setIsLoading(false);
    },
    [signupStatus]
  );

  const login = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/drive.file",
    onSuccess: (codeResponse: TokenResponse) => {
      console.log("Got the response here %o", codeResponse);
      console.log("After dec");

      // const decoded = jwtDecode(codeResponse.access_token!!);
      console.log("After decoding" + JSON.stringify(codeResponse.access_token));
      onGoogleLoginSuccess(codeResponse.access_token, true);
      /* getGoogleUserInfoFromAccessToken(codeResponse.access_token).then(
        (result) => {
          console.log("Fetched user data and got the result here %o", result);
        }
      ); */
    },

    onError: (error) => console.log("Login Failed:", error),
  });
  /*  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("code response is %o", codeResponse);
    },
    onError: (error: any) => console.log("Login Failed:", error),
  }); */
  const [openCreateAccount, setOpenCreateAccount] = React.useState(false);

  const dispatchLogin = (user: UserObj) => {
    dispatch(loginReducerAction(user));
  };

  const setLoginSucceeded = () => {
    fetchUserData()
      .then((user: UserObj) => {
        dispatchLogin(user);
        if (DEBUG) {
          console.log("will move to path " + afterLoginPath);
        }

        if (afterLoginPath && afterLoginPath.length > 0) {
          navigate(afterLoginPath);
          dispatch(setPathAfterLogin(null));
        } else {
          console.log("Will navigate to the mainboard");
          navigate(MAINBOARD);
        }
        if (DEBUG) console.log("User data is  " + JSON.stringify(user));
      })
      .catch((err) => {
        console.log("Caught an error while fetch user data" + err);
      });
  };

  const onGoogleLoginSuccess = (
    access_token: any,
    createAccountIfNotExists = false
  ) => {
    setSignupStatus(INITIAL_SIGNUP_STATUS);
    setIsLoading(true);
    setTimeout(() => {
      //this is just a hack.. to avoid error on backend token used too early
      const response = handleGoogleLoginResponse(access_token, true);
      response
        .then((result: any) => {
          if (result.status === 200 && result.data.status_code == 0) {
            setLoginSucceeded();
          } else {
            setSignupStatus({
              signupFailed: true,
              errorMsg: t("google_signup_failed_msg"),
            });
          }
          if (DEBUG) {
            console.log("Got a result :" + JSON.stringify(result));
          }
          setIsLoading(false);
        })
        .catch((e: any) => {
          setIsLoading(false);
        });
    }, 5000);

    /* setTimeout(() => {
      const response = handleGoogleLoginResponse(
        access_token,
        createAccountIfNotExists
      );
      response
        .then((result: any) => {
          console.log("Google login result  %o", result);
          if (result.status === 200 && result.data.status_code == 0) {
            setLoginSucceeded();
          } else if (result.status == 200 && result.data.status_code == 5) {
            userGoogleAccountFirstName.current = result.data.first_name;
            userGoogleAccountEmail.current = result.data.email;
            userGoogleAccessToken.current = access_token;
            setOpenCreateAccount(true);
          } else {
            setSignupFailed(t("google_login_failed_msg"));
          }
          if (DEBUG) {
            console.log("Got a result :" + JSON.stringify(result));
          }
          setIsLoading(false);
        })
        .catch((e: any) => {
          setIsLoading(false);
        });
    }, 2000); */ //this is just a hack.. to avoid error on backend token used too early
  };

  if (signupsuccess) {
    return (
      <Container
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        maxWidth="sm"
      >
        <Helmet>
          <title>{t("signup_action")} | Uploadly</title>
        </Helmet>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px",
            background: "#f8fbf8",
            borderRadius: "16px",
            marginTop: "24px",
            minWidth: "30%",
            "@media (max-width: 780px)": {
              minWidth: "80%",
            },
          }}
          elevation={3}
        >
          <img
            style={{ position: "absolute", top: "16px", left: "64px" }}
            height="48px"
            width="48px"
            src={UploadlyIcon}
          />
          <img
            id="logo"
            width="196px"
            height="196px"
            style={{ marginTop: "36px", marginBottom: "36px" }}
            src={EmailSentIllustration}
            alt="Memento logo"
          />
          <Typography
            sx={{
              marginBottom: "24px",
              textAlign: "center",
            }}
            variant="subtitle1"
            gutterBottom
          >
            {t("confirmation_email_sent_msg")}
          </Typography>
        </Paper>
      </Container>
    );
  } else
    return (
      <>
        <Container
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          maxWidth="sm"
        >
          <Helmet>
            <title>{t("signup_action")} | Uploadly</title>
          </Helmet>
          <img
            style={{ position: "absolute", top: "16px", left: "64px" }}
            height="48px"
            width="48px"
            src={UploadlyIcon}
          />
          <Card
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              px: theme.spacing(4),
              py: theme.spacing(2),
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            }}
          >
            <Typography
              sx={{
                marginBottom: "16px",
              }}
              variant="h4"
            >
              {t("sign_up")}
            </Typography>
            {signupStatus.signupFailed && (
              <Alert style={{}} id="error-alert" severity="error">
                {signupStatus.errorMsg}
              </Alert>
            )}
            <Stack sx={{ my: 3, width: "100%" }} spacing={3}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 3, width: "100%" }}
                spacing={1}
              >
                <TextField
                  inputRef={firstNameRef}
                  error={Boolean(inputsErrors.firstNameError)}
                  helperText={inputsErrors.firstNameError}
                  name="firstname"
                  placeholder={t("first_name")}
                  sx={{
                    flex: "1",
                  }}
                />
                <TextField
                  inputRef={lastNameRef}
                  error={Boolean(inputsErrors.lastNameError)}
                  helperText={inputsErrors.lastNameError}
                  name="lastname"
                  placeholder={t("last_name")}
                  sx={{
                    flex: "1",
                  }}
                />
              </Stack>

              <TextField
                inputRef={emailRef}
                error={Boolean(inputsErrors.emailError)}
                helperText={inputsErrors.emailError}
                name="email"
                placeholder={t("email")}
              />

              <TextField
                inputRef={passwordRef}
                error={Boolean(inputsErrors.passwordError)}
                helperText={inputsErrors.passwordError}
                name="password"
                placeholder={t("password")}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                inputRef={passwordConfirmationRef}
                error={Boolean(inputsErrors.passwordConfirmationError)}
                helperText={inputsErrors.passwordConfirmationError}
                name="password_confirmation"
                placeholder={t("password_confirmation")}
                type={showPassword ? "text" : "password"}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleRegister();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              sx={{ marginTop: 3 }}
              onClick={handleRegister}
              loading={isLoading}
            >
              {t("sign_up")}
            </LoadingButton>

            <Divider sx={{ my: 3 }}>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", textTransform: "uppercase" }}
              >
                {t("or")}
              </Typography>
            </Divider>

            <Button
              variant="contained"
              sx={{
                textTransform: "uppercase",
                padding: theme.spacing(1),
                px: theme.spacing(3),
              }}
              onClick={() => {
                login();
              }}
            >
              <Box
                sx={{
                  background: "white",
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px",
                  borderRadius: "4px",
                  marginRight: theme.spacing(4),
                }}
              >
                <img src={GoogleIcon} />
              </Box>{" "}
              {t("continue_with_google_title")}
            </Button>

            {/*   <button
              onClick={() => {
                login();
              }}
              style={{
                width: "100%",
                backgroundColor: "rgb(255, 255, 255)",
                display: "inline-flex",
                alignItems: "center",
                color: "rgba(0, 0, 0, 0.54)",
                // boxShadow:
                //   "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
                padding: "0px",
                borderRadius: "4px",
                border: "1px solid transparent",
                fontSize: "14px",
                fontWeight: 500,
                borderWidth: "1px",
                borderColor: "gray",
                fontFamily: "Roboto, sans-serif",
              }}
              type="button"
            >
              <div
                style={{
                  marginRight: "10px",
                  background: "rgb(255, 255, 255)",
                  padding: "10px",
                  borderRadius: " 2px",
                }}
              >
                <img src={GoogleIcon} />
              </div>
              <span
                style={{
                  width: "100%",
                  padding: "10px 10px 10px 0px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              >
                {t("singup_with_google")}
              </span>
            </button> */}
          </Card>
        </Container>
      </>
    );
};

export default React.memo(SignupPage);
