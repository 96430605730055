import { A_DAY_IN_MS, ONE_GB } from "shared/dataUtils";
import { Owner } from "./uploadly";

export enum PaymentType { //should be in sync with values from the backend
  GOOGLE_PLAY_BILLING = 1,
  CARD_STRIPE = 2,
}
export interface SharedUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface UserObj {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  uploadlyStorageUsedSpace: number;
  subscriptionPlan: SubscriptionPlan;
}

export enum SubscriptionPlanIds {
  FREE = 0,
  STANDARD = 1,
  BUSINESS = 2,
}

export interface SubscriptionPlan {
  id: number;
  name: string;
}

export interface SubscriptionInformation {
  subscriptionStart: number; //tmstamp
  lastPaymentDate: number; //tmstamp
  nextPaymentDate: number; //tmstamp
  isCancelled: boolean;
  paymentInformation: PaymentInformation | null;
}

export interface PaymentInformation {
  cardBrand: string;
  cardLast4Digits: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
}

export const getUserInitials = (user: UserObj | Owner | null) => {
  if (!user) {
    return "..";
  }
  if (user.lastName.length > 0 && user.firstName.length > 0) {
    return user.lastName.charAt(0) + user.firstName.charAt(0);
  }
  if (user.lastName.length > 0) {
    return user.lastName.charAt(0);
  }
  if (user.firstName.length > 0) {
    return user.firstName.charAt(0);
  }
  return "XX";
};

export const getUserMaxStorageUsage = (user: UserObj | undefined | null) => {
  if (!user) {
    return 0;
  }
  if (user.subscriptionPlan.id == SubscriptionPlanIds.FREE) {
    return ONE_GB;
  } else if (user.subscriptionPlan.id == SubscriptionPlanIds.STANDARD) {
    return ONE_GB * 10;
  } else if (user.subscriptionPlan.id == SubscriptionPlanIds.BUSINESS) {
    return ONE_GB * 100;
  }
  return ONE_GB;
};

export const getUserMaxAllowedSizePerUploadLink = (
  user: UserObj | undefined | null
) => {
  if (!user) {
    return 0;
  }
  if (user.subscriptionPlan.id == SubscriptionPlanIds.FREE) {
    return ONE_GB;
  } else if (user.subscriptionPlan.id == SubscriptionPlanIds.STANDARD) {
    return ONE_GB * 5;
  } else if (user.subscriptionPlan.id == SubscriptionPlanIds.BUSINESS) {
    return Number.MAX_SAFE_INTEGER;
  }
  return ONE_GB;
};


export const getUserMaxAllowedUploadCountsPerLink = (
  user: UserObj | undefined | null
) => {
  if (!user) {
    return 0;
  }
  if (user.subscriptionPlan.id == SubscriptionPlanIds.FREE) {
    return 100;
  } else if (user.subscriptionPlan.id == SubscriptionPlanIds.STANDARD) {
    return 5_00;
  } else if (user.subscriptionPlan.id == SubscriptionPlanIds.BUSINESS) {
    return Number.MAX_SAFE_INTEGER;
  }
  return ONE_GB;
};

export const getUserMaxUploadLinkExpiryDuration = (
  user: UserObj | undefined | null
) => {
  if (!user) {
    return 0;
  }
  if (user.subscriptionPlan.id == SubscriptionPlanIds.FREE) {
    return A_DAY_IN_MS * 7;
  } else if (user.subscriptionPlan.id == SubscriptionPlanIds.STANDARD) {
    return A_DAY_IN_MS * 30;
  } else if (user.subscriptionPlan.id == SubscriptionPlanIds.BUSINESS) {
    return Number.MAX_SAFE_INTEGER;
  }
  return ONE_GB;
};
