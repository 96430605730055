import { Box } from "@mui/material";
import { fetchUserData } from "api/authApi";
import { fetchUserCurrency } from "api/geoipApi";
import Pricing from "components/landing/Pricing";
import Header from "components/ui/Header";
import { UserObj } from "models/user";
import { ROOT } from "navigation/Constants";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import { RootState } from "store";
import { loginUser, logoutUser } from "store/auth";

const PricingPage = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.auth.user);


  
  React.useEffect(() => {
   /*  fetchUserCurrency().then((result: any) => {
      console.log("user currency result is " + result)
      console.log("currency code " + result.currency.code)
      console.log("currency symbol " + result.currency.symbol)
    }) */
    if (!user && isUserLoggedIn) {
      fetchUserData()
        .then((user: UserObj) => {
          dispatch(loginUser(user));
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      {isUserLoggedIn && <Header />}
      <Box>
        <img
          style={{ position: "absolute", top: "16px", left: "64px" }}
          height="48px"
          width="48px"
          src={UploadlyIcon}
          onClick={() => {
            navigate(ROOT);
          }}
        />
      </Box>
      <Pricing />
    </Box>
  );
};

export default React.memo(PricingPage);
