export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const FRONT_URL = process.env.REACT_APP_FRONTEND_URL;
export const GOOGLE_LOGIN_KEY = process.env.REACT_APP_GOOGLE_LOGIN_KEY;
export const REACT_APP_GOOGLE_AUTH_REDIRECT_URL =
  process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URL;
export const GOOGLE_DEVELOPER_KEY = process.env.REACT_APP_GOOGLE_DEVELOPER_KEY;

export const ONEDRIVE_CLIENT_ID = process.env.REACT_APP_ONEDRIVE_CLIENT_ID;
export const ONEDRIVE_AUTH_REDIRECT_URL =
  process.env.REACT_APP_ONEDRIVE_AUTH_REDIRECT_URL;

export const DROPBOX_CLIENT_ID = process.env.REACT_APP_DROPBOX_CLIENT_ID;
export const DROPBOX_AUTH_REDIRECT_URL =
  process.env.REACT_APP_DROPBOX_AUTH_REDIRECT_URL;

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_KEY;
export const GEOIP_IP_API_KEY = process.env.REACT_APP_GEO_IP_KEY;

export const DEBUG = process.env.REACT_APP_DEBUG == "true";
export const PASSWORD_MIN_LENGTH = 8;
export const FIRST_NAME_LAST_NAME_MIN_LENGTH = 2;
export const LAST_UPLOAD_ITEMS_RETRIEVED_IN_DASHBOARD = 4;
export const LAST_UPLOAD_LINKS_RETRIEVED_IN_DASHBOARD = 5;

export const MAX_UPLOAD_LINKS_FREE_PLAN = 1;
export const MAX_UPLOAD_LINKS_STANDARD_PLAN = 50;

export const DEFAULT_CURRENCY = "USD";
export const UPLOAD_ATTACHEMENTS_EMAIL = "add@mg.uploadly.io";

export const getUploadLinkUrlFromToken = (token) => {
  return FRONT_URL + "/upload/" + token;
};

export const getUploadLinkDetailViewRelativeUrlFromId = (id) => {
  return "/home/link/" + id;
};

export const getConnectedStorageDetailViewRelativeUrlFromId = (id) => {
  return "/home/storage/" + id;
};

export const getInternalFileAccessLink = (id) => {
  return SERVER_URL + "/integrations/file/" + id;
};

export const getInternalFileDownloadLink = (id) => {
  return SERVER_URL + "/integrations/download/" + id;
};

export const getUploadItemPreviewLink = (id) => {
  return SERVER_URL + "/integrations/preview/" + id;
};

export const getEmailUploadItemPreviewLink = (id) => {
  return SERVER_URL + "/integrations/epreview/" + id;
};

export function getUserAvatarForId(id) {
  if (!id) {
    return "";
  }
  return SERVER_URL + "/integrations/avatar/" + id;
}

export function getUserAvatarForUploadLinkId(id) {
  return SERVER_URL + "/integrations/avatar-for-link/" + id;
}

export function getUserAvatarForUsername(username) {
  return SERVER_URL + "/uav/" + username;
}
