import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  CircularProgressProps,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Tooltip,
  Typography,
  keyframes,
  useTheme,
} from "@mui/material";
import { LogoutApiResult, LogoutStatusCodes, logout } from "api/authApi";
import { DEBUG } from "configuration";
import { uploadFileGql } from "graphql/mutations";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import VoidIllustration from "res/illustrations/illustration_void.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { retrievePublicUploadLinkForTokenGql } from "graphql/queries";
import PublicUploadLinkContainer from "components/ui/publicupload/PublicUploadLinkContainer";
import { UploadLink, UploadLinkStatus } from "models/uploadly";
import SimpleConfirmationDialog, {
  OpenSimpleConfirmationDialogPayload,
} from "components/ui/dialogs/SimpleConfirmationDialog";
import { getSizeInHumeanReadableFormat } from "shared/dataUtils";
import {
  UploadLinkInvalidErrors,
  UploadLinkValidityCheckResult,
  checkUploadLinkStatus,
} from "api/uploadLinkApi";
import PageNotFound from "./PageNotFound";
import Lottie from "lottie-react";
import animationData from "res/animations/complete_animation.json";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

/* const interface FileUploadProgress{

} */

interface UploadableFile {
  fileObj: File;
  progress: number;
  isUploaded: boolean;
}
interface UploadItemInformationFields {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

const PublicUpload = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const userLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const params: any = useParams();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [progress, setProgress] = useState<number>(0.5);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [uploadOngoing, setUploadOngoing] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [
    uploadLinkValidityCheckOngoing,
    setUploadLinkValidityCheckOngoing,
  ] = useState(true);
  const [
    uploadLinkValidityCheckVerdict,
    setUploadLinkValidityCheckVerdict,
  ] = useState<UploadLinkValidityCheckResult | null>(null);
  const [
    openConfirmationDialogPayload,
    setOpenConfirmationDialogPayload,
  ] = useState<OpenSimpleConfirmationDialogPayload>({
    open: false,
    description: null,
  });
  const abort = React.useRef<any>();
  const { t } = useTranslation();

  const validateFields = React.useRef<any>();

  const [
    fileBeingUploaded,
    setFileBeingUploaded,
  ] = React.useState<UploadableFile | null>(null);

  const [
    retrievePublicUploadLink,
    retrievePublicUploadLinkResult,
  ] = useLazyQuery(retrievePublicUploadLinkForTokenGql, {
    variables: {
      token: params.id,
    },
    context: {
      clientName: "public",
    },
  });

  React.useEffect(() => {
    if (params.id) {
      checkUploadLinkStatus(params.id)
        .then((result) => {
          console.log("Checking link result %o", result);
          setUploadLinkValidityCheckOngoing(false);
          setUploadLinkValidityCheckVerdict(result);
          if (result.valid) {
            retrievePublicUploadLink();
          }
        })
        .catch((e) => {
          setUploadLinkValidityCheckOngoing(false);
        });
      //
    }
  }, []);

  const [uploadFile, uploadFileResult] = useMutation(uploadFileGql, {
    context: {
      clientName: "public",
      fetchOptions: {
        useUpload: true,
        onProgress: (ev: ProgressEvent) => {
          console.log("Got new progress event");
          const progress = ev.loaded / ev.total;
          if (fileBeingUploaded) {
            fileBeingUploaded.progress = progress;
            //setFileBeingUploaded(fileBeingUploaded)
          }
          setProgress(ev.loaded / ev.total);
        },
        onAbortPossible: (abortHandler: any) => {
          abort.current = abortHandler;
        },
      },
    },
  });

  function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const filesList = e.target.files;
    if (!filesList) {
      return;
    }
    // addFiles(e.target.files);
    const uploadLink: UploadLink | undefined =
      retrievePublicUploadLinkResult.data?.uploadLink;
    if (!uploadLink) {
      return;
    }
    var filesExcludedDueToUploadLinkConstraints: UploadableFile[] = [];
    var totalfilesSize = 0;
    var totalfilesCount = 0;
    var uploadableFilesList: UploadableFile[] = Array.from(filesList).map(
      (file: File) => {
        totalfilesSize += file.size;
        totalfilesCount++;
        const uploadableFileObject = {
          fileObj: file,
          progress: 0,
          isUploaded: false,
        };
        if (totalfilesSize > uploadLink.remainingSpace) {
          filesExcludedDueToUploadLinkConstraints.push(uploadableFileObject);
        }
        return uploadableFileObject;
      }
    );

    //We check if some files were excluded because they don't fit in the remanining space of the upload link
    if (filesExcludedDueToUploadLinkConstraints.length > 0) {
      uploadableFilesList = uploadableFilesList.filter(
        (item: UploadableFile) =>
          !filesExcludedDueToUploadLinkConstraints.includes(item)
      );
      setSelectedFiles([...uploadableFilesList, ...selectedFiles]);
      setOpenConfirmationDialogPayload({
        open: true,
        description: (
          <>
            <Typography>{t("file_size_limit_reached_introduction")}</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
              }}
            >
              {filesExcludedDueToUploadLinkConstraints.map((uploadableFile) => {
                return (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      marginLeft: "16px",
                    }}
                  >
                    {"• " +
                      uploadableFile.fileObj.name +
                      " " +
                      getSizeInHumeanReadableFormat(
                        uploadableFile.fileObj.size
                      )}
                  </Typography>
                );
              })}
            </Box>
            <Typography
              sx={{
                marginTop: "8px",
              }}
            >
              {t("file_size_limit_reached_recommendation", {
                total_files_size: getSizeInHumeanReadableFormat(totalfilesSize),
              })}
            </Typography>
          </>
        ),
      });
      return;
    }

    //We check if the number of uploaded files doesn't exceed the maximum allowed number of uploads for this upload link
    if (
      filesList.length + uploadLink.uploadsCount >
      uploadLink.maxUploadsCount
    ) {
      const remainingUploads =
        uploadLink.maxUploadsCount - uploadLink.uploadsCount;
      uploadableFilesList = uploadableFilesList.slice(0, remainingUploads);
      setSelectedFiles([...uploadableFilesList, ...selectedFiles]);
      setOpenConfirmationDialogPayload({
        open: true,
        description: (
          <>
            <Typography>
              {t("uploads_count_limit_reached_introduction")}
            </Typography>
          </>
        ),
      });
      return;
    }

    //if (retainedFiles.length + uploadLink.uploadsCount)
    setSelectedFiles([...uploadableFilesList, ...selectedFiles]);
  }

  /**
   * Will add the files sequentially
   * @param files
   * @returns
   */
  const addFiles = async (
    files: UploadableFile[],
    information: UploadItemInformationFields
  ) => {
    if (!files) {
      return;
    }
    setUploadOngoing(true);
    var i = 0;
    for (const uploadableFile of files) {
      i++;
      if (uploadableFile.isUploaded) {
        if (DEBUG) {
          console.log("Skipping file %o", uploadableFile.fileObj);
        }
        continue;
      }

      const fileObj = uploadableFile.fileObj;
      if (DEBUG) {
        console.log(
          "Adding   new file " +
            JSON.stringify(fileObj.type) +
            " size " +
            JSON.stringify(fileObj.size)
        );
      }
      setFileBeingUploaded(uploadableFile);
      var uploadInput = {
        ...information,
        file: fileObj,
      };

      await uploadFile({
        variables: {
          input: uploadInput,
          token: params.id,
          expectedFilesCount: files.length,
          currentFileIndex: i,
        },
      }).then((result) => {
        if (result.data.uploadFile.result) {
          uploadableFile.isUploaded = true;
          // setUploadedFilesList([fileObj, ...uploadedFilesList]);
        }
      });
    }
    setUploadOngoing(false);
    setUploadCompleted(true);
    //const filesCount = files.length;
  };

  /* const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number }
  ) => {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          {...props}
          sx={{
            animation: `${rotate} 1s linear infinite`,
            width: "96px",
            height: "96px",
            // color: "white"
          }}
          size="96px"
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }; */

  const handleRemoveFile = React.useCallback(
    (fileToRemove: UploadableFile) => {
      const newArray = selectedFiles.filter(
        (file: UploadableFile) => file !== fileToRemove
      );
      setSelectedFiles(newArray);
    },
    [selectedFiles]
  );
  /* 
  const getFileItems = React.useCallback(() => {
    //val items = <></>
    const itemsArray = [];
    const filesCount = selectedFiles.length;

     for (let i = 0; i < filesCount; i++) {
      const fileObj = selectedFiles[i];
      console.log("File obj is %o", fileObj);
      itemsArray.push();
    }
    return itemsArray; 
  }, [selectedFiles, fileBeingUploaded, progress, handleRemoveFile]); */

  const handleUploadFiles = useCallback(() => {
    const result = validateFields.current.validateAndGetValues();
    console.log("Got value from validation %o", result);
    if (result.validationOk) {
      addFiles(selectedFiles, result.fields);
    }
  }, [selectedFiles, validateFields.current]);

  const handleCancelUploadFiles = useCallback(() => {
    abort.current();
    setFileBeingUploaded(null);
    setUploadOngoing(false);
  }, [abort, setFileBeingUploaded, setUploadOngoing]);

  const getAcceptedFiles = useCallback(() => {
    const uploadLink: UploadLink | undefined =
      retrievePublicUploadLinkResult.data?.uploadLink;
    if (uploadLink) {
      // console.log("getAcceptedFiles uploadLink  %o ", uploadLink);

      const allowedFiles = uploadLink.allowedFiles.split(",");
      if (allowedFiles.includes("all")) {
        return "*/*";
      }
      var acceptedFilesValueForInput = "";
      allowedFiles.map((fileType, index) => {
        var fileTypeSupported = false;
        if (fileType.toLowerCase() == "image") {
          fileTypeSupported = true;
          acceptedFilesValueForInput += "image/*";
        } else if (fileType.toLowerCase() == "video") {
          fileTypeSupported = true;
          acceptedFilesValueForInput += "video/*";
        } else if (fileType.toLowerCase() == "pdf") {
          fileTypeSupported = true;
          acceptedFilesValueForInput += "application/pdf";
        }
        if (index < allowedFiles.length - 1 && fileTypeSupported) {
          acceptedFilesValueForInput += ",";
        }
      });
      return acceptedFilesValueForInput;
    }
  }, [retrievePublicUploadLinkResult.data]);

  if (uploadLinkValidityCheckOngoing) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",

          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          background: "#2c2c2c",
        }}
      >
        <CircularProgress size={"120px"} />
      </Box>
    );
  }

  if (uploadCompleted) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          alignItems: "center",
          background: "#2c2c2c",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Lottie
            style={{ height: 600, width: 600 }}
            animationData={animationData}
            loop={true}
            autoplay
          />

          <Typography
            variant="h4"
            sx={{
              color: "white",
              position: "absolute",
              bottom: theme.spacing(4),
            }}
          >
            {t("upload_complete_message")}
          </Typography>
        </Box>
      </Box>
    );
  }

  /*******************Display the link has expired ****************** */
  if (uploadLinkValidityCheckVerdict && !uploadLinkValidityCheckVerdict.valid) {
    if (
      uploadLinkValidityCheckVerdict.error ==
      UploadLinkInvalidErrors.UPLOAD_LINK_EXPIRED
    ) {
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "#2c2c2c",
          }}
        >
          <img
            src={VoidIllustration}
            style={{
              maxWidth: "500px",
              width: "auto",
              height: "auto",
              display: "block",
            }}
          />
          <Typography
            sx={{
              color: "white",
              marginTop: "16px",
            }}
          >
            {t("upload_link_expired_title")}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PageNotFound />
        </Box>
      );
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",

        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        background: "#2c2c2c",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          background: "#2c2c2c",
          //   overflow: "scroll",
          [theme.breakpoints.down("md")]: {
            //  overflow: "hidden",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",

            //height: selectedFiles.length > 0 ? "30%" : "100%",
          },
        }}
      >
        {/*    <Box
          sx={{
            width: "100%",
            height: "200px",
            background: "green",
          }}
        ></Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //  alignItems: "center",
            justifyContent: "center",
            //width: selectedFiles.length > 0 ? "50%" : "100%",
            width: "50%",
            height: "100%",
            py: theme.spacing(4),
            px: theme.spacing(12),
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.down("sm")]: {},
            [theme.breakpoints.down("md")]: {
              width: "80%",
              px: 0,
              //height: selectedFiles.length > 0 ? "20%" : "100%",
              //justifyContent: selectedFiles.length > 0 ? "top" :  "center",
              //   py: theme.spacing(0),
            } /* 
          [theme.breakpoints.down("lg")]: {
            width: "80%",
            px: theme.spacing(2),
          },
          [theme.breakpoints.down("xl")]: {
            width: "90%",
          }, */,
          }}
        >
          <PublicUploadLinkContainer
            ref={validateFields}
            uploadLink={retrievePublicUploadLinkResult.data?.uploadLink}
            loading={retrievePublicUploadLinkResult.loading}
            layoutSettings={
              retrievePublicUploadLinkResult.data?.uploadLink?.layoutSettings
            }
            onUploadFiles={() => {
              hiddenFileInput?.current?.click();
            }}
            hideUploadButton={selectedFiles.length > 0}
          />
        </Box>

        <Box
          sx={{
            width: selectedFiles.length > 0 ? "60%" : "0px",
            display: selectedFiles.length > 0 ? "flex" : "none",
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#383838",
            paddingTop: theme.spacing(8),
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.down("md")]: {
              width: "100%",
              flex: "1",
              // overflow: "hidden",
              paddingTop: theme.spacing(1),
              //height: selectedFiles.length > 0 ? "70%" : "0px",
            },
            //background: theme.palette.primary.main
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{
              overflowY: "auto",
              display: "flex",
              justifyContent: "center",
              px: theme.spacing(8),
              // justifyContent:"center"
              [theme.breakpoints.down("md")]: {
                px: theme.spacing(4),
                height: "100%",
              },
            }}
          >
            {selectedFiles.map((uploadableFile: UploadableFile) => {
              return (
                <LoadedFilePreviewItem
                  key={uploadableFile.fileObj.name}
                  fileBeingUploaded={fileBeingUploaded}
                  uploadableFile={uploadableFile}
                  onDelete={handleRemoveFile}
                  progress={progress}
                  isUploaded={uploadableFile.isUploaded}
                />
              );
            })}
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              width: "100%",
              py: theme.spacing(4),
              px: theme.spacing(4),
            }}
          >
            {selectedFiles.length > 0 && !uploadOngoing && (
              <Box>
                <Button
                  variant="text"
                  sx={{
                    color: "white",
                    px: theme.spacing(4),
                  }}
                  onClick={() => {
                    hiddenFileInput?.current?.click();
                  }}
                >
                  {t("upload_action")}
                </Button>
                <Button
                  sx={{
                    px: theme.spacing(4),
                    ml: theme.spacing(1),
                  }}
                  variant="contained"
                  onClick={handleUploadFiles}
                >
                  {t("submit_action")}
                </Button>
              </Box>
            )}
            {uploadOngoing && (
              <Button
                sx={{
                  px: theme.spacing(4),
                  py: theme.spacing(2),
                }}
                variant="contained"
                onClick={handleCancelUploadFiles}
              >
                {t("cancel_action")}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <input
        ref={hiddenFileInput}
        accept={getAcceptedFiles()}
        onChange={onFileInputChange}
        type="file"
        multiple
        style={{ display: "none" }}
      />
      <SimpleConfirmationDialog
        open={openConfirmationDialogPayload.open}
        title={t("hmm")}
        description={openConfirmationDialogPayload.description}
        handleModalClose={() => {
          setOpenConfirmationDialogPayload({
            open: false,
            description: null,
          });
        }}
        handleOk={() => {
          setOpenConfirmationDialogPayload({
            open: false,
            description: null,
          });
        }}
        hideCancel
      />
    </Box>
  );
};

interface LoadedFilePreviewItemProps {
  uploadableFile: UploadableFile;
  fileBeingUploaded: UploadableFile | null;
  onDelete: (file: UploadableFile) => void;
  progress?: number;
  isUploaded: boolean;
}

const shouldDisplayItemPreview = (fileObj: File): boolean => {
  return fileObj.type.startsWith("image/");
};

const LoadedFilePreviewItem = ({
  uploadableFile,
  fileBeingUploaded,
  onDelete,
  progress = 0,
  isUploaded,
}: LoadedFilePreviewItemProps) => {
  const theme = useTheme();
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [localProgress, setLocalProgress] = useState<number>(0);
  const [displayPreview, setDisplayPreview] = useState(
    shouldDisplayItemPreview(uploadableFile.fileObj)
  );

  const { t } = useTranslation();
  React.useEffect(() => {
    setLocalProgress(progress);
  }, [progress]);

  React.useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageURL(reader.result as string);
    };
    reader.readAsDataURL(uploadableFile.fileObj);
  }, []);

  // const fileUploaded = uploadedFilesList.find((file) => file == fileObj);

  return (
    <Grid
      key={uploadableFile.fileObj.name}
      sx={{
        width: "100%",
        alignItems: "center",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
          alignItems: "center",
        },
      }}
      item
      xs={6}
      sm={4}
      md={4}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
          borderRadius: "8px",
          background: "#2c2c2c",
          my: theme.spacing(1),
          width: "100%",

          /* border:
      clickedUploadItem && clickedUploadItem.id == item.id
        ? `2px solid ${theme.palette.primary.main}`
        : "none", */
        }}
      >
        {imageURL && displayPreview && (
          <img
            style={{
              objectFit: "cover",
            }}
            height="100px"
            width="100%"
            src={imageURL}
          />
        )}
        {!imageURL && displayPreview && (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height="100px"
          />
        )}
        {uploadableFile == fileBeingUploaded && !uploadableFile.isUploaded && (
          <LinearProgress
            sx={{
              width: "100%",
            }}
            defaultValue={0}
            value={localProgress * 100}
            variant="determinate"
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            px: theme.spacing(1),
            py: theme.spacing(1),
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: ".8rem",
              wordWrap: "break-word",
              maxWidth: "80%",

              display: "-webkit-box",
              textOverflow: "ellipsis",
              fontWeight: "bold",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              maxLines: 2,
            }}
          >
            {" "}
            {uploadableFile.fileObj.name}
          </Typography>

          {/*   <CheckCircleIcon
            sx={{
              color: "green", 
            }}
          /> */}
          {isUploaded ? (
            <CheckCircleIcon
              sx={{
                color: "green",
                marginLeft: theme.spacing(1),
              }}
            />
          ) : (
            <>
              {uploadableFile == fileBeingUploaded ? (
                <>
                  {localProgress == 1 ? (
                    <CircularProgress size={"24px"} variant="indeterminate" />
                  ) : (
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: ".8rem",
                      }}
                    >
                      {`${Math.round(localProgress * 100)}%`}
                    </Typography>
                  )}
                </>
              ) : (
                <Tooltip title={t("delete_action")}>
                  <IconButton
                    onClick={() => {
                      onDelete(uploadableFile);
                    }}
                  >
                    <DeleteOutlineIcon
                      sx={{
                        color: "white",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </Box>
      </Card>
    </Grid>
  );
};

export default React.memo(PublicUpload);
