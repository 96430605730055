import { gql } from "@apollo/client";
import { UPLOAD_LINKS_ATTRIBUTES } from "./queries";

export const connectCloudToEmailGql = gql`
  mutation($integrationId: String) {
    connectCloudToEmail(integrationId: $integrationId) {
      success
      emailIntegration {
        id
        uploadsCount
        integrations {
          storageProvider
          destinationFolder{
            url
            name
          }
        }
      }
    }
  }
`;

export const deleteEmailUploadItemGql = gql`
  mutation($id: ID) {
    deleteEmailUploadItem(id: $id) {
      success
    }
  }
`;
export const deleteUploadItemGql = gql`
  mutation($id: ID) {
    deleteUploadItem(id: $id) {
      success
    }
  }
`;

export const updateUserInfoGql = gql`
  mutation($userInfo: UserInfoInput!) {
    updateUserInfo(userInfo: $userInfo) {
      result
    }
  }
`;

export const updateUsernameGql = gql`
  mutation($newUsername: String) {
    updateUsername(newUsername: $newUsername) {
      result
    }
  }
`;

export const updateUserAvatarGql = gql`
  mutation($avatarFile: Upload!) {
    updateUserAvatar(avatarFile: $avatarFile) {
      result
    }
  }
`;

export const updateUploadLinkLayoutSettingsGql = gql`
  mutation($input: UploadLinkLayoutSettingsInput!, $uploadLinkId: ID!) {
    updateUploadLinkLayoutSettings(input: $input, uploadLinkId: $uploadLinkId) {
      success
      uploadLinkLayoutSettings {
        showUserInfo
        description
        enableUserNameField
        enableUserEmailField
        enableUserMessageField
        messageTitle
        owner {
          firstName
          lastName
        }
      }
    }
  }
`;

export const updateUploadLinkGql = gql`
  mutation($input: UploadLinkInput!) {
    updateUploadLink(input: $input) {
      success
      uploadLink {
        ${UPLOAD_LINKS_ATTRIBUTES}
      }
    }
  }
`;

export const createUploadLinkGql = gql`
  mutation($integrationId: String, $name: String, $keepCopyInInternalStorage: Boolean) {
    createUploadLink(integrationId: $integrationId, name: $name, keepCopyInInternalStorage: $keepCopyInInternalStorage) {
      success
      error
      uploadLink {
        ${UPLOAD_LINKS_ATTRIBUTES}
      }
    }
  }
`;

export const uploadFileGql = gql`
  mutation(
    $input: UploadInput
    $token: String
    $expectedFilesCount: Int
    $currentFileIndex: Int
  ) {
    uploadFile(input: $input, token: $token, expectedFilesCount: $expectedFilesCount, currentFileIndex: $currentFileIndex) {
      result
    }
  }
`;
