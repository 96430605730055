import Button from "@mui/material/Button";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import UpgradeIllustration from "res/illustrations/upgrade_plan_illustration.svg";
import { PRICING } from "navigation/Constants";
import { useNavigate } from "react-router-dom";

interface MaxDataUsageLimitReachedDialogProps {
  open: boolean;
  description?: string | null;
  handleModalClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    addButton: {
      marginEnd: "12px",
      width: "40%",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "24px",
    },
  })
);

export default function UpgradePlanDialog({
  open,
  description,
  handleModalClose,
}: MaxDataUsageLimitReachedDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();
  const handleUpgradeButtonClicked = React.useCallback(() => {
    navigate(PRICING);
    handleModalClose();
  }, [handleModalClose]);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose()}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography
              variant="h6"
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {t("upgrade_plan_dialog_title")}
            </Typography>
            <img
              style={{
                maxHeight: "40vh",
                width: "100%",
                marginTop: theme.spacing(2),
              }}
              src={UpgradeIllustration}
            />
            <Typography
              variant="subtitle2"
              sx={{ textAlign: "center", marginTop: "12px", color: "gray" }}
            >
              {description ? description : t("upgrade_plan_dialog_description")}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.addButton}
                color="primary"
                variant="outlined"
                onClick={handleUpgradeButtonClicked}
              >
                <Typography sx={{ fontWeight: "400" }} variant="h6">
                  {t("upgrade_plan_button_title")}
                </Typography>
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
