import { Box, Tab, Tabs, useTheme } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ConnectNewStorageContainer from "pages/storages/ConnectNewStorageContainer";
import ConnectedStoragesContainer from "pages/storages/ConnectedStoragesContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CONNECTED_STORAGES_FULL_PATH,
  CONNECT_NEW_STORAGES_FULL_PATH,
  STORAGES_FULL_PATH,
} from "navigation/Constants";

const TAB_CONNECTED_STORAGES = 1;
const TAB_CONNECT_NEW_STORAGE = 2;

interface IntegrationsContainerProps {
  children: React.ReactNode;
}

const IntegrationsContainer = ({ children }: IntegrationsContainerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [tabValue, setTabValue] = React.useState(TAB_CONNECTED_STORAGES);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fullPath = location.pathname;
    console.log("rendering for  location  " + fullPath);
    console.log("location is " +  fullPath);
    if (fullPath.endsWith(CONNECTED_STORAGES_FULL_PATH)) {
      setTabValue(TAB_CONNECTED_STORAGES);
    } else if (fullPath.endsWith(CONNECT_NEW_STORAGES_FULL_PATH)) {
      setTabValue(TAB_CONNECT_NEW_STORAGE);
    } else if (fullPath.endsWith(STORAGES_FULL_PATH)) {
      //We navigate here to the default view
      navigate(CONNECT_NEW_STORAGES_FULL_PATH);
    }
    return () => {
      console.log("cleanup of integration container");
    };
  }, [location.pathname]);

  const handleTabValueChanged = useCallback(
    (tabValue: number) => {
      if (tabValue == TAB_CONNECTED_STORAGES) {
        navigate(CONNECTED_STORAGES_FULL_PATH);
      } else if (tabValue == TAB_CONNECT_NEW_STORAGE) {
        navigate(CONNECT_NEW_STORAGES_FULL_PATH);
      } else {
        navigate(CONNECT_NEW_STORAGES_FULL_PATH);
      }
    },
    [location]
  );

  React.useEffect(() => {
    console.log("Will set tabvalue " + tabValue);
  }, [tabValue]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        px: theme.spacing(8),
        flexDirection: "column",
        overflowY: "hidden",
        [theme.breakpoints.down("lg")]: {
          px: theme.spacing(0),
        },
      }}
    >
      <Tabs
        value={tabValue}
        onChange={(event: React.SyntheticEvent, newValue: any) => {
          handleTabValueChanged(newValue);
        }}
      >
        <Tab
          value={TAB_CONNECT_NEW_STORAGE}
          label={t("connect_storage_title")}
        />

        <Tab
          value={TAB_CONNECTED_STORAGES}
          label={t("connected_storages_title")}
        />
      </Tabs>
      {children}
      {/* {tabValue == TAB_CONNECT_NEW_STORAGE && <ConnectNewStorageContainer />}
      {tabValue == TAB_CONNECTED_STORAGES && <ConnectedStoragesContainer />} */}
    </Box>
  );
};

export default IntegrationsContainer;
