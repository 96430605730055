import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  isUserLoggedIn,
  setUserIsloggedIn,
  setUserIsloggedOut,
} from "localstorage/localstorage";
import { SubscriptionInformation, UserObj } from "models/user";

export interface UserAuthState {
  user: UserObj | null;
  userSubscriptionInfo: SubscriptionInformation | null;
  isUserLoggedIn: boolean;
}

interface AuthState {
  user: UserObj | null;
  userSubscriptionInfo: SubscriptionInformation | null;
  isUserLoggedIn: boolean;
}

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    user: null,
    userSubscriptionInfo: null,
    isUserLoggedIn: isUserLoggedIn(),
  } as AuthState,
  reducers: {
    loginUser: (state, action: PayloadAction<UserObj>) => {
      state.user = action.payload;
      state.isUserLoggedIn = true;
      setUserIsloggedIn();
    },
    logoutUser: (state) => {
      state.user = null;
      state.isUserLoggedIn = false;
      setUserIsloggedOut();
    },
    setUserSubscriptionInformation: (
      state,
      action: PayloadAction<SubscriptionInformation | null>
    ) => {
      state.userSubscriptionInfo = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserSubscriptionInformation, loginUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
