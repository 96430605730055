import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface CopyButtonToolTippedProps {
  onClick: () => void;
  sx?: object;
}

const CopyButtonToolTipped = ({ sx, onClick }: CopyButtonToolTippedProps) => {
  const { t } = useTranslation();
  const [buttonCliked, setButtonClicked] = useState(false);
  return (
    <Box sx={{ ...sx }}>
      <Tooltip
        title={buttonCliked ? t("link_copied_msg") : t("copy_link_action")}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setButtonClicked(true);
            onClick();
          }}
        >
          <ContentCopyIcon
            sx={{
              width: "16px",
              height: "16px",
            }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default React.memo(CopyButtonToolTipped);
