import {
  Box,
  Card,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  StorageProviderType,
  UploadLink,
  UploadLinkStatus,
} from "models/uploadly";
import React, { useRef, useState } from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import {
  StyledButton,
  StyledInputElement,
  StyledInputRoot,
} from "shared/styles";
import TextPicker from "components/ui/TextPicker";
import MultiSelectField from "components/ui/MultiSelectField";
import { LoadingButton } from "@mui/lab";
import dayjs, { Dayjs, extend } from "dayjs";
import { useTranslation } from "react-i18next";
import { upload } from "@testing-library/user-event/dist/upload";
import {
  ONE_GB,
  ONE_MB,
  appendDeltaAndFormatDate,
  currentTimestamp,
  isDifferenceSmallerThanDelta,
  iso8601dateToTimestamp,
} from "shared/dataUtils";
import { updateUploadLinkGql } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { showSnackbar, showUpgradePlanDialog } from "store/view";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserMaxAllowedSizePerUploadLink,
  getUserMaxAllowedUploadCountsPerLink,
  getUserMaxUploadLinkExpiryDuration,
} from "models/user";
import { RootState } from "store";
interface UploadLinkSettingsContainerProps {
  uploadLink: UploadLink;
}

const UploadLinkSettingsContainer = ({
  uploadLink,
}: UploadLinkSettingsContainerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateUploadLink, updateUploadLinkResult] = useMutation(
    updateUploadLinkGql
  );
  const [
    linkExpirationDate,
    setLinkExpirationDate,
  ] = React.useState<Dayjs | null>(
    dayjs(
      uploadLink.expiryDate ? uploadLink.expiryDate : currentTimestamp() * 1000
    )
  );

  const [newUploadLinkName, setNewUploadLinkName] = useState<string | null>(
    null
  );
  const [uploadsMaxCountInput, setuploadsMaxCountInput] = React.useState<
    number | null
  >(null);
  const [fileTypesAllowedInput, setFileTypesAllowedInput] = React.useState<
    string | null
  >(null);

  const [enableDisableState, setEnableDisableState] = useState<boolean | null>(
    null
  );

  const user = useSelector((state: RootState) => state.auth.user);
  const [
    maxTotalUploadsSizeInput,
    setMaxTotalUploadsSizeInput,
  ] = React.useState<number | null>(null);

  const shouldEnableSave = React.useCallback(() => {
    return (
      Boolean(newUploadLinkName) ||
      Boolean(uploadsMaxCountInput) ||
      Boolean(fileTypesAllowedInput) ||
      Boolean(maxTotalUploadsSizeInput) ||
      enableDisableState != null ||
      iso8601dateToTimestamp(uploadLink.expiryDate) !=
        linkExpirationDate?.unix()
    );
  }, [
    linkExpirationDate,
    uploadLink,
    newUploadLinkName,
    uploadsMaxCountInput,
    fileTypesAllowedInput,
    maxTotalUploadsSizeInput,
    enableDisableState,
  ]);

  const handleSaveUpdate = React.useCallback(() => {
    updateUploadLink({
      variables: {
        input: {
          id: uploadLink.id,
          name:
            newUploadLinkName && newUploadLinkName != uploadLink.name
              ? newUploadLinkName
              : undefined,
          expiryDate:
            iso8601dateToTimestamp(uploadLink.expiryDate) !=
            linkExpirationDate?.unix()
              ? linkExpirationDate?.unix()
              : undefined,
          allowedFiles: fileTypesAllowedInput
            ? fileTypesAllowedInput
            : undefined,
          maxUploadsCount: uploadsMaxCountInput
            ? uploadsMaxCountInput
            : undefined,
          maxTotalUploadsSize: maxTotalUploadsSizeInput
            ? maxTotalUploadsSizeInput
            : undefined,
          status:
            enableDisableState != null
              ? enableDisableState
                ? UploadLinkStatus.DISABLED
                : UploadLinkStatus.ACTIVE
              : undefined,
        },
      },
    })
      .then((result) => {
        dispatch(
          showSnackbar({
            message: t("update_successful_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        if (result.data.updateUploadLink.success) {
          resetUpdatedFields();
        }
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("update_failed_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
      });
  }, [
    maxTotalUploadsSizeInput,
    uploadsMaxCountInput,
    newUploadLinkName,
    linkExpirationDate,
    fileTypesAllowedInput,
    enableDisableState,
  ]);

  const resetUpdatedFields = React.useCallback(() => {
    setFileTypesAllowedInput(null);
    setMaxTotalUploadsSizeInput(null);
    setFileTypesAllowedInput(null);
    setNewUploadLinkName(null);
    setEnableDisableState(null);
  }, [setFileTypesAllowedInput]);

  return (
    <Box
      sx={{
        py: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        width: "100%",
        height: "100%",
      }}
    >
      <Card
        sx={{
          paddingTop: theme.spacing(4),

          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            px: "5%",
          }}
        >
          <UploadLinkNameSetting
            key={"name-setting"}
            uploadLink={uploadLink}
            onNameChanged={(newName: string) => {
              setNewUploadLinkName(newName);
            }}
          />

          <UploadLinkEnabledDisableSetting
            key={"enable-disable-setting"}
            uploadLink={uploadLink}
            onStateChanged={(checked: boolean) => {
              setEnableDisableState(checked);
            }}
          />
          <UploadLinkMaxNumberOfUploads
            key={"max-uploads-setting"}
            uploadLink={uploadLink}
            onCountChanged={(uploadsCount: number) => {
              // console.log("update of counts");
              setuploadsMaxCountInput(uploadsCount);
            }}
          />
          <UploadLinkAllowedFilesSetting
            key={"allowed-files-setting"}
            uploadLink={uploadLink}
            onAllowedFilesChanged={(value: string) => {
              // console.log("Got a file type changed" + value);
              setFileTypesAllowedInput(value);
            }}
          />
          <UploadLinkTotalMaxSizeSetting
            key={"total-max-size-setting"}
            uploadLink={uploadLink}
            onMaxTotalSizeChanged={(maxSize: number) => {
              //console.log("max size changed " + maxSize);
              setMaxTotalUploadsSizeInput(maxSize);
            }}
          />
          <Box
            key={"expiry-date-setting"}
            sx={{
              paddingTop: theme.spacing(3),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "30%",
              }}
            >
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
                variant="subtitle2"
              >
                {t("upload_link_expiry_date")}
              </Typography>
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  color: "gray",
                }}
                variant="caption"
              >
                {t("upload_link_expiry_date_description")}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "60%",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label={null}
                    disablePast
                    value={linkExpirationDate}
                    onChange={(newValue) => {
                      if (!newValue) {
                        return;
                      }
                      console.log("got a new value " + newValue);
                      const maxAllowedExpiryDuration = getUserMaxUploadLinkExpiryDuration(
                        user
                      );
                      const creationDate = new Date(uploadLink.createdAt);
                      const selectedDate = newValue?.toDate();
                      if (
                        !isDifferenceSmallerThanDelta(
                          creationDate,
                          selectedDate,
                          maxAllowedExpiryDuration
                        )
                      ) {
                        dispatch(
                          showUpgradePlanDialog({
                            show: true,
                            message: t("upgrade_plan_link_expiry_message", {
                              date: appendDeltaAndFormatDate(
                                uploadLink.createdAt,
                                maxAllowedExpiryDuration
                              ),
                            }),
                          })
                        );
                        return;
                      }
                      setLinkExpirationDate(newValue);
                    }}
                    /*  onSelectedSectionsChange={(newValue) => {
                      console.log("got a new value  onSelectedSectionsChange" + newValue);
                    }} */
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            py: theme.spacing(1),
            px: theme.spacing(2),
          }}
        >
          <LoadingButton
            disabled={!shouldEnableSave()}
            loading={updateUploadLinkResult.loading}
            onClick={handleSaveUpdate}
          >
            {t("save_action")}
          </LoadingButton>
        </Box>
      </Card>
    </Box>
  );
};

interface UploadLinkTotalMaxSizeSettingProps
  extends UploadLinkSettingsContainerProps {
  onMaxTotalSizeChanged: (maxSize: number) => void;
}

const maxSizeOptions: any = {};

maxSizeOptions["100mo"] = { label: "100 Mo", value: ONE_MB * 100 };
maxSizeOptions["500mo"] = { label: "500 Mo", value: ONE_MB * 500 };
maxSizeOptions["1go"] = { label: "1 Go", value: ONE_GB };
maxSizeOptions["2go"] = { label: "2 Go", value: ONE_GB * 2 };
maxSizeOptions["5go"] = { label: "5 Go", value: ONE_GB * 5 };
maxSizeOptions["unlimited"] = {
  label: "Unlimited",
  value: Number.MAX_SAFE_INTEGER,
};

const supportedMaxSizes = ["100mo", "500mo", "1go", "2go", "5go", "unlimited"];

const UploadLinkTotalMaxSizeSetting = ({
  uploadLink,
  onMaxTotalSizeChanged,
}: UploadLinkTotalMaxSizeSettingProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const getMaxUploadsSizeForTextPicker = React.useCallback(
    (maxSize: number) => {
      //const maxTotalUploadsSize = uploadLink.maxTotalUploadsSize;
      //const maxTotalUploadsSize = ONE_GB * 5;
      switch (maxSize) {
        case ONE_MB * 100:
          return maxSizeOptions["100mo"];
        case ONE_MB * 500:
          return maxSizeOptions["500mo"];
        case ONE_GB:
          return maxSizeOptions["1go"];
        case ONE_GB * 2:
          return maxSizeOptions["2go"];
        case ONE_GB * 5:
          return maxSizeOptions["5go"];
        default:
          return maxSizeOptions["unlimited"];
      }
    },
    [uploadLink]
  );

  const [selectedMaxSize, setSelectedMaxSize] = React.useState(
    getMaxUploadsSizeForTextPicker(uploadLink.maxTotalUploadsSize)
  );
  return (
    <Box
      sx={{
        paddingTop: theme.spacing(3),

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "30%",
        }}
      >
        <Typography
          sx={{
            boxSizing: "border-box",
            wordWrap: "break-word",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
          variant="subtitle2"
        >
          {t("upload_link_max_total_size_setting_title")}
        </Typography>
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            color: "gray",
          }}
          variant="caption"
        >
          {t("upload_link_max_total_size_setting_description")}
        </Typography>
      </Box>

      <TextPicker
        sx={{
          width: "60%",
        }}
        defaultValue={selectedMaxSize}
        value={selectedMaxSize}
        options={supportedMaxSizes.map((element) => maxSizeOptions[element])}
        onChange={(maxSize: number) => {
          console.log("picked value " + maxSize);
          const maxAllowedSizeForUser = getUserMaxAllowedSizePerUploadLink(
            user
          );
          if (maxSize > maxAllowedSizeForUser) {
            dispatch(
              showUpgradePlanDialog({
                show: true,
                message: null,
              })
            );
            return;
          }
          setSelectedMaxSize(getMaxUploadsSizeForTextPicker(maxSize));
          onMaxTotalSizeChanged(maxSize);
        }}
      />
    </Box>
  );
};

interface UploadLinkAllowedFilesSettingProps
  extends UploadLinkSettingsContainerProps {
  onAllowedFilesChanged: (allowedFiles: string) => void;
}

const allowedFilesObject: any = {};

allowedFilesObject["all"] = { label: "All", value: "all" };
allowedFilesObject["image"] = { label: "Image", value: "image" };
allowedFilesObject["video"] = { label: "Video", value: "video" };
allowedFilesObject["pdf"] = { label: "PDF", value: "pdf" };

const supportedFileTypes = ["all", "image", "video", "pdf"];
const supportedFileTypesForGooglePhotos = ["all", "image", "video"];

const UploadLinkAllowedFilesSetting = ({
  uploadLink,
  onAllowedFilesChanged,
}: UploadLinkAllowedFilesSettingProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const lastSelectedValue = React.useRef<any>();
  const lastNotifiedValue = React.useRef<any>();

  const getAllowedFilesValueForMultiSelectField = React.useCallback(() => {
    const allowedFilesValues = uploadLink.allowedFiles;
    //const allowedFilesValues = "video,image,pdf";
    if (!allowedFilesValues) {
      return [allowedFilesObject["all"]];
    }
    const supportedFileTypes = allowedFilesValues.split(",");
    return supportedFileTypes.map((value) => allowedFilesObject[value]);
  }, [uploadLink]);

  const [allowedFilesValue, setAllowedFilesValue] = React.useState(
    getAllowedFilesValueForMultiSelectField()
  );

  React.useEffect(() => {
    //console.log("allowedFilesValue %o", allowedFilesValue);
    var result = "";
    allowedFilesValue.map((fileType, index) => {
      result = result + (index > 0 ? "," : "") + fileType.value;
    });
    if (!lastNotifiedValue.current) {
      //We skip the initial render with this
      lastNotifiedValue.current = result;
      return;
    }
    if (result != lastNotifiedValue.current) {
      onAllowedFilesChanged(result);
    }
  }, [allowedFilesValue]);

  return (
    <Box
      sx={{
        paddingTop: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "30%",
        }}
      >
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
          variant="subtitle2"
        >
          {t("upload_link_allowed_files_setting_title")}
        </Typography>
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            color: "gray",
          }}
          variant="caption"
        >
          {t("upload_link_allowed_files_setting_description")}
        </Typography>
      </Box>

      <MultiSelectField
        value={allowedFilesValue}
        onChange={(e, value) => {
          const newValueContainsAll = Boolean(
            value.find((element: any) => element.value == "all")
          );
          const lastValueContainsAll = Boolean(
            (lastSelectedValue.current
              ? lastSelectedValue.current
              : allowedFilesValue
            ).find((element: any) => element.value == "all")
          );

          if (
            (newValueContainsAll && !lastValueContainsAll) ||
            value.length == 0
          ) {
            const newValue = [allowedFilesObject["all"]];

            setAllowedFilesValue(newValue);
            lastSelectedValue.current = newValue;
            return;
          } else {
            const newValue = value.filter(
              (element: any) => element != allowedFilesObject["all"]
            );

            setAllowedFilesValue(newValue);
            lastSelectedValue.current = newValue;
          }
        }}
        sx={{
          width: "60%",
        }}
        options={(uploadLink.integrations &&
        uploadLink.integrations.length > 0 &&
        uploadLink.integrations[0].storageProvider ==
          StorageProviderType.GOOGLE_PHOTOS
          ? supportedFileTypesForGooglePhotos
          : supportedFileTypes
        ).map((value) => {
          return allowedFilesObject[value];
        })}
      />
    </Box>
  );
};

interface UploadLinkMaxNumberOfUploadsProps
  extends UploadLinkSettingsContainerProps {
  onCountChanged: (count: number) => void;
}

const CustomNumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInputElement,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: "▴",
        },
        decrementButton: {
          children: "▾",
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

const UploadLinkMaxNumberOfUploads = ({
  uploadLink,
  onCountChanged,
}: UploadLinkMaxNumberOfUploadsProps) => {
  const theme = useTheme();
  const [uploadsMaxCountInput, setuploadsMaxCountInput] = React.useState<
    number | null
  >(uploadLink.maxUploadsCount);
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        paddingTop: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "30%",
        }}
      >
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
          variant="subtitle2"
        >
          {t("upload_link_max_number_uploads_setting_title")}
        </Typography>
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            color: "gray",
          }}
          variant="caption"
        >
          {t("upload_link_max_number_uploads_setting_description")}
        </Typography>
      </Box>
      <CustomNumberInput
        aria-label="Demo number input"
        defaultValue={uploadLink.maxUploadsCount}
        value={uploadsMaxCountInput}
        step={100}
        onChange={(e, value) => {
          //  console.log("onchange " + value);
          if (value) {
            const maxAllowedValueForUser = getUserMaxAllowedUploadCountsPerLink(
              user
            );
            if (value > maxAllowedValueForUser) {
              dispatch(
                showUpgradePlanDialog({
                  show: true,
                  message: null,
                })
              );
              return;
            }
            setuploadsMaxCountInput(value);
            onCountChanged(value);
          }
        }}
        max={Number.MAX_SAFE_INTEGER}
        min={1}
      />
    </Box>
  );
};

interface UploadLinkEnabledDisableSettingProps
  extends UploadLinkSettingsContainerProps {
  onStateChanged: (enabled: boolean) => void;
}

const UploadLinkEnabledDisableSetting = ({
  uploadLink,
  onStateChanged,
}: UploadLinkEnabledDisableSettingProps) => {
  const theme = useTheme();
  const [nameInput, setNameInput] = React.useState(uploadLink.name);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        paddingTop: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
          variant="subtitle2"
        >
          {t("upload_link_disabled_setting_title")}
        </Typography>
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            color: "gray",
          }}
          variant="caption"
        >
          {t("upload_link_disabled_setting_description")}
        </Typography>
      </Box>

      <Switch
        defaultChecked={Boolean(uploadLink.status)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onStateChanged(event.target.checked);
        }}
      />
    </Box>
  );
};

interface UploadLinkNameSettingProps extends UploadLinkSettingsContainerProps {
  onNameChanged: (newName: string) => void;
}

const UploadLinkNameSetting = ({
  uploadLink,
  onNameChanged,
}: UploadLinkNameSettingProps) => {
  const theme = useTheme();
  const [nameInput, setNameInput] = React.useState(uploadLink.name);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
          variant="subtitle2"
        >
          {t("upload_link_name_setting_title")}
        </Typography>
        <Typography
          sx={{
            flex: "1",
            boxSizing: "border-box",
            wordWrap: "break-word",
            color: "gray",
          }}
          variant="caption"
        ></Typography>
      </Box>

      <TextField
        defaultValue={uploadLink.name}
        value={nameInput}
        onChange={(e) => {
          setNameInput(e.target.value);
          onNameChanged(e.target.value);
        }}
        sx={{
          width: "60%",
        }}
      />
    </Box>
  );
};

export default React.memo(UploadLinkSettingsContainer);
