import React, { useState } from "react";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import { UserObj } from "models/user";
import { makeStyles } from "@mui/styles";
import Popper from "@mui/material/Popper";
import { useTranslation } from "react-i18next";
import FloatingUserMenu from "./FloatingUserMenu";
import { RootState } from "store";
import { getUserAvatarForId } from "configuration";
//import { getUserAvatarForEmail } from "configuration";

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 5,
  },
}));

const CurrentUserAvatarMenu = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showToolTip, setShowTooltip] = React.useState(false);
  const handleClick = (event: any) => {
    setShowTooltip(false);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const userObj = useSelector((state: RootState) => state.auth.user);
  const avatarFilter = showToolTip ? "brightness(.9)" : "";
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const userInitials = userObj ?
    userObj?.firstName.charAt(0) + userObj?.lastName.charAt(0) : ".."
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <Tooltip
        open={showToolTip}
        arrow
        title={t("header_avatar_tooltip")}
      >
        <Avatar
          onClick={handleClick}
          onMouseEnter={() => setShowTooltip(!open && true)}
          onMouseLeave={() => setShowTooltip(false)}
          color="primary"
          src={getUserAvatarForId(userObj?.id)}
          sx={{
            transform: showToolTip ? "scale(1.3)" : "scale(1)",
            transformOrigin: "center",
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
          style={{
            marginRight: "5%",
            border: "thick double #fff",
            fontSize: "1rem",
            width: "45px",
            height: "45px",
            cursor: "pointer",
            filter: avatarFilter,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          }}
        >
          {userInitials ? userInitials : ".."}
        </Avatar>
      </Tooltip>
      {userObj && (
        <Popper
          id={id}
          open={open}
          placement={"bottom-end"}
          anchorEl={anchorEl}
          className={classes.popper}
        >
          <FloatingUserMenu closeMenu={() => setAnchorEl(null)} />
        </Popper>
      )}
    </>
  );
};

export default React.memo(CurrentUserAvatarMenu);
