import {
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Fade,
  Grid,
  Link,
  ListItemButton,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  SubscriptionPlanIds,
  UserObj,
  getUserMaxStorageUsage,
} from "models/user";
import { RootState } from "store";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import { LoadingButton } from "@mui/lab";
import { getIconForStorageProvider } from "pages/storages/storageProviderIds";
import {
  bytesToGigabytes,
  formatIso8601date,
  generateRandomCombination,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import {
  DEBUG,
  MAX_UPLOAD_LINKS_FREE_PLAN,
  MAX_UPLOAD_LINKS_STANDARD_PLAN,
} from "configuration";
import { retrieveConnectedStoragesPagedGql } from "graphql/queries";
import DestinationFolderBadge from "components/ui/DestinationFolderBadge";
import { createUploadLinkGql } from "graphql/mutations";
import {
  addUploadLinkToCacheIfExists,
  uploadLastNUploadLinksItemsCache,
} from "graphql/gqlcachehelper";
import { ConnectedStorage, StorageProviderType } from "models/uploadly";
import { showSnackbar, showUpgradePlanDialog } from "store/view";

const MAX_UPLOAD_LINKS_COUNT_REACHED = 1;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      position: "relative",
      width: "50%",
      marginTop: "24px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      height: "90%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      [theme.breakpoints.down("md")]: {
        width: "90%",
        px: 0,
      },
    },
    inputLabel: {
      fontSize: "1.9rem",
      padding: "16px",
      overflow: "hidden",
      "& .MuiInput-root": {
        height: "70%",
      },
      "& .MuiInput-input .MuiInputLabel-root": {
        fontSize: "large",
      },
      "&:hover .MuiInput-input .MuiInputBase-input": {
        outline: "none",
      },
      "&:focus .MuiInput-input": {
        outline: "none",
      },
    },
    annotationTextField: {
      fontSize: "1.9rem",
    },

    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      padding: "24px",
    },
    applyButton: {
      marginEnd: "12px",
      marginStart: "12px",
      alignItems: "center",
      textTransform: "capitalize",
    },
  })
);

interface CreateUploadLinkModalProps {
  open: boolean;
  handleModalClose: () => void;
}
const PAGE_SIZE = 15;

const UPLOADLY_STORAGE_ID = "uploadly-storage-id";
const UPLOADLY_STORAGE_ITEM: ConnectedStorage = {
  id: UPLOADLY_STORAGE_ID,
  accountEmail: "",
  createdAt: "",
  storageProvider: StorageProviderType.UPLOADLY_STORAGE,
  destinationFolder: {
    name: "",
    url: "",
  },
};

const CreateUploadLinkModal = ({
  open,
  handleModalClose,
}: CreateUploadLinkModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const fetchMoreOngoing = React.useRef(false);
  const switchValueRef = React.useRef<any>();
  const [createUploadLink, createUploadLinkResult] = useMutation(
    createUploadLinkGql
  );
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const computeStorageUsagePercentage = useCallback(() => {
    if (!user) {
      return 0;
    }
    const maxStorage = getUserMaxStorageUsage(user);
    const percentage = Math.round(
      (user?.uploadlyStorageUsedSpace / maxStorage) * 100
    );
    return percentage > 100 ? 100 : percentage;
  }, [user]);

  const usedStoragePercentage = useMemo(() => computeStorageUsagePercentage(), [
    user,
  ]);
  const [allItemsRetrieved, setAllItemsRetrieved] = React.useState(false);
  const [itemsListEmpty, setItemsListEmpty] = React.useState(false);
  const [nameInputValue, setNameInputValue] = React.useState(
    generateRandomCombination()
  );
  const [selectedStorageId, setselectedStorageId] = React.useState<
    string | null
  >();
  const lastFetchedPage = React.useRef(1);

  const storagesQueryResult = useQuery(retrieveConnectedStoragesPagedGql, {
    variables: {
      page: 1,
      pageSize: PAGE_SIZE,
    },
  });

  React.useEffect(() => {
    if (
      storagesQueryResult.data &&
      !storagesQueryResult.loading &&
      !storagesQueryResult.error
    ) {
      setAllItemsRetrieved(
        storagesQueryResult.data.allConnectedStoragesPaged.length % PAGE_SIZE !=
          0 || storagesQueryResult.data.allConnectedStoragesPaged.length == 0
      );
      setItemsListEmpty(
        storagesQueryResult.data.allConnectedStoragesPaged.length == 0
      );
    }
  }, [storagesQueryResult]);

  const fetchMoreItems = React.useCallback(() => {
    if (fetchMoreOngoing.current) {
      if (DEBUG) {
        console.log("fetchMoreItems already ongoing");
      }
      return;
    }
    fetchMoreOngoing.current = true;

    if (allItemsRetrieved) {
      if (DEBUG) {
        console.log("Already retrieved everything");
      }
      fetchMoreOngoing.current = false;
      return;
    }
    const nextPageToFetch = lastFetchedPage.current + 1;

    if (DEBUG) {
      console.log("Fetch more data for page " + nextPageToFetch);
    }
    storagesQueryResult
      .fetchMore({
        variables: {
          page: nextPageToFetch,
          pageSize: PAGE_SIZE,
        },
      })
      .then((result) => {
        if (DEBUG) {
          console.log("Fetch more result %o", result);
        }
        if (
          result.data &&
          result.data.allConnectedStoragesPaged &&
          result.data.allConnectedStoragesPaged.length == 0
        ) {
          setAllItemsRetrieved(true);
        }
        lastFetchedPage.current = nextPageToFetch;
        fetchMoreOngoing.current = false;
      })
      .catch((e) => {
        //setFetchOngoing(false)
        fetchMoreOngoing.current = false;
      });
    //}
  }, [
    storagesQueryResult,
    storagesQueryResult.data,
    allItemsRetrieved,
    fetchMoreOngoing.current,
  ]);

  const handleOnScroll = React.useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        fetchMoreItems();
      }
    },
    [fetchMoreItems]
  );

  const handleCreateLinkClicked = React.useCallback(() => {
    createUploadLink({
      variables: {
        integrationId:
          selectedStorageId != UPLOADLY_STORAGE_ID ? selectedStorageId : null,
        name: nameInputValue,
        keepCopyInInternalStorage: switchValueRef.current?.checked,
      },
    })
      .then((result) => {
        if (result.data.createUploadLink.success) {
          addUploadLinkToCacheIfExists(
            createUploadLinkResult.client.cache,
            result.data.createUploadLink.uploadLink
          );
          uploadLastNUploadLinksItemsCache(
            createUploadLinkResult.client.cache,
            result.data.createUploadLink.uploadLink
          );
          dispatch(
            showSnackbar({
              message: t("upload_link_created_confirmation"),
              loading: false,
            })
          );
          handleModalClose();
        } else {
          if (
            result.data.createUploadLink.error == MAX_UPLOAD_LINKS_COUNT_REACHED
          ) {
            dispatch(
              showUpgradePlanDialog({
                show: true,
                message:
                  user?.subscriptionPlan.id == SubscriptionPlanIds.FREE
                    ? t("upgrade_plan_max_upload_link_count_singular_reached", {
                        upload_links_count: MAX_UPLOAD_LINKS_FREE_PLAN,
                      })
                    : t("upgrade_plan_max_upload_link_count_plural_reached", {
                        upload_links_count: MAX_UPLOAD_LINKS_STANDARD_PLAN,
                      }),
              })
            );
            handleModalClose();
          }
        }
      })
      .catch((e) => {
        console.log("Got the error ", e);
      });
  }, [
    nameInputValue,
    selectedStorageId,
    handleModalClose,
    switchValueRef.current,
  ]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => handleModalClose()}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          className={classes.paper}
          sx={{
            px: theme.spacing(2),
            justifyContent: "space-between",
            overflowY: "hidden",

            /* 
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              px: 0,
            }, */
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
              px: theme.spacing(2),
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                marginTop: theme.spacing(3),
                marginLeft: theme.spacing(2),
                marginBottom: theme.spacing(1),
                overflowY: "hidden",
                color: theme.palette.primary.main,
              }}
            >
              {t("pick_name_for_upload_link_title")}
            </Typography>

            <TextField
              placeholder=""
              defaultValue={nameInputValue}
              value={nameInputValue}
              onChange={(e) => {
                setNameInputValue(e.target.value);
              }}
              error={nameInputValue.length == 0}
              helperText={
                nameInputValue.length == 0 ? t("name_must_not_be_empy") : null
              }
              sx={{
                colorl: "white",
                width: "80%",
                marginBottom: theme.spacing(1),
                px: theme.spacing(2),
              }}
              inputProps={{
                color: "white",
              }}
            />
            <Box
              sx={{
                marginTop: theme.spacing(2),
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                display:
                  selectedStorageId && selectedStorageId == UPLOADLY_STORAGE_ID
                    ? "none"
                    : "flex",
                transition: theme.transitions.create("all", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginLeft: theme.spacing(2),
                    overflowY: "hidden",

                    color: theme.palette.primary.main,
                  }}
                >
                  {t("create_uploadly_link_keep_in_storage_title")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    marginLeft: theme.spacing(2),
                    overflowY: "hidden",
                    color: "gray",
                  }}
                >
                  {t("create_uploadly_link_keep_in_storage_description")}
                </Typography>
              </Box>

              <Switch defaultChecked={true} inputRef={switchValueRef} />
            </Box>
            <Typography
              variant="subtitle1"
              sx={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(1),
                marginLeft: theme.spacing(2),
                overflowY: "hidden",

                color: theme.palette.primary.main,
              }}
            >
              {t("create_link_modal_title")}
            </Typography>

            <Box
              onScroll={handleOnScroll}
              sx={{
                flex: "1",
                width: "100%",
                "&::-webkit-scrollbar": {
                  width: 3,
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: `inset 0 0 6px #faf6e9`,
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "4px",
                },
                //  background: "yellow",
                overflowY: "auto",
                paddingTop: theme.spacing(1),
                px: theme.spacing(2),
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  overflowY: "auto",
                }}
              >
                {storagesQueryResult &&
                  storagesQueryResult.data &&
                  storagesQueryResult.data.allConnectedStoragesPaged &&
                  [
                    UPLOADLY_STORAGE_ITEM,
                    ...storagesQueryResult.data.allConnectedStoragesPaged,
                  ].map((item: ConnectedStorage, index: number) => {
                    return (
                      <Grid item key={item.id} xs={12} sm={6} md={4}>
                        <Card
                          sx={{
                            height: "100%",
                            boxShadow:
                              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                            borderRadius: "8px",
                            border:
                              selectedStorageId == item.id
                                ? `2px solid ${theme.palette.primary.main}`
                                : "none",
                          }}
                          onClick={() => {
                            setselectedStorageId(item.id);
                          }}
                        >
                          <CardActionArea
                            sx={{
                              height: "100%",
                              //padding: "24px",
                              py: theme.spacing(1),
                              px: theme.spacing(2),
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "start",
                              background: "aliceblue",
                              width: "100%",
                            }}
                            onClick={() => {
                              // handleIntegrationClicked(item.id);
                            }}
                          >
                            <Box
                              sx={{
                                boxSizing: "border-box",
                                display: "flex",
                                alignItems: "start",
                              }}
                            >
                              {getIconForStorageProvider(item.storageProvider)}
                            </Box>
                            <Typography
                              sx={{
                                wordWrap: "break-word",
                                textOverflow: "ellipsis",
                                fontWeight: "bold",
                                py: theme.spacing(1),
                              }}
                              variant="caption"
                            >
                              {item.id == UPLOADLY_STORAGE_ID
                                ? t("my_uploadly_storage_title")
                                : item.accountEmail}
                            </Typography>
                            {item.id == UPLOADLY_STORAGE_ID ? (
                              <Box
                                sx={{
                                  height: "34px",
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "end",
                                }}
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "default",
                                    mx: theme.spacing(1),
                                  }}
                                >
                                  <CircularProgress
                                    variant="determinate"
                                    value={100}
                                    sx={{
                                      position: "absolute",
                                      color: "#a1caff", // Color of the remaining progress track
                                    }}
                                    size={"32px"}
                                  />
                                  <CircularProgress
                                    variant="determinate"
                                    size={"32px"}
                                    value={usedStoragePercentage}
                                    sx={{
                                      position: "absolute",
                                      color:
                                        usedStoragePercentage >= 100
                                          ? "red"
                                          : usedStoragePercentage >= 80
                                          ? "orange"
                                          : theme.palette.primary.main,
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      position: "absolute",
                                      color: "gray",
                                      fontSize: ".6rem",
                                    }}
                                    variant="subtitle2"
                                    component="div"
                                    color="text.secondary"
                                  >
                                    {usedStoragePercentage}%
                                  </Typography>
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  flex: "1",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "end",
                                }}
                              >
                                <DestinationFolderBadge
                                  destinationFolder={item.destinationFolder}
                                  storageProviderId={item.storageProvider}
                                />
                              </Box>
                            )}
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
          <div className={classes.buttonContainer}>
            <Button
              sx={{
                marginRight: theme.spacing(2),
              }}
              onClick={handleModalClose}
            >
              {t("cancel_action")}
            </Button>
            <LoadingButton
              className={classes.applyButton}
              color="primary"
              variant="contained"
              loading={createUploadLinkResult.loading}
              disabled={!selectedStorageId || nameInputValue.length == 0}
              onClick={handleCreateLinkClicked}
              sx={{
                textTransform: "uppercase",
              }}
            >
              {t("create_link_action")}
            </LoadingButton>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default React.memo(CreateUploadLinkModal);
