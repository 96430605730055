import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import thunk from "redux-thunk";
import rootReducer from "./store";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import axios from "axios";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_LOGIN_KEY } from "configuration";
import { ThemeProvider } from "styled-components";
import Cookies from "js-cookie";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

/* axios.defaults.headers.common['X-CSRF-TOKEN'] =  Cookies.get('csrftoken');
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken"; */
export const msalConfig = {
  auth: {
      clientId: 'c2ddcbdf-be8b-46a2-99c3-3f1e37659930', // Replace with your client ID
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: 'http://localhost:3000', // Replace with your redirect URI
  },
  cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to true if you are having issues on IE11 or Edge
  }
};

export const loginRequest = {
  scopes: ["User.Read", "Files.ReadWrite"]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

/* axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken"; */
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_LOGIN_KEY}>
  {/*   <React.StrictMode> */}
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
