import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  Skeleton,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import PublicUploadLinkContainer from "components/ui/publicupload/PublicUploadLinkContainer";
import { updateUploadLinkLayoutSettingsGql } from "graphql/mutations";
import { retrieveUploadLinkLayoutSettingsGql } from "graphql/queries";
import { UploadLink, UploadLinkLayoutSettings } from "models/uploadly";
import { UserObj, getUserInitials } from "models/user";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import { showSnackbar } from "store/view";
import { RootState } from "store";
import { updateUploadLinkLayoutSettingsCache } from "graphql/gqlcachehelper";

interface PublicUploadLinkPreviewProps {
  uploadLink: UploadLink;
}

const defaultLayoutSettingsValue = (translation: any) => {
  return {
    showUserInfo: true,
    enableUserNameField: true,
    enableUserEmailField: true,
    enableUserMessageField: true,
    messageTitle: "",
    description: translation("upload_link_layout_setting_description_default"),
  };
};
const PublicUploadLinkPreview = ({
  uploadLink,
}: PublicUploadLinkPreviewProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [initialLayoutSettingsValue, setInitialLayoutSettingsValue] = useState(
    defaultLayoutSettingsValue(t)
  );
  const [layoutSettings, setLayoutSettings] = useState(
    defaultLayoutSettingsValue(t)
  );
  const uploadLinkLayoutSettingsResult = useQuery(
    retrieveUploadLinkLayoutSettingsGql,
    {
      variables: {
        uploadLinkId: uploadLink.id,
      },
    }
  );
  const [
    updateUploadLinkLayoutSettings,
    updateUploadLinkLayoutSettingsResult,
  ] = useMutation(updateUploadLinkLayoutSettingsGql);

  React.useEffect(() => {
    if (
      uploadLinkLayoutSettingsResult.data &&
      uploadLinkLayoutSettingsResult.data.uploadLinkLayoutSettings
    ) {
      setLayoutSettings(
        uploadLinkLayoutSettingsResult.data.uploadLinkLayoutSettings
      );
      setInitialLayoutSettingsValue(
        uploadLinkLayoutSettingsResult.data.uploadLinkLayoutSettings
      );
    }
  }, [uploadLinkLayoutSettingsResult.data]);

  const user = useSelector((state: RootState) => state.auth.user);

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const handleDisplayUserInformationCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setLayoutSettings({ ...layoutSettings, showUserInfo: isChecked });
      setEnableSaveButton(true);
    },
    [layoutSettings]
  );

  const handleAskForUserNameCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setLayoutSettings({ ...layoutSettings, enableUserNameField: isChecked });
      setEnableSaveButton(true);
    },
    [layoutSettings]
  );

  const handleAskForUserEmailCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setLayoutSettings({
        ...layoutSettings,
        enableUserEmailField: isChecked,
      });
      setEnableSaveButton(true);
    },
    [layoutSettings]
  );

  const handleAskForUserMessageCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setLayoutSettings({
        ...layoutSettings,
        enableUserMessageField: isChecked,
      });
      setEnableSaveButton(true);
    },
    [layoutSettings]
  );

  const handleSaveLayoutSettings = useCallback(() => {
    const layoutSettingsCurrentValue = initialLayoutSettingsValue;
    console.log("Current layout settings are %o", layoutSettingsCurrentValue);
    console.log("New layout settings are %o", layoutSettings);
    const layoutSettingsInputValue = {
      showUserInfo:
        layoutSettings.showUserInfo != layoutSettingsCurrentValue.showUserInfo
          ? layoutSettings.showUserInfo
          : undefined,
      description:
        layoutSettings.description != layoutSettingsCurrentValue.description
          ? layoutSettings.description
          : undefined,
      enableUserNameField:
        layoutSettings.enableUserNameField !=
        layoutSettingsCurrentValue.enableUserNameField
          ? layoutSettings.enableUserNameField
          : undefined,
      enableUserEmailField:
        layoutSettings.enableUserEmailField !=
        layoutSettingsCurrentValue.enableUserEmailField
          ? layoutSettings.enableUserEmailField
          : undefined,
      enableUserMessageField:
        layoutSettings.enableUserMessageField !=
        layoutSettingsCurrentValue.enableUserMessageField
          ? layoutSettings.enableUserMessageField
          : undefined,
      messageTitle:
        layoutSettings.messageTitle != layoutSettingsCurrentValue.messageTitle
          ? layoutSettings.messageTitle
          : undefined,
    };

    updateUploadLinkLayoutSettings({
      variables: {
        input: layoutSettingsInputValue,
        uploadLinkId: uploadLink.id,
      },
    })
      .then((data: any) => {
        // if (data.result)
        console.log("change result is %o", data);
        dispatch(
          showSnackbar({
            message: t("update_successful_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        setInitialLayoutSettingsValue(
          data.data.updateUploadLinkLayoutSettings.uploadLinkLayoutSettings
        );
        updateUploadLinkLayoutSettingsCache(
          uploadLinkLayoutSettingsResult.client.cache,
          uploadLink.id,
          data.data.updateUploadLinkLayoutSettings.uploadLinkLayoutSettings
        );
      })
      .catch((e) => {
        dispatch(
          showSnackbar({
            message: t("update_failed_msg"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        setLayoutSettings(layoutSettingsCurrentValue);
      });
  }, [layoutSettings, initialLayoutSettingsValue]);

  return (
    <Box
      sx={{
        py: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          height: "auto",
        },
      }}
    >
      <Card
        sx={{
          paddingTop: theme.spacing(4),
          marginRight: "4px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "50%",
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            px: "5%",
          }}
        >
          {/*  -------------------------------- DISPLAY USER PROFILE  --------------------------------*/}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
                variant="subtitle2"
              >
                {t("upload_link_layout_setting_show_profile_title")}
              </Typography>
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  color: "gray",
                }}
                variant="caption"
              >
                {t("upload_link_layout_setting_show_profile_description")}
              </Typography>
            </Box>

            <Switch
              defaultChecked
              checked={layoutSettings.showUserInfo}
              onChange={handleDisplayUserInformationCheck}
            />
          </Box>

          {/*  -------------------------------- END DISPLAY USER PROFILE  --------------------------------*/}
          {/*  -------------------------------- USER DESCRIPTION --------------------------------*/}
          <Box
            sx={{
              marginTop: theme.spacing(3),
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                flex: "1",
                boxSizing: "border-box",
                wordWrap: "break-word",
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
              variant="subtitle2"
            >
              {t("description_title")}
            </Typography>
            <TextField
              placeholder={t(
                "upload_link_layout_setting_description_placeholder"
              )}
              value={layoutSettings.description}
              onChange={(event: any) => {
                //  setDescriptionInputValue(event.target.value);
                setLayoutSettings({
                  ...layoutSettings,
                  description: event.target.value,
                });
                setEnableSaveButton(true);
              }}
              fullWidth
              defaultValue={layoutSettings.description}
              sx={{
                marginTop: theme.spacing(1),
                colorl: "white",
              }}
            />
          </Box>
          {/*  -------------------------------- END USER DESCRIPTION --------------------------------*/}

          {/*  -------------------------------- ASK FOR USER NAME  --------------------------------*/}
          <Box
            sx={{
              marginTop: theme.spacing(3),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
                variant="subtitle2"
              >
                {t("upload_link_layout_setting_ask_for_user_name_title")}
              </Typography>
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  color: "gray",
                }}
                variant="caption"
              >
                {t("upload_link_layout_setting_ask_for_user_name_description")}
              </Typography>
            </Box>

            <Switch
              defaultChecked
              checked={layoutSettings.enableUserNameField}
              onChange={handleAskForUserNameCheck}
            />
          </Box>

          {/*  -------------------------------- END ASK FOR USER NAME  --------------------------------*/}

          {/*  -------------------------------- ASK FOR USER EMAIL  --------------------------------*/}
          <Box
            sx={{
              marginTop: theme.spacing(3),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
                variant="subtitle2"
              >
                {t("upload_link_layout_setting_ask_for_user_email_title")}
              </Typography>
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  color: "gray",
                }}
                variant="caption"
              >
                {t("upload_link_layout_setting_ask_for_user_email_description")}
              </Typography>
            </Box>

            <Switch
              defaultChecked
              checked={layoutSettings.enableUserEmailField}
              onChange={handleAskForUserEmailCheck}
            />
          </Box>

          {/*  -------------------------------- END ASK USER EMAIL  --------------------------------*/}

          {/*  -------------------------------- ASK FOR USER MESSAGE  --------------------------------*/}
          <Box
            sx={{
              marginTop: theme.spacing(3),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
                variant="subtitle2"
              >
                {t("upload_link_layout_setting_ask_for_user_message_title")}
              </Typography>
              <Typography
                sx={{
                  flex: "1",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                  color: "gray",
                }}
                variant="caption"
              >
                {t(
                  "upload_link_layout_setting_ask_for_user_message_description"
                )}
              </Typography>
            </Box>

            <Switch
              defaultChecked
              checked={layoutSettings.enableUserMessageField}
              onChange={handleAskForUserMessageCheck}
            />
          </Box>

          {layoutSettings.enableUserMessageField && (
            <Box
              sx={{
                marginTop: theme.spacing(2),
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextField
                placeholder={t(
                  "upload_link_layout_setting_ask_for_user_message_placeholder"
                )}
                type="text"
                value={layoutSettings.messageTitle}
                onChange={(event: any) => {
                  setLayoutSettings({
                    ...layoutSettings,
                    messageTitle: event.target.value,
                  });
                  setEnableSaveButton(true);
                }}
                fullWidth
                defaultValue={layoutSettings.messageTitle}
                sx={{
                  marginTop: theme.spacing(1),
                }}
              />
            </Box>
          )}
        </Box>

        {/*  -------------------------------- END ASK USER MESSAGE  --------------------------------*/}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            px: theme.spacing(2),
            mb: theme.spacing(2),
            mr: theme.spacing(2),
          }}
        >
          <LoadingButton
            disabled={!enableSaveButton}
            loading={updateUploadLinkLayoutSettingsResult.loading}
            onClick={handleSaveLayoutSettings}
          >
            {t("save_action")}
          </LoadingButton>
        </Box>
      </Card>
      <Card
        sx={{
          py: theme.spacing(8),
          px: theme.spacing(4),

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          width: "50%",
          background: "#2c2c2c",
          [theme.breakpoints.down("md")]: {
            width: "100%",
            mt: theme.spacing(2),

          },
        }}
      >
        <PublicUploadLinkContainer
          layoutSettings={layoutSettings}
          uploadLink={uploadLink}
          loading={uploadLinkLayoutSettingsResult.loading}
          readOnly
        />
      </Card>
    </Box>
  );
};

export default React.memo(PublicUploadLinkPreview);
