import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import EdgesensorHighRoundedIcon from "@mui/icons-material/EdgesensorHighRounded";
import ViewQuiltRoundedIcon from "@mui/icons-material/ViewQuiltRounded";
import ControlUploadlyLinkPreview from "res/gifs/control_uploadly_link.gif";

import GoogleDriveIcon from "res/icons/google-drive-icon.png";
import DropBoxIcon from "res/icons/dropbox-logo.svg";
import OneDriveIcon from "res/icons/one-drive-logo.svg";
import S3Icon from "res/icons/s3-icon.svg";
import AzureBlobStorageIcon from "res/icons/azure-storage-icon.svg";

import ControlUploadlyLinkPreviewMP4 from "res/gifs/control_uploadly_link.mp4";
import TrackUploadlyLinkPreviewMP4 from "res/gifs/track_uploadly_link.mp4";
import PickUploadlyLocation from "res/gifs/pick_uploadly_location.mp4";
import EmailForwardingMP4 from "res/gifs/email_forwarding.mp4";
import { Divider, Tooltip, alpha, useTheme } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import MonitorIcon from "@mui/icons-material/Monitor";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import { useTranslation } from "react-i18next";
import FTPIcon from "res/icons/ftp-icon.svg";
import GooglePhotosIcon from "res/icons/google-photos.svg";
import LoopingVideo from "components/ui/LoopingVideo";
import { EMAIL_FORWARDING_FULL_PATH } from "navigation/Constants";
import { UPLOAD_ATTACHEMENTS_EMAIL } from "configuration";

const FEATURE_TRACK_ID = 1;
const FEATURE_CONTROL_ID = 2;
const FEATURE_PICK_LOCATION_ID = 3;

const getItems = (translation: any) => {
  const items = [
    {
      icon: <MonitorIcon />,
      title: translation("landing_features_overview_title"),
      id: FEATURE_TRACK_ID,
      description: translation("landing_features_overview_description"),
    },
    {
      icon: <SettingsIcon />,
      title: translation("landing_features_control_title"),
      id: FEATURE_CONTROL_ID,
      description: translation("landing_features_control_description"),
    },
    {
      icon: <SnippetFolderIcon />,
      title: translation("landing_features_pick_location_title"),
      id: FEATURE_PICK_LOCATION_ID,
      description: translation("landing_features_pick_location_description"),
    },
  ];
  return items;
};

const supportedStorages = [
  {
    icon: <img width="48px" height="48px" src={GooglePhotosIcon} />,
    name: "Google Photos",
  },
  {
    icon: <img width="48px" height="48px" src={GoogleDriveIcon} />,
    name: "Google Drive",
  },
  {
    icon: <img width="48px" height="48px" src={DropBoxIcon} />,
    name: "Dropbox",
  },
  {
    icon: <img width="48px" height="48px" src={OneDriveIcon} />,
    name: "OneDrive",
  },
  {
    icon: <img width="48px" height="48px" src={FTPIcon} />,
    name: "FTP storage",
  },
  {
    icon: <img width="48px" height="48px" src={S3Icon} />,
    name: "Amazon S3",
    comingSoon: true,
  },
  {
    icon: <img width="48px" height="48px" src={AzureBlobStorageIcon} />,
    name: "Azure Blob Storage",
    comingSoon: true,
  },
];

export default function Features() {
  const [selectedItemId, setSelectedItemId] = React.useState(FEATURE_TRACK_ID);
  const theme = useTheme();
  const { t } = useTranslation();
  const handleItemClick = (index: number) => {
    setSelectedItemId(index);
  };

  const getSelectedItemVideoRessource = React.useCallback(() => {
    if (selectedItemId == FEATURE_TRACK_ID) {
      return TrackUploadlyLinkPreviewMP4;
    } else if (selectedItemId == FEATURE_CONTROL_ID) {
      return ControlUploadlyLinkPreviewMP4;
    } else if (selectedItemId == FEATURE_PICK_LOCATION_ID) {
      return PickUploadlyLocation;
    }
    return TrackUploadlyLinkPreviewMP4;
  }, [selectedItemId]);

  return (
    <Container id="features" sx={{ py: { xs: 4, sm: 8 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography
              component="h2"
              variant="h4"
              sx={{
                color: "#0b3558",
              }}
            >
              {t("landing_features_title")}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                mb: { xs: 2, sm: 4 },
                marginTop: theme.spacing(4),
                [theme.breakpoints.down("sm")]: {
                  marginTop: theme.spacing(2),
                },
              }}
            >
              {t("landing_features_description")}
            </Typography>
          </div>

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: { sm: "flex" } }}
          >
            {getItems(t).map(({ icon, title, description, id }, index) => (
              <Card
                key={title}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(id)}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                  background: "none",
                  backgroundColor:
                    selectedItemId === id ? "action.selected" : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemId === id
                        ? "primary.light"
                        : "grey.200";
                    }
                    return selectedItemId === id ? "primary.dark" : "grey.800";
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === "light") {
                          return selectedItemId === id
                            ? "primary.main"
                            : "grey.300";
                        }
                        return selectedItemId === id
                          ? "primary.main"
                          : "grey.700";
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: "none" }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { sm: "flex" }, width: "100%" }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              display: { sm: "flex" },
              pointerEvents: "none",
              boxShadow: `0 0 12px 8px ${alpha(
                theme.palette.primary.main,
                0.4
              )}`,
            }}
          >
            <video
              autoPlay
              loop
              muted
              style={{
                width: "100%",
                height: "auto",
                backgroundImage:
                  theme.palette.mode === "light"
                    ? 'url("/static/images/templates/templates-images/hero-light.png")'
                    : 'url("/static/images/templates/templates-images/hero-dark.png")',
                backgroundSize: "cover",
                borderRadius: "10px",
                outline: "1px solid",
                outlineColor:
                  theme.palette.mode === "light"
                    ? alpha("#BFCCD9", 0.5)
                    : alpha("#9CCCFC", 0.1),
                boxShadow:
                  theme.palette.mode === "light"
                    ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                    : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
              }}
              src={getSelectedItemVideoRessource()}
            >
              <source src={getSelectedItemVideoRessource()} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Card>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: theme.spacing(16),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "30%",
            marginRight: theme.spacing(8),
          }}
        >
          <Typography
            component="h2"
            variant="h4"
            sx={{
              color: "#0b3558",
            }}
          >
            {t("landing_email_uploads_feature_title")}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              mb: { xs: 2, sm: 4 },
              marginTop: theme.spacing(2),
              [theme.breakpoints.down("sm")]: {
                marginTop: theme.spacing(2),
              },
            }}
          >
            {t("landing_email_uploads_feature_description")}
            <span
              style={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => {
                window.open("mailto:" + UPLOAD_ATTACHEMENTS_EMAIL);
              }}
            >
              {UPLOAD_ATTACHEMENTS_EMAIL}
            </span>
          </Typography>
        </Box>
        <Card
          variant="outlined"
          sx={{
            height: "100%",
            width: "70%",
            display: { sm: "flex" },
            pointerEvents: "none",
            boxShadow: `0 0 12px 8px ${alpha(theme.palette.primary.main, 0.4)}`,
          }}
        >
          {/*  <video
            autoPlay
            loop
            muted
            style={{
              width: "100%",
              height: "auto",
              backgroundImage:
                theme.palette.mode === "light"
                  ? 'url("/static/images/templates/templates-images/hero-light.png")'
                  : 'url("/static/images/templates/templates-images/hero-dark.png")',
              backgroundSize: "cover",
              borderRadius: "10px",
              outline: "1px solid",
              outlineColor:
                theme.palette.mode === "light"
                  ? alpha("#BFCCD9", 0.5)
                  : alpha("#9CCCFC", 0.1),
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                  : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
            }}
            src={EmailForwardingMP4}
          >
            <source src={EmailForwardingMP4} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}

          <LoopingVideo videoRessource={EmailForwardingMP4} />
        </Card>
      </Box>

      <Box>
        <Typography
          component="h2"
          variant="h3"
          sx={{
            color: "#0b3558",
            width: "100%",
            alignSelf: "center",
            textAlign: "center",
            mt: { xs: 8, sm: 16 },
          }}
        >
          {t("supported_storages_title")}
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            mt: { xs: 2, sm: 4 },
          }}
        >
          {/*  <Grid
            container
            sx={{
              width: "30%",
            }}
          >
            
          </Grid> */}
          <Grid container spacing={3} sx={{ width: "auto" }}>
            {supportedStorages.map((storage, index) => {
              return (
                <Grid
                  item
                  key={storage.name}
                  xs="auto"
                  sm={6}
                  md={4}
                  sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                    width: "100px",
                    height: "120px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip key={storage.name} title={storage.name}>
                    <Card
                      key={storage.name}
                      sx={{
                        width: "100%",
                        height: "120px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: `${alpha(
                          theme.palette.primary.main,
                          0.4
                        )} 0px 1px 4px`,
                        transition: theme.transitions.create("all", {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.leavingScreen,
                        }),
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      {storage.icon}
                      {storage.comingSoon && (
                        <Chip
                          label={t("coming_soon")}
                          size="small"
                          sx={{
                            display: "flex",
                            position: "absolute",
                            right: 6,
                            top: 6,
                            background: theme.palette.primary.main,
                            /* background: (theme) =>
                        theme.palette.mode === "light" ? "" : "none", */
                            backgroundColor: "#6f77e3",
                            py: 0,
                            fontSize: ".5rem",
                            "& .MuiChip-root": {
                              height: "8px",
                            },
                            "& .MuiChip-label": {
                              color: "white",
                              py: 0,
                            },
                            "& .MuiChip-icon": {
                              color: "white",
                              py: 0,
                            },
                          }}
                        />
                      )}
                    </Card>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
