import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CONNECTED_STORAGES_FULL_PATH,
  UPLOADLY_LINKS_FULL_PATH,
} from "navigation/Constants";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { retrieveConnectedStorageForIdGql } from "graphql/queries";
import { ConnectedStorage } from "models/uploadly";
import {
  getIconForStorageProvider,
  getStorageProviderName,
} from "./storageProviderIds";
import { formatIso8601date } from "shared/dataUtils";
import DestinationFolderBadge from "components/ui/DestinationFolderBadge";
import UploadlyLinksContainer, {
  UploadLinksQueryType,
} from "pages/mainboard/UploadlyLinksContainer";
import UploadedFilesContainer, {
  UploadItemsQueryType,
} from "pages/mainboard/UploadedFilesContainer";

const TAB_UPLOAD_LINKS = 0;
const TAB_UPLOAD_ITEMS = 1;

const ConnectedStorageDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [tabValue, setTabValue] = React.useState(TAB_UPLOAD_LINKS);
  const { t } = useTranslation();
  const params = useParams();

  const connectedStorageResult = useQuery(retrieveConnectedStorageForIdGql, {
    variables: {
      id: params.id,
    },
  });

  const connectedStorage: ConnectedStorage =
    connectedStorageResult.data && connectedStorageResult.data.connectedStorage
      ? connectedStorageResult.data.connectedStorage
      : null;

  if (connectedStorage) {
    return (
      <Box
        sx={{
          px: theme.spacing(8),
          [theme.breakpoints.down("lg")]: {
            px: theme.spacing(0),
          },         
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon
              sx={{
                height: "32px",
                width: "32px",
              }}
            />
          </IconButton>

          <Box
            sx={{
              marginLeft: theme.spacing(1),
            }}
          >
            {getIconForStorageProvider(connectedStorage.storageProvider)}
          </Box>
          <Typography
            variant="h3"
            sx={{
              marginLeft: theme.spacing(1),
            }}
          >
            {connectedStorage.accountEmail}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: "gray",
              marginLeft: theme.spacing(1),
            }}
          >
            {getStorageProviderName(connectedStorage.storageProvider)}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              marginLeft: theme.spacing(1),
              color: "gray",
            }}
          >
            •
          </Typography>
          <DestinationFolderBadge
            destinationFolder={connectedStorage.destinationFolder}
            storageProviderId={connectedStorage.storageProvider}
            sx={{
              marginLeft: theme.spacing(2),
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              marginLeft: theme.spacing(1),
              color: "gray",
            }}
          >
            •
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              marginLeft: theme.spacing(1),
              color: "gray",
            }}
          >
            {t("created_at", {
              date: formatIso8601date(connectedStorage.createdAt),
            })}
          </Typography>
        </Box>
        <Tabs
          value={tabValue}
          onChange={(event: React.SyntheticEvent, newValue: any) => {
            // console.log("Got a change here " + JSON.stringify(newValue));
            setTabValue(newValue);
          }}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
        >
          <Tab
            value={TAB_UPLOAD_LINKS}
            label={t("connected_storage_upload_links_title")}
          />

          <Tab
            value={TAB_UPLOAD_ITEMS}
            label={t("connected_storage_upload_items_title")}
          />
        </Tabs>

        {tabValue == TAB_UPLOAD_LINKS && params.id && (
          <UploadlyLinksContainer
            queryType={UploadLinksQueryType.FOR_CONNECTED_STORAGE}
            connectedStorageId={params.id}
          />
        )}
        {tabValue == TAB_UPLOAD_ITEMS && (
          <UploadedFilesContainer
            queryType={UploadItemsQueryType.FOR_CONNECTED_STORAGE}
            connectedStorageId={params.id}
          />
        )}
      </Box>
    );
  }
};

export default React.memo(ConnectedStorageDetails);
