import Button from "@mui/material/Button";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link as TextLink } from "@mui/material";
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "navigation/Constants";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNavigate } from "react-router-dom";

interface CreateAccountConfirmationDialogProps {
  open: boolean;
  userFirstName: string;
  userEmail: string;
  googleAccessToken: string;
  handleCreateNewAccount: (googleAccessToken: string) => void;
  handleModalClose: (close: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    addButton: {
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingTop: "24px",
    },
  })
);

export default function CreateAccountConfirmationDialog({
  open,
  userFirstName,
  userEmail,
  googleAccessToken,
  handleCreateNewAccount,
  handleModalClose,
}: CreateAccountConfirmationDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleModalClose(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              Hi {userFirstName}
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
              }}
              variant="subtitle2"
            >
              {t("create_account_dialog_description", {
                user_email: userEmail,
              })}
            </Typography>
            <Typography
              style={{
                marginBottom: "24px",
                marginTop: "16px",
              }}
              variant="caption"
              component="div"
              gutterBottom
            >
              {t("privacy_and_terms_acknowledgement_part1")}
              <TextLink
                style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                onClick={() => {
                  window.open(PRIVACY_POLICY, "_blank");
                }}
              >
                {" "}
                {t("privacy_policy")}
              </TextLink>{" "}
              {t("and")}
              <TextLink
                style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                onClick={() => {
                  window.open(TERMS_CONDITIONS, "_blank");
                }}
              >
                {" "}
                {t("terms_and_conditions")}
              </TextLink>
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.addButton}
                color="primary"
                onClick={() => handleModalClose(false)}
                sx={{ marginRight: "12px" }}
              >
                {t("cancel_action")}
              </Button>

              <Button
                className={classes.addButton}
                color="primary"
                variant="contained"
                onClick={() => {
                  handleCreateNewAccount(googleAccessToken);
                }}
              >
                <PersonOutlineOutlinedIcon
                  sx={{
                    color: "white",
                    height: "100%",
                    width: "24px",
                    paddingRight: "8px",
                  }}
                />

                {t("create_account_action")}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
