import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

/* const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];
 */

interface TextPickerOption {
  value: string;
  label: string;
}

interface TextPickerProps {
  options: TextPickerOption[];
  value: TextPickerOption;
  defaultValue: TextPickerOption;
  onChange: (maxSize: number) => void;
  sx?: object;
}

function TextPicker({
  options,
  value,
  defaultValue,
  onChange,
  sx,
}: TextPickerProps) {
  return (
    <Box
      component="form"
      sx={{
        width: "100%",
        "& .MuiTextField-root": { width: "100%" },
        ...sx,
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="outlined-select-currency-native"
        select
        label={null}
        sx={{
          width: "100%",
        }}
        defaultValue={defaultValue.value}
        value={value.value}
        SelectProps={{
          native: true,
        }}
        onChange={(e) => {
          console.log("textfield change " + e.target.value);
          onChange(parseInt(e.target.value));
        }}
      >
        {options.map((option: TextPickerOption) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Box>
  );
}

export default React.memo(TextPicker);
