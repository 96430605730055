import axios from "axios";
import { DEBUG, SERVER_URL } from "configuration";
import Cookies from "js-cookie";

const FTP_LOGIN_API_SUBPATH = "/integrations/ftp-login";
const FTP_CONNECT_API_SUBPATH = "/integrations/ftp-connect";

export interface FtpLoginResult {
  success: boolean;
}

export interface FtpConnectResult {
  integrationId: string;
}

export const ftpLogin = async (
  host: string,
  username: string,
  password: string
): Promise<FtpLoginResult> => {
  try {
    let res = await axios.post(
      SERVER_URL + FTP_LOGIN_API_SUBPATH,
      {
        ftp_host: host,
        ftp_username: username,
        ftp_password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    /*  if (DEBUG) {
      console.log(
        "ftpLogin: result" + JSON.stringify(res)
      );
    } */
    return {
      success: res.data.success,
    };
  } catch (e) {
    throw e;
  }
};

export const ftpConnect = async (
  host: string,
  username: string,
  password: string,
  path: string
): Promise<FtpConnectResult> => {
  try {
    let res = await axios.post(
      SERVER_URL + FTP_CONNECT_API_SUBPATH,
      {
        ftp_host: host,
        ftp_username: username,
        ftp_password: password,
        ftp_path: path,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    return {
      integrationId: res.data.id,
    };
  } catch (e) {
    throw e;
  }
};
