import { forwardRef } from 'react';
import { Link, Route } from 'react-router-dom';


interface RouterLinkProps{
    href: string;
}

const RouterLink = forwardRef(({ href, ...other } : RouterLinkProps, ref: any) => <Link ref={ref} to={href} {...other} />);

export default RouterLink;
