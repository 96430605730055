import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SelectOption {
  value: string;
  label: string;
}

interface MultiSelectFieldProps {
  options: SelectOption[];
  value: SelectOption[];
  onChange: (event: React.SyntheticEvent, value: any) => void;
  sx?: object;
}
export default function MultiSelectField({
  options,
  value,
  onChange,
  sx,
}: MultiSelectFieldProps) {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options}
      defaultValue={value}
      value={value}
      disableCloseOnSelect
      clearIcon={null}
      onChange={onChange}
      getOptionLabel={(option) => option.label}
      sx={{
        "& .MuiInputBase-root": { py: 0 },

        "& .MuiChip-root": { height: "auto" },
        ...sx,
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ "& .MuiInputBase-input": { padding: 0 } }}
          label={null}
          placeholder=""
        />
      )}
    />
  );
}
