import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Slide,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import {
  LogoutApiResult,
  LogoutStatusCodes,
  fetchUserData,
  logout,
} from "api/authApi";
import { authenticateUser } from "api/googleAuthApi";
import SideNav, { NAV } from "components/SideNav";
import { HEADER, SPACING } from "config/config-layout";
import { DEBUG, GOOGLE_LOGIN_KEY } from "configuration";
import { useResponsive } from "hooks/use-responsive";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, logoutUser } from "store/auth";
import { RootState } from "store";
import CloseIcon from "@mui/icons-material/Close";
import {
  setOpenedUploadItem,
  showSnackbar,
  showUpgradePlanDialog,
} from "store/view";
import CurrentUserAvatarMenu from "components/ui/menu/CurrentUserAvatar";
import UploadedItemDetailContainer from "./mainboard/UploadedItemDetailContainer";
import Header from "components/ui/Header";
import UpgradePlanDialog from "components/ui/dialogs/UpgradePlanDialog";

interface MainBoardProps {
  children: React.ReactNode;
}

function TransitionUp(props: any) {
  return <Slide {...props} direction="up" />;
}

const MainBoard = ({ children }: MainBoardProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const userLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const openedUploadItem = useSelector(
    (state: RootState) => state.view.openedUploadItem
  );
  const openUpgradePlanDialog = useSelector(
    (state: RootState) => state.view.upgradePlanDialogState
  );
  const [openMenu, setOpenMenu] = useState(false);

  const [externalPopup, setExternalPopup] = React.useState<any>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const snackBar = useSelector((state: RootState) => state.view.snackBarState);
  console.log("Snackbar is  %o", snackBar);
  const handleCreateIntegration = React.useCallback(() => {
    const clientId = GOOGLE_LOGIN_KEY;
    const redirectUri = "http://localhost:3000";
    const scope = "https://www.googleapis.com/auth/drive";

    const authUrl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline`;

    var popupWidth = 500;
    var popupHeight = 600;
    var left = window.screenX + (window.outerWidth - popupWidth) / 2;
    var top = window.screenY + (window.outerHeight - popupHeight) / 2.5;
    const title = `Auth`;
    const popup = window.open(
      authUrl,
      title,
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  }, [setExternalPopup]);

  //We monitor on the external popup that we got a response code
  React.useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("code");
        if (code) {
          externalPopup.close();
          if (DEBUG) {
            console.log(`The popup URL has URL code param = ${code}`);
          }
          console.log("Got the code " + code);
          setExternalPopup(null);
          clearInterval(timer);
          authenticateUser(code).then((result) => {});
          //handleAuthenticateUser(code);
          // YourApi.endpoint(code).then(() => {
          //   // change UI to show after the code was stored
          // })
          //   .catch(() => {
          //     // API error
          //   })
          //   .finally(() => {
          //     // clear timer at the end
          //     setExternalPopup(null);
          //     timer && clearInterval(timer);
          //   })
        }
      } catch (e) {
        console.log("Caught an exception! %o", e);
      }
    }, 500);
  }, [externalPopup]);

  useEffect(() => {
    intializeUserData();
  }, []);

  const intializeUserData = useCallback(() => {
    if (userLoggedIn) {
      console.log("User is logged in");
      if (!user) {
        console.log("User data is not available");
        fetchUserData()
          .then((result) => {
            console.log("fetch result " + JSON.stringify(result));
            dispatch(loginUser(result));
          })
          .catch((error) => {
            if (error?.message?.includes("403")) {
              dispatch(logoutUser());
            }
          });
      }
    } else {
      console.log("User is not logged in going to path ");
    }
  }, [user]);

  const handleClose = (event: any, reason?: string) => {
    console.log("handle close for reason =" + reason);

    if (reason === "clickaway") {
      return;
    }
    dispatch(
      showSnackbar({ message: "", action: "", onAction: null, loading: false })
    );
  };

  const handleSnackBarAction = useCallback(
    (event: React.SyntheticEvent | React.MouseEvent) => {
      if (snackBar.onAction) {
        snackBar.onAction();
      }
      handleClose(event);
    },
    [snackBar]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
        flex: "1",
      }}
    >
      <SideNav
        openNav={openMenu}
        onCloseNav={() => {
          setOpenMenu(false);
        }}
      />

      <Box
        sx={{
          flex: "1",
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <Header
          displayMenuButton
          toggleMenuClicked={() => {
            setOpenMenu(!openMenu);
          }}
        />
        <ChildrenContainer>{children}</ChildrenContainer>
      </Box>

      <Drawer
        anchor={"right"}
        open={Boolean(openedUploadItem)}
        onClose={() => {
          dispatch(setOpenedUploadItem(null));
        }}
        ModalProps={{
          BackdropProps: {
            style: {
              backgroundColor: "transparent", // Make the backdrop transparent
            },
          },
        }}
      >
        <Box
          sx={{
            width: "35vw",
            [theme.breakpoints.down("lg")]: {
              width: "65vw",
            },
            [theme.breakpoints.down("md")]: {
              width: "65vw",
            },
            [theme.breakpoints.down("sm")]: {
              width: "85vw",
            },
          }}
        >
          {openedUploadItem && (
            <UploadedItemDetailContainer
              uploadItem={openedUploadItem}
              onClose={() => {
                dispatch(setOpenedUploadItem(null));
              }}
            />
          )}
        </Box>
      </Drawer>
      <Snackbar
        //  className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackBar.message.length > 0}
        autoHideDuration={snackBar.loading ? 600000 : 5000} //when it's a loading snackbar, we leave it there until the action confirmation arrives to update it.
        onClose={handleClose}
        TransitionComponent={TransitionUp}
        message={<Typography>{snackBar.message}</Typography>}
        action={
          <React.Fragment>
            {snackBar.loading ? (
              <CircularProgress
                style={{ margin: "8px 0px 8px 130px", padding: "2px" }}
              />
            ) : (
              <Button
                style={{
                  margin: "8px 0px 8px 130px",
                  fontSize: "1.4rem",
                }}
                color="primary"
                size="large"
                onClick={handleSnackBarAction}
              >
                {snackBar.action}
              </Button>
            )}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {openUpgradePlanDialog.show && (
        <UpgradePlanDialog
          open={openUpgradePlanDialog.show}
          handleModalClose={() => {
            dispatch(
              showUpgradePlanDialog({
                show: false,
                message: null,
              })
            );
          }}
          description={openUpgradePlanDialog.message}
        />
      )}
    </Box>
  );
};

interface ChildrenContainerProps {
  children: React.ReactNode;
  sx?: object;
}
const ChildrenContainer = ({
  children,
  sx,
  ...other
}: ChildrenContainerProps) => {
  const lgUp = useResponsive("up", "lg");
  const theme = useTheme();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        flex: "1",
        px: 2,

        /*  py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }), */
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default React.memo(MainBoard);
