import React, { useRef, useEffect } from "react";
import EmailForwardingMP4 from "res/gifs/email_forwarding.mp4";

interface LoopingVideoProps {
  videoRessource: any;
}

const LoopingVideo = ({ videoRessource }: LoopingVideoProps) => {
  const videoRef = useRef<any>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    // Intersection Observer callback
    const handleIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          videoElement.currentTime = 0; // Reset to start
          videoElement.play(); // Play the video
        } else {
          videoElement.pause(); // Pause the video when out of view
        }
      });
    };

    // Create an Intersection Observer
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Adjust this as needed (e.g., 0.5 means 50% of the video should be visible)
    });

    if (videoElement) {
      observer.observe(videoElement);
    }

    // Cleanup on component unmount
    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  return (
    <video
      ref={videoRef}
      src={videoRessource}
      loop
      muted
      playsInline
      autoPlay
      style={{ width: "100%", height: "auto" }}
    />
  );
};

export default LoopingVideo;
