import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { editSubscriptionCardV3 } from "api/stripeApi";
import { DEBUG } from "configuration";
import { useSelector } from "react-redux";
import { UserObj } from "models/user";
import { fetchUserData } from "api/authApi";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";
import { getUserCurrency } from "localstorage/localstorage";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Button as MaterialButton, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { RootState } from "store";
import { loginUser, logoutUser } from "store/auth";
import { LoadingButton } from "@mui/lab";

const CardInputWrapper = styled.div`
  border: 2px solid gray;
  border-radius: 8px;
  padding: 20px 4px;
  height: 64px;
  margintop: 16px;
`;

const Container = styled.div`
  width: 50%;
`;

const inputStyle = {
  iconColor: "gray",
  color: "black",
  fontWeight: "500",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "1.5rem",
  fontSmoothing: "antialiased",
  "::placeholder": {
    color: "gray",
  },
};

const useStyles = makeStyles((theme) => ({
  nameInputField: {
    marginTop: "16px",
    marginBottom: "16px",
    "& .MuiInputBase-input": {
      fontSize: "1.5rem",
      padding: "12px 12px",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    width: "45%",

    input: {
      "::placeholder": {
        color: "blue",
      },
    },
  },
  payButton: {
    fontSize: "3rem",
    fontWeight: "normal",
    "& .MuiButtonBase-root": {
      fontSize: "1.5rem",
      fontWeight: "normal",
    },
  },
  headerBox: {
    display: "flex",
    flexDirection: "row",
  },
  closeTooltip: {
    fontSize: "3rem",
  },
}));

interface DialogCheckoutFormProps {
  onSuccess: () => void;
  onCloseClicked: () => void;
}

const DialogCheckoutForm = ({
  onSuccess,
  onCloseClicked,
}: DialogCheckoutFormProps) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const theme = useTheme();
  const [displayPaymentError, setDisplayPaymentError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(t("payment_error_invalid_card"));
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [fullNameFieldContent, setFullNameFieldContent] = useState("");
  const [fullNameErrorText, setFullNameErrorText] = useState("");
  const classes = useStyles();
  const user = useSelector((state: RootState) => state.auth.user);

  //console.log(" stripe user " + JSON.stringify(user));
  // Handle real-time validation errors from the CardElement.
  const handleChange = (event: any) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setDisplayPaymentError(false);
    if (fullNameFieldContent.length == 0) {
      setFullNameErrorText("Full name is required");
      return;
    } else {
      setFullNameErrorText("");
    }
    const card = elements?.getElement(CardElement);
    setIsPaymentLoading(true);
    const processEditingCard = async (email: string) => {
      editSubscriptionCardV3()
        .then((data) => {
          if (DEBUG) {
            console.log(
              "Edit subscription payment result is " + JSON.stringify(data)
            );
          }
          if (data.result == "0") {
            const clientSecret = data.clientSecret;
            //const confirmPaymentResult = await
            stripe
              ?.confirmCardSetup(clientSecret, {
                payment_method: {
                  card: card!!,
                  billing_details: {
                    name: fullNameFieldContent,
                    email: user?.email,
                  },
                },
              })
              .then((result) => {
                console.log("payment confirmed result %o", result);
                setIsPaymentLoading(false);
                if (result.error) {
                  //alert(result.error.message);
                  setErrorMsg(t("payment_error_failed_to_confirm_payment"));
                  setDisplayPaymentError(true);
                } else {
                  // Successful subscription payment
                  if (result.setupIntent.status == "succeeded") {
                    //Success
                    onSuccess();
                  }
                }
              });
          } else if (data.result == "1") {
            //Payment failed at this stage
            setErrorMsg(t("payment_error_failed_to_provision"));
            setDisplayPaymentError(true);
            //onSubscriptionFailed();
          }
        })
        .catch((e) => {
          console.log("caught an error %o", e);
          setErrorMsg(t("payment_error_failed_toreach_backend"));
          setDisplayPaymentError(true);
          setIsPaymentLoading(false);
        });
      // } else {
      //   setIsPaymentLoading(false);
      // }
      //console.log("stripe payment method %o", paymentMethod);
      //};
    };

    if (!user) {
      fetchUserData()
        .then((user: UserObj) => {
          //dispatchLogin(user);
          //history.push(MAINBOARD);
          console.log("User data is  " + JSON.stringify(user));
          dispatch(loginUser(user));
          processEditingCard(user.email);
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    } else {
      processEditingCard(user.email);
    }
  };
  return (
    <Paper
      elevation={0}
      sx={{
        padding: "16px",
      }}
    >
      <Box className={classes.headerBox}>
        <Typography
          sx={{ width: "100%" }}
          variant="h6"
          component="div"
          gutterBottom
        >
          {t("edit_payment_dialog_title")}
        </Typography>
        <Tooltip className={classes.closeTooltip} title={t("title_close")}>
          {/* <MaterialButton onClick={onCloseClicked} variant="outlined">
            <ClearIcon
              style={{ color: "gray", height: "24px", width: "24px" }}
            />
          </MaterialButton> */}
          <IconButton onClick={onCloseClicked}>
            {" "}
            <ClearIcon
              style={{ color: "gray", height: "24px", width: "24px" }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      {displayPaymentError && (
        <Alert severity="error" style={{ marginBottom: theme.spacing(2) }}>
          {errorMsg}
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="stripe-form">
        <TextField
          error={fullNameErrorText.length > 0}
          helperText={fullNameErrorText}
          className={classes.nameInputField}
          fullWidth={true}
          placeholder={t("full_name_title")}
          variant="standard"
          onChange={(e) => {
            setFullNameFieldContent(e.target.value);
          }}
          sx={{
            "& .MuiInputBase-root": {
              border: `2px solid ${theme.palette.primary.main}`,
              borderRadius: "8px",
            },
          }}
          FormHelperTextProps={{
            style: {
              fontSize: "1.2rem",
              color: "black",
            },
          }}
          InputProps={{
            style: {
              color: "black",
              fontWeight: "500",
            },
            startAdornment:
              fullNameErrorText.length == 0 ? (
                <InputAdornment position="start">
                  <PersonSharpIcon
                    sx={{
                      opacity: "0.5",
                      color: "gray",
                      width: "24px",
                      height: "24px",
                      mx: theme.spacing(1),
                    }}
                  />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <ErrorOutlineIcon
                    sx={{
                      opacity: "0.5",
                      color: "red",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </InputAdornment>
              ),
            disableUnderline: true,
          }}
        ></TextField>
        <div className="form-row" style={{ marginTop: "32px" }}>
          <CardInputWrapper>
            <CardElement
              options={{
                style: {
                  base: inputStyle,
                },
              }}
              onChange={handleChange}
            />
          </CardInputWrapper>
          <div className="card-errors" role="alert">
            {error}
          </div>
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
            marginTop: theme.spacing(2),
          }}
        >
          <LoadingButton sx={{}} type="submit" loading={isPaymentLoading}>
            {t("edit_payment_save_card")}
          </LoadingButton>
        </Box>
      </form>
    </Paper>
  );
};
export default DialogCheckoutForm;
