import { gql } from "@apollo/client";

export const UPLOAD_LINKS_ATTRIBUTES = `
      id
      createdAt
      token
      name
      uploadsCount
      allowedFiles
      maxUploadsCount
      maxTotalUploadsSize
      totalUploadsSize
      remainingSpace
      expiryDate
      status
      integrations {
        storageProvider
        destinationFolder{
          url
          name
        }
      }`;

export const UPLOAD_ITEMS_ATTRIBUTES = `
      id
      fileName
      fileSize
      fileUrl
      fileType
      createdAt
      hasPreview
      internalFileUpload{
        id
      }
      uploadLink {
        integrations{
          storageProvider
        }
      }
      uploader{
        firstName
        lastName
        email
        message
      }`;

export const EMAIL_UPLOAD_ITEMS_ATTRIBUTES = `
      id
      fileName
      fileSize
      fileUrl
      fileType
      createdAt
      hasPreview
      internalFileUpload{
        id
      }`;

export const retrieveEmailUploadItemsGql = gql`
  query($page: Int, $pageSize: Int){
    emailUploadItemsPaged(page: $page, pageSize: $pageSize){
      ${EMAIL_UPLOAD_ITEMS_ATTRIBUTES}
    }
  }
`;

export const retrieveEmailIntegrationGql = gql`
  query {
    emailIntegration {
      id
      uploadsCount
      integrations {
        storageProvider
        destinationFolder{
          url
          name
        }
      }
    }
  }
`;

export const retrieveConnectedStorageForIdGql = gql`
  query($id: ID) {
    connectedStorage(id: $id) {
      id
      storageProvider
      accountEmail
      createdAt
      destinationFolder {
        url
        name
      }
    }
  }
`;

export const retrieveLastNUploadItemsGql = gql`
  query($n: Int) {
    lastNUploadItems(n: $n) {
      ${UPLOAD_ITEMS_ATTRIBUTES}
    }
  }
`;

export const retrieveLastNConnectedStoragesGql = gql`
  query($n: Int) {
    lastNConnectedStorages(n: $n) {
      id
      storageProvider
      accountEmail
      createdAt
      destinationFolder {
        url
        name
      }
    }
  }
`;

export const retrieveLastNUploadLinksGql = gql`
query($n: Int) {
  lastNUploadLinks(n: $n) {
    ${UPLOAD_LINKS_ATTRIBUTES}
  }
}
`;

export const retrievePublicUploadLinkForTokenGql = gql`
  query($token: String) {
    uploadLink(token: $token) {
      id
      allowedFiles
      maxTotalUploadsSize
      totalUploadsSize
      expiryDate
      status
      remainingSpace
      uploadsCount
      maxUploadsCount
      layoutSettings {
        showUserInfo
        description
        enableUserNameField
        enableUserEmailField
        enableUserMessageField
        messageTitle
        owner {
          firstName
          lastName
        }
      }
    }
  }
`;

export const retrieveUploadLinkLayoutSettingsGql = gql`
  query($uploadLinkId: ID) {
    uploadLinkLayoutSettings(uploadLinkId: $uploadLinkId) {
      showUserInfo
      description
      enableUserNameField
      enableUserEmailField
      enableUserMessageField
      messageTitle
      owner {
        firstName
        lastName
      }
    }
  }
`;

export const retrieveUploadItemsPagedGql = gql`
  query($page: Int, $pageSize: Int){
    uploadItemsPaged(page: $page, pageSize: $pageSize){
      ${UPLOAD_ITEMS_ATTRIBUTES}
    }
  }
`;

export const retrieveUploadItemForLinkIdPagedGql = gql`
  query($linkId: ID, $page: Int, $pageSize: Int){
    uploadItemsForLinkPaged(linkId: $linkId, page: $page, pageSize: $pageSize){
      ${UPLOAD_ITEMS_ATTRIBUTES}
    }
  }
`;

export const retrieveUploadLinkForIdGql = gql`
  query($id: ID) {
    uploadLink(id: $id) {
      ${UPLOAD_LINKS_ATTRIBUTES}
    }
  }
`;

export const retrieveUploadItemsForConnectedStoragePagedGql = gql`
  query($id: ID, $page: Int, $pageSize: Int) {
    uploadItemsForConnectedStoragePaged(id: $id, page: $page, pageSize: $pageSize) {
      ${UPLOAD_ITEMS_ATTRIBUTES}
    }
  }
`;

export const retrieveUploadLinksForConnectedStoragePagedGql = gql`
  query($id: ID, $page: Int, $pageSize: Int) {
    uploadLinksForConnectedStoragePaged(id: $id, page: $page, pageSize: $pageSize) {
      ${UPLOAD_LINKS_ATTRIBUTES}
    }
  }
`;

export const retrieveUploadLinksPagedGql = gql`
  query($page: Int, $pageSize: Int) {
    allUploadLinksPaged(page: $page, pageSize: $pageSize) {
      ${UPLOAD_LINKS_ATTRIBUTES}
    }
  }
`;

export const retrieveConnectedStoragesPagedGql = gql`
  query($page: Int, $pageSize: Int) {
    allConnectedStoragesPaged(page: $page, pageSize: $pageSize) {
      id
      storageProvider
      accountEmail
      createdAt
      destinationFolder {
        url
        name
      }
    }
  }
`;

export const retrieveConnectedStoragesGql = gql`
  query {
    allConnectedStorages {
      id
      storageProvider
      accountEmail
      destinationFolder {
        url
        name
      }
    }
  }
`;
