import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import authActions from 'store/auth';
import view from 'store/view';

const store=  configureStore({
  reducer: {
    view: view,
    auth: authActions,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
