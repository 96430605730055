import { Box, IconButton, useTheme } from "@mui/material";
import { HEADER, SPACING } from "config/config-layout";
import React, { useState } from "react";
import CurrentUserAvatarMenu from "components/ui/menu/CurrentUserAvatar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

interface HeaderProps {
  displayMenuButton?: boolean;
  toggleMenuClicked?: () => void;
}

const Header = ({
  displayMenuButton = false,
  toggleMenuClicked,
}: HeaderProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: `${HEADER.H_MOBILE + SPACING}px`,
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "end",
        px: theme.spacing(8),
        //  height: "100vw",
        [theme.breakpoints.down("lg")]: {
          justifyContent: displayMenuButton ?  "space-between" : "end",
          px: theme.spacing(2),

        },
      }}
    >
      {displayMenuButton && (
        <IconButton
          sx={{
            display: "none",
            [theme.breakpoints.down("lg")]: {
              display: "block",
            },
          }}
          onClick={() => {
            toggleMenuClicked && toggleMenuClicked();
          }}
        >
          <MenuOutlinedIcon />
        </IconButton>
      )}
      <CurrentUserAvatarMenu />
    </Box>
  );
};

export default React.memo(Header);
