import { useQuery } from "@apollo/client";
import {
  Avatar,
  Box,
  Button,
  Card,
  Skeleton,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { getUserAvatarForUploadLinkId } from "configuration";
import { retrieveUploadLinkLayoutSettingsGql } from "graphql/queries";
import { UploadLink, UploadLinkLayoutSettings } from "models/uploadly";
import { UserObj, getUserInitials } from "models/user";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import { isValidEmail } from "shared/dataUtils";
import { RootState } from "store";

interface PublicUploadLinkContainerProps {
  layoutSettings: UploadLinkLayoutSettings | null | undefined;
  uploadLink: UploadLink | null | undefined;
  loading?: boolean;
  readOnly?: boolean;
  onUploadFiles?: () => void;
  hideUploadButton?: boolean;
  //validationRef?: any;
}

const PublicUploadLinkContainer = forwardRef<
  any,
  PublicUploadLinkContainerProps
>(
  (
    {
      layoutSettings,
      uploadLink,
      loading,
      readOnly = false,
      onUploadFiles,
      hideUploadButton = false,
      //validationRef,
    },
    ref
  ) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const { t } = useTranslation();
    const theme = useTheme();

    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const messageRef = useRef<HTMLInputElement>(null);

    const [firstNameInputValue, setFirstNameInputValue] = useState();
    const [lastNameInputValue, setLastNameInputValue] = useState();
    const [emailInputValue, setEmailInputValue] = useState<string | null>(null);
    const [messageInputValue, setMessageInputValue] = useState();

    const [firstNameInputError, setFirstNameInputError] = useState<
      string | null
    >(null);
    const [lastNameInputError, setLastNameInputError] = useState<string | null>(
      null
    );
    const [emailInputError, setEmailInputError] = useState<string | null>(null);
    const [messageInputError, setMessageInputError] = useState<string | null>(
      null
    );

    useImperativeHandle(
      ref,
      () => ({
        validateAndGetValues() {
          var validationOk = true;
          if (layoutSettings?.enableUserNameField) {
            console.log(
              "validateAndGetValues just go called! " + firstNameInputValue
            );
            if (!firstNameInputValue) {
              setFirstNameInputError(t("field_required_message"));
              validationOk = false;
            }
            if (!lastNameInputValue) {
              setLastNameInputError(t("field_required_message"));
              validationOk = false;
            }
          }
          if (layoutSettings?.enableUserEmailField) {
            if (!emailInputValue) {
              setEmailInputError(t("field_required_message"));
              validationOk = false;
            }
            if (emailInputValue && !isValidEmail(emailInputValue)) {
              setEmailInputError(t("must_be_valid_email_msg"));
              validationOk = false;
            }
          }
          if (layoutSettings?.enableUserMessageField) {
            if (!messageInputValue) {
              setMessageInputError(t("field_required_message"));
              validationOk = false;
            }
          }

          return {
            validationOk: validationOk,
            fields: {
              firstName: layoutSettings?.enableUserNameField
                ? firstNameInputValue
                : undefined,
              lastName: layoutSettings?.enableUserNameField
                ? lastNameInputValue
                : undefined,
              email: layoutSettings?.enableUserEmailField
                ? emailInputValue
                : undefined,
              message: layoutSettings?.enableUserMessageField
                ? messageInputValue
                : undefined,
            },
          };
        },
      }),
      [
        layoutSettings,
        firstNameInputValue,
        lastNameInputValue,
        emailInputValue,
        messageInputValue,
      ]
    );

    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "60%",
          }}
        >
          <Box>
            <Box>
              <Skeleton
                variant="circular"
                animation={"pulse"}
                sx={{
                  background: "gray",
                }}
                width={90}
                height={90}
              />

              <Skeleton
                variant="text"
                animation={"pulse"}
                sx={{ background: "gray", fontSize: "2rem", width: "50%" }}
              />
            </Box>
            {/*  -------------------------------- DESCRIPTION  --------------------------------*/}

            <Skeleton
              variant="text"
              animation={"pulse"}
              sx={{ background: "gray", fontSize: "2rem", width: "100%" }}
            />

            <Skeleton
              variant="text"
              animation={"pulse"}
              sx={{ background: "gray", fontSize: "3rem", width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation={"pulse"}
              sx={{ background: "gray", fontSize: "3rem", width: "100%" }}
            />
            <Skeleton
              variant="text"
              animation={"pulse"}
              sx={{ background: "gray", fontSize: "3rem", width: "100%" }}
            />
          </Box>

          <Box
            sx={{
              marginTop: theme.spacing(8),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Skeleton
              variant="text"
              animation={"pulse"}
              sx={{ background: "gray", fontSize: "3rem", width: "40%" }}
            />
          </Box>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box>
          {layoutSettings?.showUserInfo && layoutSettings?.owner ? (
            <Box
              sx={{
                transition: theme.transitions.create("all", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              }}
            >
              <Avatar
                src={getUserAvatarForUploadLinkId(uploadLink?.id)}
                sx={{
                  border: "1px solid black",
                  fontSize: "3rem",
                  width: "96px",
                  height: "96px",
                  background: theme.palette.primary.main,
                }}
              >
                {getUserInitials(layoutSettings?.owner)}
              </Avatar>
              <Typography
                variant="h3"
                sx={{
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                {layoutSettings?.owner?.firstName +
                  " " +
                  layoutSettings?.owner?.lastName}
              </Typography>
            </Box>
          ) : (
            <Box>
              <img
                src={UploadlyIcon}
                style={{
                  width: "72px",
                  height: "72px",
                }}
              />
            </Box>
          )}
          {/*  -------------------------------- DESCRIPTION  --------------------------------*/}

          <Typography
            variant="body1"
            sx={{
              color: "white",
              marginTop: theme.spacing(1),
            }}
          >
            {layoutSettings?.description
              ? layoutSettings?.description
              : t("upload_link_layout_setting_description_default")}
          </Typography>
          {/*  -------------------------------- END DESCRIPTION  --------------------------------*/}

          {/*  -------------------------------- ASK FOR USER NAME  --------------------------------*/}

          {layoutSettings?.enableUserNameField && (
            <Box
              sx={{
                marginTop: theme.spacing(2),
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextField
                inputRef={firstNameRef}
                placeholder={t("first_name")}
                helperText={firstNameInputError}
                error={Boolean(firstNameInputError)}
                value={firstNameInputValue}
                onChange={(event: any) => {
                  const value = event.target.value;
                  if (value) {
                    setFirstNameInputError(null);
                  }
                  setFirstNameInputValue(event.target.value);
                }}
                fullWidth
                /// defaultValue={layoutSettings.description}
                sx={{
                  marginTop: theme.spacing(1),
                  color: "white",
                  "& .MuiInputBase-input::placeholder": {
                    color: "white", // Inline styling example
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                }}
              />
              <TextField
                inputRef={lastNameRef}
                placeholder={t("last_name")}
                value={lastNameInputValue}
                helperText={lastNameInputError}
                error={Boolean(lastNameInputError)}
                onChange={(event: any) => {
                  const value = event.target.value;
                  if (value) {
                    setLastNameInputError(null);
                  }
                  setLastNameInputValue(event.target.value);
                }}
                fullWidth
                //defaultValue={layoutSettings.description}
                sx={{
                  marginTop: theme.spacing(1),
                  marginLeft: theme.spacing(1),
                  colorl: "white",
                  "& .MuiInputBase-input::placeholder": {
                    color: "white", // Inline styling example
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                }}
              />
            </Box>
          )}

          {/*  -------------------------------- END ASK FOR USER NAME  --------------------------------*/}

          {/*  -------------------------------- ASK FOR USER EMAIL  --------------------------------*/}

          {layoutSettings?.enableUserEmailField && (
            <Box
              sx={{
                marginTop: theme.spacing(2),
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextField
                inputRef={emailRef}
                placeholder={t("email")}
                helperText={emailInputError}
                error={Boolean(emailInputError)}
                type="email"
                value={emailInputValue}
                onChange={(event: any) => {
                  const value = event.target.value;
                  if (value) {
                    setEmailInputError(null);
                  }
                  setEmailInputValue(event.target.value);
                }}
                fullWidth
                /// defaultValue={layoutSettings.description}
                sx={{
                  marginTop: theme.spacing(1),
                  color: "white",
                  "& .MuiInputBase-input::placeholder": {
                    color: "white", // Inline styling example
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                  },
                }}
              />
            </Box>
          )}

          {/*  -------------------------------- END ASK FOR USER NAME  --------------------------------*/}

          {/*  -------------------------------- ASK FOR USER MESSAGE  --------------------------------*/}

          {layoutSettings?.enableUserMessageField && (
            <Box
              sx={{
                marginTop: theme.spacing(2),
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextField
                inputRef={messageRef}
                placeholder={
                  layoutSettings.messageTitle
                    ? layoutSettings.messageTitle
                    : t(
                        "upload_link_layout_setting_ask_for_user_message_title_default_value"
                      )
                }
                type="text"
                // type="color"
                value={messageInputValue}
                helperText={messageInputError}
                error={Boolean(messageInputError)}
                onChange={(event: any) => {
                  const value = event.target.value;
                  if (value) {
                    setMessageInputError(null);
                  }
                  setMessageInputValue(event.target.value);
                }}
                fullWidth
                multiline
                rows={4} // Number of initial rows
                maxRows={20} // Maximum number of rows before scrolling
                /// defaultValue={layoutSettings.description}
                sx={{
                  marginTop: theme.spacing(1),
                  color: "white",
                  "& .MuiInputBase-input::placeholder": {
                    color: "white", // Inline styling example
                  },
                  padding: 0,
                  "& .MuiInputBase-root": {
                    padding: "8px",
                  },
                  "& .MuiInputBase-input": {
                    color: "white",
                    padding: 0,
                    // height: "80px",
                    alignContent: "top",
                    textAlign: "top",
                  },
                }}
              />
            </Box>
          )}
          {/*  -------------------------------- END ASK FOR USER NAME  --------------------------------*/}
        </Box>
        {!hideUploadButton && (
          <Box
            sx={{
              marginTop: theme.spacing(8),
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Button
              variant="contained"
              sx={{
                maxWidth: "30%",
                color: "white",
                px: theme.spacing(12),
              }}
              fullWidth={false}
              onClick={() => {
                if (!readOnly) {
                  onUploadFiles && onUploadFiles();
                }
              }}
            >
              {t("upload_action")}
            </Button>
            {!uploadLink?.allowedFiles.split(",").includes("all") && (
              <Typography
                sx={{
                  color: "white",
                  fontSize: ".8rem",
                  fontStyle: "italic",
                }}
              >
                {t("accepts_files", { file_types: uploadLink?.allowedFiles })}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  }
);

export default React.memo(PublicUploadLinkContainer);
