import Button from "@mui/material/Button";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link as TextLink } from "@mui/material";
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "navigation/Constants";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNavigate } from "react-router-dom";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { StorageProviderType } from "models/uploadly";


export interface ConfigureStoragePayload {
    storageType: StorageProviderType;
    data: any;
}

interface ConfigureStorageFolderDialogProps {
  open: boolean;
  payload: ConfigureStoragePayload;
  onConfigureDestinationFolder: (payload: ConfigureStoragePayload) => void;
  handleModalClose: (b: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    addButton: {
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingTop: "24px",
    },
  })
);

export default function ConfigureStorageFolderDialog({
  open,
  payload,
  onConfigureDestinationFolder,
  handleModalClose,
}: ConfigureStorageFolderDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {/* handleModalClose(false) */}}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {t("configure_destination_folder_dialog_title")}
            </Typography>
            <Typography
              sx={{
                marginTop: theme.spacing(4),
              }}
              variant="subtitle2"
            >
              {t("configure_destination_folder_dialog_description")}
            </Typography>

            <div className={classes.buttonContainer}>
              <Button
                className={classes.addButton}
                color="primary"
                onClick={() => handleModalClose(false)}
                sx={{ marginRight: "12px" }}
              >
                {t("upload_to_root_location")}
              </Button>

              <Button
                className={classes.addButton}
                color="primary"
                variant="contained"
                onClick={() => {
                  onConfigureDestinationFolder(payload);
                }}
              >
                <DriveFolderUploadIcon
                  sx={{
                    color: "white",
                    height: "100%",
                    width: "24px",
                    paddingRight: "8px",
                  }}
                />

                {t("configure_destination_folder_action")}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
