import Button from "@mui/material/Button";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import React, { useCallback, useRef } from "react";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Alert, Box, TextField, Link as TextLink } from "@mui/material";
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "navigation/Constants";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNavigate } from "react-router-dom";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { StorageProviderType } from "models/uploadly";
import { ftpConnect, ftpLogin } from "api/ftpApi";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import { LoadingButton } from "@mui/lab";
import { isValidUnixPath } from "shared/dataUtils";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/view";

interface ConfigureFtpStorageDialogProps {
  open: boolean;
  handleModalClose: (b: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "40%",
      display: "flex",
      flexDirection: "column",
      marginTop: "24px",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    addButton: {
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingTop: "24px",
    },
  })
);

interface FtpStorageInformation {
  host: string;
  username: string;
  password: string;
  path: string;
}

export default function ConfigureFtpStorageDialog({
  open,
  handleModalClose,
}: ConfigureFtpStorageDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [ftpLoginOngoing, setFtpLoginOngoing] = React.useState(false);
  const [
    ftpStorageCreationOngoing,
    setFtpStorageCreationOngoing,
  ] = React.useState(false);
  const [ftpLoginSuccess, setFtpLoginSuccess] = React.useState(false);
  const [pathNotValidError, setPathNotValidError] = React.useState<
    string | null
  >(null);
  const [displayFtpLoginFailed, setDisplayFtpLoginFailed] = React.useState(
    false
  );

  const [
    ftpStorageInformation,
    setFtpStorageInformation,
  ] = React.useState<FtpStorageInformation | null>();

  const hostInputRef = useRef<any>(null);
  const usernameInputRef = useRef<any>(null);
  const passwordInputRef = useRef<any>(null);
  const pathInputRef = useRef<any>(null);

  const handleFtpLogin = useCallback(() => {
    const host = hostInputRef.current.value;
    const username = usernameInputRef.current.value;
    const password = passwordInputRef.current.value;
    setDisplayFtpLoginFailed(false);
    setFtpLoginOngoing(true);
    ftpLogin(host, username, password)
      .then((result) => {
        console.log("Got ftp login result " + JSON.stringify(result));
        if (result.success) {
          setFtpLoginOngoing(false);
          setDisplayFtpLoginFailed(true);
          setFtpLoginSuccess(true);
          setFtpStorageInformation({
            host: host,
            username: username,
            password: password,
            path: "",
          });
        } else {
          setFtpLoginOngoing(false);
          setDisplayFtpLoginFailed(true);
          setFtpLoginSuccess(false);
        }
      })
      .catch((e) => {
        setFtpLoginOngoing(false);
        setDisplayFtpLoginFailed(true);
        setFtpLoginSuccess(false);
      });
  }, [
    hostInputRef.current,
    usernameInputRef.current,
    passwordInputRef.current,
  ]);

  const handleConnectFtpStorage = useCallback(() => {
    const path = pathInputRef.current.value;

    if (!isValidUnixPath(path)) {
      setPathNotValidError(t("path_invalid_error"));
      return;
    }
    setPathNotValidError(null);
    if (!ftpStorageInformation) {
      return;
    }
    setFtpStorageCreationOngoing(true);
    ftpConnect(
      ftpStorageInformation?.host,
      ftpStorageInformation?.username,
      ftpStorageInformation?.password,
      path
    )
      .then((result) => {
        setFtpStorageCreationOngoing(false);
        dispatch(
          showSnackbar({
            message: t("storage_connected_confirmation"),
            loading: false,
          })
        );
        handleModalClose(false);
        //console.log("Got ftp connect result " + JSON.stringify(result));
      })
      .catch((e) => {
        setFtpStorageCreationOngoing(false);
        dispatch(
          showSnackbar({
            message: t("operation_failed_generic"),
            action: "",
            onAction: null,
            loading: false,
          })
        );
        handleModalClose(false);
      });
  }, [ftpStorageInformation, pathInputRef.current]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          // handleModalClose(false);
        }}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {!ftpLoginSuccess && (
              <>
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {t("connect_ftp_server_dialog_title")}
                </Typography>
                {displayFtpLoginFailed && (
                  <Alert severity="error" sx={{ marginTop: theme.spacing(1) }}>
                    {t("ftp_login_failed_error")}
                  </Alert>
                )}
                <TextField
                  placeholder={t("ftp_host_title")}
                  inputRef={hostInputRef}
                  /*  defaultValue={nameInputValue}
              value={nameInputValue}
              onChange={(e) => {
                setNameInputValue(e.target.value);
              }} */
                  /*  error={nameInputValue.length == 0}
              helperText={
                nameInputValue.length == 0 ? t("name_must_not_be_empy") : null
              } */
                  sx={{
                    color: "white",
                    marginBottom: theme.spacing(1),
                    marginTop: theme.spacing(2),
                    px: theme.spacing(2),
                  }}
                  inputProps={{
                    color: "white",
                  }}
                />

                <TextField
                  placeholder={t("ftp_user_title")}
                  inputRef={usernameInputRef}
                  type="text"
                  /*  defaultValue={nameInputValue}
              value={nameInputValue}
              onChange={(e) => {
                setNameInputValue(e.target.value);
              }} */
                  /*  error={nameInputValue.length == 0}
              helperText={
                nameInputValue.length == 0 ? t("name_must_not_be_empy") : null
              } */
                  sx={{
                    color: "white",
                    marginBottom: theme.spacing(1),
                    px: theme.spacing(2),
                  }}
                  inputProps={{
                    color: "white",
                  }}
                />
                <TextField
                  placeholder={t("password")}
                  inputRef={passwordInputRef}
                  /*  defaultValue={nameInputValue}
              value={nameInputValue}
              onChange={(e) => {
                setNameInputValue(e.target.value);
              }} */
                  /*  error={nameInputValue.length == 0}
              helperText={
                nameInputValue.length == 0 ? t("name_must_not_be_empy") : null
              } */
                  type="password"
                  sx={{
                    colorl: "white",
                    marginBottom: theme.spacing(1),
                    px: theme.spacing(2),
                  }}
                  inputProps={{
                    color: "white",
                  }}
                />

                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    onClick={() => handleModalClose(false)}
                    sx={{ marginRight: "12px" }}
                  >
                    {t("cancel_action")}
                  </Button>

                  <LoadingButton
                    className={classes.addButton}
                    color="primary"
                    variant="contained"
                    onClick={handleFtpLogin}
                    loading={ftpLoginOngoing}
                  >
                    <SettingsInputAntennaIcon
                      sx={{
                        color: "white",
                        height: "100%",
                        width: "24px",
                        paddingRight: "8px",
                      }}
                    />

                    {t("authenticate_action")}
                  </LoadingButton>
                </div>
              </>
            )}

            {ftpLoginSuccess && (
              <>
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {t("connect_ftp_server_dialog_title")}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    marginTop: theme.spacing(2),
                    px: theme.spacing(2),
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      width: "100%",
                      textAlign: "start",
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    - {t("ftp_enter_path_instruction_1")} <br />-{" "}
                    {t("ftp_enter_path_instruction_2")} <br />-{" "}
                    {t("ftp_enter_path_instruction_3")}
                  </Typography>
                  <TextField
                    placeholder={t("path_to_folder_input_placeholder")}
                    inputRef={pathInputRef}
                    error={Boolean(pathNotValidError)}
                    helperText={pathNotValidError}
                    sx={{
                      width: "100%",
                      color: "white",
                      marginBottom: theme.spacing(1),
                    }}
                    inputProps={{
                      color: "white",
                    }}
                  />
                </Box>
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    onClick={() => handleModalClose(false)}
                    sx={{ marginRight: "12px" }}
                  >
                    {t("cancel_action")}
                  </Button>

                  <LoadingButton
                    className={classes.addButton}
                    color="primary"
                    variant="contained"
                    onClick={handleConnectFtpStorage}
                    loading={ftpStorageCreationOngoing}
                  >
                    <SettingsInputAntennaIcon
                      sx={{
                        color: "white",
                        height: "100%",
                        width: "24px",
                        paddingRight: "8px",
                      }}
                    />

                    {t("connect_action")}
                  </LoadingButton>
                </div>
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}
