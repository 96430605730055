const USER_LOGGED_IN_KEY = "user_logged_in_key";
const USER_LOGGED_IN = "1";
const USER_LOGGED_OUT = "0";

const LOCAL_CURRENCY = "user_currency_key";
const WEB_CLIENT_UNIQUE_ID = "web_client_unique_id";
const DISPLAY_NOTIFICATIONS_PERMISSION =
  "display_notifciation_permission_rationale";
const CONFIDENTIALITY_FILTER_KEY = "confidentiality_filter_key";
const PIN_SIDE_NAVIGATION_BAR = "pin_side_navigation_bar_key";
const COLLECTION_FILTER_OPTION = "collection_filter_option_key";
const COLLECTIONS_VIEW_TYPE = "collections_view_type";
const SELECT_COLLECTIONS_VIEW_TYPE = "select_collections_view_type";
const COLLECTIONS_SORT_TYPE = "collections_sort_type";
const AGGDATA_VIEW_TYPE = "aggdata_view_type";
const AGGDATA_SORT_TYPE = "aggdata_sort_type";
const AGGDATA_FILTER_TYPE = "aggdata_filter_type";

enum USER_LOGGED_STATUS {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
}


export function setUserCurrency(localCurrency: string) {
  localStorage.setItem(LOCAL_CURRENCY, localCurrency);
}

export function getUserCurrency()  {
  return localStorage.getItem(LOCAL_CURRENCY);
}


export function setWebClientUniqueId(uuid: string) {
  localStorage.setItem(WEB_CLIENT_UNIQUE_ID, uuid);
}

export function setNotificatioPermissionnDisplayed() {
  localStorage.setItem(DISPLAY_NOTIFICATIONS_PERMISSION, "1");
}

export function shouldDisplayNotificationPermissionRationale(): boolean {
  return localStorage.getItem(DISPLAY_NOTIFICATIONS_PERMISSION) != "1";
}

export function getWebClientUniqueId() {
  return localStorage.getItem(WEB_CLIENT_UNIQUE_ID);
}


export function setUserIsloggedIn() {
  localStorage.setItem(USER_LOGGED_IN_KEY, USER_LOGGED_IN);
}

export function setUserIsloggedOut() {
  localStorage.setItem(USER_LOGGED_IN_KEY, USER_LOGGED_OUT);
}

export function isUserLoggedIn(): boolean {
  return localStorage.getItem(USER_LOGGED_IN_KEY) === USER_LOGGED_IN;
}

//value can be either "personal" or "shared"
export function saveCollectionFilterOption(filterOption: string) {
  localStorage.setItem(COLLECTION_FILTER_OPTION, filterOption);
}
//value can be either "personal" or "shared"
export function getCollectionFilterType() {
  return localStorage.getItem(COLLECTION_FILTER_OPTION)
    ? localStorage.getItem(COLLECTION_FILTER_OPTION)
    : "personal";
}
