import {
  DEBUG,
  GOOGLE_LOGIN_KEY,
  REACT_APP_GOOGLE_AUTH_REDIRECT_URL,
} from "configuration";
import { useCallback, useEffect, useState } from "react";

export enum AuthType {
  GOOGLE_DRIVE = 1,
  GOOGLE_PHOTOS = 2,
}

export interface GoogleAuthMode {
  mode: AuthType;
}

export function useGoogleAuthPopup({
  mode,
}: GoogleAuthMode): [() => void, string | null] {
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [externalPopup, setExternalPopup] = useState<any>(null);
  const [scopes, _] = useState(() => {
    const driveScopes = "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";
    if (mode == AuthType.GOOGLE_DRIVE) {
      return driveScopes;
    } else if (mode == AuthType.GOOGLE_PHOTOS) {
      return "https://www.googleapis.com/auth/photoslibrary.readonly https://www.googleapis.com/auth/photoslibrary.appendonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";
    }
    else return driveScopes;
  });

  const showPopup = useCallback(() => {
    const clientId = GOOGLE_LOGIN_KEY;
    const redirectUri = REACT_APP_GOOGLE_AUTH_REDIRECT_URL;
  /*   const scope =
      "https://www.googleapis.com/auth/photoslibrary.appendonly https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";
 */
    const authUrl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&access_type=offline&prompt=select_account+consent`;

    var popupWidth = 500;
    var popupHeight = 600;
    var left = window.screenX + (window.outerWidth - popupWidth) / 2;
    var top = window.screenY + (window.outerHeight - popupHeight) / 2.5;
    const title = `Auth`;
    const popup = window.open(
      authUrl,
      title,
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  }, [setExternalPopup]);

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("code");
        if (code) {
          externalPopup.close();
          if (DEBUG) {
            console.log(`The popup URL has URL code param = ${code}`);
          }
          console.log("Got the code " + code);
          setExternalPopup(null);
          clearInterval(timer);
          setAuthCode(code);
        }
      } catch (e) {
        console.log("Caught an exception! %o", e);
      }
    }, 500);
  }, [externalPopup]);

  return [showPopup, authCode];
}
