import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getIconForStorageProvider,
  getStorageProviderName,
} from "pages/storages/storageProviderIds";
import { StorageProviderType } from "models/uploadly";
import DestinationFolderBadge from "components/ui/DestinationFolderBadge";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { formatIso8601date } from "shared/dataUtils";
import EmailUploadItemsContainer from "pages/emails/EmailUploadItemsContainer";
import ConnectCloudToEmailForwardModal from "components/modals/ConnectCloudToEmailForwardModal";
import { useMutation, useQuery } from "@apollo/client";
import { retrieveEmailIntegrationGql } from "graphql/queries";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { env } from "process";
import { DEBUG } from "configuration";
import { connectCloudToEmailGql } from "graphql/mutations";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/view";

const TAB_INCOMING_EMAILS = 0;
const TAB_INCOMING_EMAILS_SETTINGS = 1;

const MENU_ITEM_DISCONNECT = 1;
const MENU_ITEM_EDIT_CONNECTED_STORAGE = 2;

const getMenuOptions = (translation: any) => {
  return [
    {
      id: MENU_ITEM_DISCONNECT,
      title: translation("dissociate_action"),
      icon: <HighlightOffIcon color="primary" />,
    },
    {
      id: MENU_ITEM_EDIT_CONNECTED_STORAGE,
      title: translation("pick_another_storage"),
      icon: <ChangeCircleIcon color="primary" />,
    },
  ];
};

const EmailsForwardingContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const retrieveEmailIntegration = useQuery(retrieveEmailIntegrationGql);

  const [tabValue, setTabValue] = React.useState(TAB_INCOMING_EMAILS);
  const [
    displayConnectToCloudModal,
    setDisplayConnectToCloudModal,
  ] = React.useState(false);
  const [
    openEditConnectedStorageMenu,
    setOpenEditConnectedStorageMenu,
  ] = React.useState(false);
  // const anchorElement = useRef<any>();
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );

  const integrations =
    retrieveEmailIntegration.data?.emailIntegration?.integrations;
  const [connectCloudStorage, _] = useMutation(connectCloudToEmailGql);
  const dispatch = useDispatch();
  const handleDissociateConnectedStorage = useCallback(() => {
    connectCloudStorage({
      variables: {
        integrationId: "-1", //we use this value to ask the backend to remove the assocation
      },
    })
      .then((result) => {
        if (result.data.connectCloudToEmail.success) {
          dispatch(
            showSnackbar({
              message: t("operation_success_generic"),
              loading: false,
            })
          );
        }
      })
      .catch((e) => {
        console.log("Got the error ", e);
      });
  }, []);

  const handleMenuItemClicked = useCallback(
    (itemId: number) => {
      switch (itemId) {
        case MENU_ITEM_DISCONNECT:
          handleDissociateConnectedStorage();
          break;
        case MENU_ITEM_EDIT_CONNECTED_STORAGE:
          setDisplayConnectToCloudModal(true);
          break;
        default:
          if (DEBUG) {
            console.log("Item not connected!");
          }
      }
      closeMenu();
    },
    [setOpenEditConnectedStorageMenu, handleDissociateConnectedStorage]
  );

  const closeMenu = useCallback(() => {
    setOpenEditConnectedStorageMenu(false);
    setAnchorElement(null);
  }, [setOpenEditConnectedStorageMenu, setAnchorElement]);


  console.log("gotdata %o", retrieveEmailIntegration.data)
  return (
    <Box
      sx={{
        px: theme.spacing(8),
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
          px: theme.spacing(1),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon
            sx={{
              height: "32px",
              width: "32px",
            }}
          />
        </IconButton> */}

        <Typography
          variant="h3"
          sx={{
            marginLeft: theme.spacing(1),
          }}
        >
          📧
        </Typography>
        <Typography
          variant="h3"
          sx={{
            marginLeft: theme.spacing(2),
          }}
        >
          {t("emails_forwarded_title")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: theme.spacing(2),
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "start",
          },
        }}
      >
        {retrieveEmailIntegration.loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="30%"
              sx={{
                borderRadius: "4px",
              }}
            />
          </Box>
        )}
        {!retrieveEmailIntegration.loading &&
          !retrieveEmailIntegration.error &&
          retrieveEmailIntegration.data && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "gray",
                }}
              >
                {getIconForStorageProvider(
                  retrieveEmailIntegration.data.emailIntegration.integrations
                    .length > 0
                    ? retrieveEmailIntegration.data.emailIntegration
                        .integrations[0].storageProvider
                    : StorageProviderType.UPLOADLY_STORAGE,
                  {
                    height: "24px",
                    width: "24px",
                  }
                )}
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{
                  color: "gray",
                  marginLeft: theme.spacing(1),
                }}
              >
                {getStorageProviderName(
                  retrieveEmailIntegration.data.emailIntegration.integrations
                    .length > 0
                    ? retrieveEmailIntegration.data.emailIntegration
                        .integrations[0].storageProvider
                    : StorageProviderType.UPLOADLY_STORAGE
                )}
              </Typography>
              {integrations && integrations.length > 0 && (
                <>
                  <DestinationFolderBadge
                    destinationFolder={integrations[0].destinationFolder}
                    storageProviderId={integrations[0].storageProvider}
                    sx={{
                      marginLeft: theme.spacing(2),
                    }}
                  />

                  <Link
                    variant="subtitle2"
                    sx={{
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),
                      cursor: "pointer",
                    }}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      //  anchorElement.current = event.currentTarget;
                      setAnchorElement(event.currentTarget);
                      setOpenEditConnectedStorageMenu(true);
                    }}
                  >
                    {t("edit_action")}
                  </Link>

                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElement}
                    open={openEditConnectedStorageMenu}
                    onClose={() => {
                      setAnchorElement(null);
                      setOpenEditConnectedStorageMenu(false);
                    }}
                    MenuListProps={{
                      "aria-labelledby": "lock-button",
                      role: "listbox",
                    }}
                  >
                    {getMenuOptions(t).map((option, index) => (
                      <MenuItem
                        key={option.title}
                        onClick={(event) => handleMenuItemClicked(option.id)}
                      >
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        <Typography variant="subtitle2">
                          {" "}
                          {option.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
              <Typography
                variant="subtitle2"
                sx={{
                  marginLeft: theme.spacing(1),
                  color: "gray",
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                }}
              >
                •
              </Typography>
              <FileUploadIcon
                sx={{
                  color: "text.secondary",
                  width: "16px",
                  height: "16px",
                  marginLeft: theme.spacing(1),
                  [theme.breakpoints.down("md")]: {
                    marginLeft: theme.spacing(0),
                  },
                }}
              />
              <Typography
                variant="subtitle2"
                sx={{
                  color: "gray",
                  marginLeft: theme.spacing(0.5),
                }}
              >
                {retrieveEmailIntegration.data.emailIntegration.uploadsCount  + " " + t("uploads")}
              </Typography>
            </Box>
          )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!retrieveEmailIntegration.loading &&
            !retrieveEmailIntegration.error &&
            (!integrations || integrations.length == 0) && (
              <Tooltip title={t("connect_cloud_storage_hint")}>
                <Link
                  variant="subtitle2"
                  sx={{
                    marginLeft: theme.spacing(1),
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDisplayConnectToCloudModal(true);
                  }}
                >
                  {t("connect_cloud_storage_title")}
                </Link>
              </Tooltip>
            )}
        </Box>
      </Box>
      <Tabs
        sx={{
          marginTop: theme.spacing(2),
        }}
        value={tabValue}
        onChange={(event: React.SyntheticEvent, newValue: any) => {
          console.log("Got a change here " + JSON.stringify(newValue));
          setTabValue(newValue);
        }}
        variant="scrollable"
        scrollButtons={false}
      >
        <Tab value={TAB_INCOMING_EMAILS} label={t('email_upload_items_tab_title')} />
       {/*  <Tab
          value={TAB_INCOMING_EMAILS_SETTINGS}
          label={t("upload_link_settings_title")}
        /> */}
      </Tabs>
      {tabValue == TAB_INCOMING_EMAILS && <EmailUploadItemsContainer />}
      {/*     {tabValue == TAB_INCOMING_EMAILS_SETTINGS && uploadLink && (
          <UploadLinkSettingsContainer uploadLink={uploadLink} />
        )} */}

      {displayConnectToCloudModal && (
        <ConnectCloudToEmailForwardModal
          open={displayConnectToCloudModal}
          handleModalClose={() => {
            setDisplayConnectToCloudModal(false);
          }}
        />
      )}
    </Box>
  );
};


export default React.memo(EmailsForwardingContainer);
