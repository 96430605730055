import React, { useCallback, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "icons/favicon.png";
import Button from "@mui/material/Button";
import { activateAccount } from "api/authApi";

import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { LOGIN } from "navigation/Constants";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grow from "@mui/material/Grow";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, useTheme } from "@mui/material/styles";

import UploadlyIcon from "res/icons/uploadly-logo.svg";
import { Helmet } from "react-helmet-async";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  min-height: 100vh;
  padding-top: 8%;
  overflow-y: hidden;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    goToBoardButton: {
      fontSize: "3rem",
    },
  })
);

const AccountActivationPage: React.FC = () => {
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [activationFailed, setActivationFailed] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params: any = useParams();
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    activateAccount(params.uid, params.token)
      .then((statusCode) => {
        if (statusCode && statusCode == 200) {
          setActivationSuccess(true);
        } else {
          setActivationFailed(true);
        }
      })
      .catch((e) => {
        setActivationFailed(true);
      });
    return () => {
      console.log("Cleanup from login");
    };
  }, []);

  const handleGotoLogin = React.useCallback(() => {
    navigate(LOGIN);
  }, [navigate]);

  return (
    <MainContainer>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "12px",
          background: "#f8fbf8",
          borderRadius: "16px",
          minWidth: "30%",
          "@media (max-width: 780px)": {
            width: "80%",
          },
        }}
        elevation={3}
      >
        <Helmet>
          <title>{t("activate_account_page")} | Uploadly</title>
        </Helmet>
        <Link to="/">
          <img
            style={{ position: "absolute", top: "16px", left: "64px" }}
            height="48px"
            width="48px"
            src={UploadlyIcon}
          />
        </Link>

        {activationSuccess && !activationFailed && (
          <Grow in={activationSuccess} style={{ transformOrigin: "0 0 0" }}>
            <CheckCircleIcon
              style={{
                height: "148px",
                width: "148px",
                color: theme.palette.primary.main,
              }}
            />
          </Grow>
        )}
        {!activationSuccess && !activationFailed && (
          <Typography
            sx={{
              marginTop: "24px",
              marginBottom: "24px",
              textAlign: "center",
            }}
            variant="subtitle1"
            component="div"
            gutterBottom
          >
            {t("account_activation_ongoing")}
          </Typography>
        )}
        {activationSuccess && !activationFailed && (
          <Typography
            sx={{
              marginTop: "24px",
              marginBottom: "24px",
              textAlign: "center",
            }}
            variant="subtitle1"
            component="div"
            gutterBottom
          >
            {t("account_activation_success")}
          </Typography>
        )}
        {activationFailed && !activationSuccess && (
          <Typography
            sx={{
              marginTop: "24px",
              marginBottom: "24px",
              textAlign: "center",
            }}
            variant="subtitle1"
            component="div"
            gutterBottom
          >
            {t("account_activation_failed")}
          </Typography>
        )}
        {!activationSuccess && !activationFailed && (
          <CircularProgress size="64px" variant="indeterminate" />
        )}

        {activationSuccess && !activationFailed && (
          <Grow in={activationSuccess} style={{ transformOrigin: "0 0 0" }}>
            <Button
              className={classes.goToBoardButton}
              style={{
                marginTop: "32px",
                minWidth: "10%",
                width: "50%",
              }}
              size="large"
              onClick={handleGotoLogin}
            >
              {t("go_to_login_page")}
            </Button>
          </Grow>
        )}
      </Paper>
    </MainContainer>
  );
};

export default AccountActivationPage;
