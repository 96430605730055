import GoogleDriveIcon from "res/icons/google-drive-icon.png";
import DropBoxIcon from "res/icons/dropbox-logo.svg";
import OneDriveIcon from "res/icons/one-drive-logo.svg";
import { StorageProviderType } from "models/uploadly";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import FTPIcon from "res/icons/ftp-icon.svg";
import GooglePhotosIcon from "res/icons/google-photos.svg";

export const UPLOADLY_STORAGE = 0;
export const GOOGLE_DRIVE_INTEGRATION = 1;
export const DROPBOX_INTEGRATION = 3;
export const ONEDRIVE_INTEGRATION = 2;
export const FTP_INTEGRATION = 4;
export const GOOGLE_PHOTOS_INTEGRATION = 5;

export const getStorageRootLocation = (storagePoviderId: number) => {
  switch (storagePoviderId) {
    case GOOGLE_DRIVE_INTEGRATION:
      return "https://drive.google.com/";
    case DROPBOX_INTEGRATION:
      return "https://dropbox.com/home";
    case ONEDRIVE_INTEGRATION:
      return "https://onedrive.live.com/";
    case GOOGLE_PHOTOS_INTEGRATION:
      return "https://photos.google.com/";
    default:
      return "";
  }
};

export const getStorageProviderName = (storagePoviderId: number) => {
  switch (storagePoviderId) {
    case UPLOADLY_STORAGE:
      return "Uploadly";
    case GOOGLE_DRIVE_INTEGRATION:
      return "Google Drive";
    case GOOGLE_PHOTOS_INTEGRATION:
      return "Google Photos";
    case DROPBOX_INTEGRATION:
      return "Dropbox";
    case ONEDRIVE_INTEGRATION:
      return "OneDrive";
    case FTP_INTEGRATION:
      return "FTP";
    default:
      return "";
  }
};

interface SizeType {
  height: string;
  width: string;
}

export const getIconForStorageProvider = (
  storagePoviderId: number,
  size?: SizeType,
  style?: object
) => {
  switch (storagePoviderId) {
    case StorageProviderType.UPLOADLY_STORAGE:
      return (
        <img
          src={UploadlyIcon}
          style={{
            ...style,
            width: size ? size.width : "32px",
            height: size ? size.height : "32px",
          }}
        ></img>
      );
    case StorageProviderType.FTP:
      return (
        <img
          src={FTPIcon}
          style={{
            ...style,
            width: size ? size.width : "32px",
            height: size ? size.height : "32px",
          }}
        ></img>
      );
    case StorageProviderType.GOOGLE_DRIVE:
      return (
        <img
          src={GoogleDriveIcon}
          style={{
            ...style,
            width: size ? size.width : "32px",
            height: size ? size.height : "32px",
          }}
        ></img>
      );
    case StorageProviderType.GOOGLE_PHOTOS:
      return (
        <img
          src={GooglePhotosIcon}
          style={{
            ...style,
            width: size ? size.width : "32px",
            height: size ? size.height : "32px",
          }}
        ></img>
      );
    case StorageProviderType.DROPBOX:
      return (
        <img
          src={DropBoxIcon}
          style={{
            ...style,
            width: size ? size.width : "32px",
            height: size ? size.height : "32px",
          }}
        ></img>
      );
    case StorageProviderType.ONEDRIVE:
      return (
        <img
          src={OneDriveIcon}
          style={{
            ...style,
            width: size ? size.width : "32px",
            height: size ? size.height : "32px",
          }}
        ></img>
      );
    default:
      console.log("Not supported storagePoviderId" + storagePoviderId);
      return <></>;
  }
};
