import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grow,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  CreateSubscriptionResult,
  createSetupIntent,
  createSubscriptionForCustomer,
} from "api/stripeApi";
import Header from "components/ui/Header";
import {
  DEBUG,
  DEFAULT_CURRENCY,
  FRONT_URL,
  STRIPE_API_KEY,
} from "configuration";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, logoutUser } from "store/auth";
import UploadlyIcon from "res/icons/uploadly-logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { SubscriptionPlanIds, UserObj } from "models/user";
import {
  PAYMENT_ROUTING_BUSINESS_MONTHLY_PLAN_ID,
  PAYMENT_ROUTING_BUSINESS_YEARLY_PLAN_ID,
  PAYMENT_ROUTING_STANDARD_MONTHLY_PLAN_ID,
  PAYMENT_ROUTING_STANDARD_YEARLY_PLAN_ID,
  getBusinessTier,
  getStandardTier,
} from "shared/subscriptionPlans";
import { BorderColor } from "@mui/icons-material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { StripeElementsOptionsMode } from "@stripe/stripe-js";
import { PAYMENT, ROOT } from "navigation/Constants";
import { LoadingButton } from "@mui/lab";
import { getDateInOneMonth, getDateInOneYear } from "shared/dataUtils";
import { RootState } from "store";
import { fetchUserData } from "api/authApi";
import { getUserCurrency, setUserCurrency } from "localstorage/localstorage";
import { fetchUserCurrency } from "api/geoipApi";
import {
  getCurrencySymbol,
  getPriceInLocalCurrency,
  getYearlyPricePerMonthInLocalCurrency,
} from "shared/currencies";
const stripePromise = loadStripe(STRIPE_API_KEY!!);

const PaymentPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const [localCurrency, setLocalCurrency] = React.useState<string>(
    () => {
      const userCurrency = getUserCurrency();
      if (userCurrency != null){
        return userCurrency
      }
      return DEFAULT_CURRENCY
    }
  );

  const user = useSelector((state: RootState) => state.auth.user);

  React.useEffect(() => {
    if (!user && isUserLoggedIn) {
      fetchUserData()
        .then((user: UserObj) => {
          dispatch(loginUser(user));
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    }
  }, [user]);

  React.useEffect(() => {
    if (!params.id) {
      navigate(PAYMENT + "/1");
    }
  }, [params]);

  const [subscriptionPlanInformation, _] = useState(() => {
    if (
      params.id == PAYMENT_ROUTING_STANDARD_MONTHLY_PLAN_ID ||
      params.id == PAYMENT_ROUTING_STANDARD_YEARLY_PLAN_ID
    ) {
      return getStandardTier(t);
    } else if (
      params.id == PAYMENT_ROUTING_BUSINESS_MONTHLY_PLAN_ID ||
      params.id == PAYMENT_ROUTING_BUSINESS_YEARLY_PLAN_ID
    ) {
      return getBusinessTier(t);
    }
    return getStandardTier(t);
  });

  const getSubscriptionPlanIdFromParams = useCallback(() => {
    if (
      params.id == PAYMENT_ROUTING_STANDARD_MONTHLY_PLAN_ID ||
      params.id == PAYMENT_ROUTING_STANDARD_YEARLY_PLAN_ID
    ) {
      return SubscriptionPlanIds.STANDARD;
    } else if (
      params.id == PAYMENT_ROUTING_BUSINESS_MONTHLY_PLAN_ID ||
      params.id == PAYMENT_ROUTING_BUSINESS_YEARLY_PLAN_ID
    ) {
      return SubscriptionPlanIds.BUSINESS;
    }
    return SubscriptionPlanIds.STANDARD;
  }, [params]);

  const shouldCreateAnnualSubscription = useCallback(() => {
    if (
      params.id == PAYMENT_ROUTING_BUSINESS_YEARLY_PLAN_ID ||
      params.id == PAYMENT_ROUTING_STANDARD_YEARLY_PLAN_ID
    ) {
      return true;
    } else if (
      params.id == PAYMENT_ROUTING_BUSINESS_MONTHLY_PLAN_ID ||
      params.id == PAYMENT_ROUTING_STANDARD_MONTHLY_PLAN_ID
    ) {
      return false;
    }
    return false;
  }, [params]);

  const [selectedRecurrence, setSelectedRecurrence] = useState(
    shouldCreateAnnualSubscription()
      ? MonthlyAnnual.ANNUAL
      : MonthlyAnnual.MONTHLY
  );

  const getCurrentPriceToPay = useCallback(() => {
    if (selectedRecurrence == MonthlyAnnual.ANNUAL) {
      return subscriptionPlanInformation.yearlyPricingTotal;
    } else {
      return subscriptionPlanInformation.monthlyPricing;
    }
  }, [
    selectedRecurrence,
    subscriptionPlanInformation,
    getSubscriptionPlanIdFromParams,
    getStandardTier,
  ]);
 // console.log("local currency here is " + localCurrency)

  const [elementOptions, setElementOptions] = useState<
    StripeElementsOptionsMode
  >({
    // passing the client secret obtained from the server
    mode: "subscription",
    amount: getCurrentPriceToPay() * 100,
    currency: localCurrency.toLowerCase(),
    paymentMethodTypes: ["card"], // Explicitly specify supported payment methods if needed

    //clientSecret: clientSecret,
  });

  React.useEffect(() => {
    setElementOptions({
      ...elementOptions,
      amount: getCurrentPriceToPay() * 100,
    //  currency: localCurrency.toLowerCase(),
    });
  }, [selectedRecurrence, localCurrency]);

  React.useEffect(() => {
    const userCurrencyFromLocal = getUserCurrency();
    if (!userCurrencyFromLocal) {
      fetchUserCurrency().then((result: any) => {
        const currency = result.currency.code;
        setUserCurrency(currency);
        setLocalCurrency(currency);
      });
    }
  }, []);

  const getCurrentPriceToPayAsString = useCallback(() => {
    if (selectedRecurrence == MonthlyAnnual.ANNUAL) {
      return (
        getCurrencySymbol(localCurrency) +
        getPriceInLocalCurrency(
          localCurrency,
          subscriptionPlanInformation.id,
          true
        )
      );
    } else {
      return (
        getCurrencySymbol(localCurrency) +
        getPriceInLocalCurrency(
          localCurrency,
          subscriptionPlanInformation.id,
          false
        )
      );
    }
  }, [selectedRecurrence, getSubscriptionPlanIdFromParams, getStandardTier]);

  const getSubscriptionInformation = useCallback(() => {
    if (
      params.id == PAYMENT_ROUTING_STANDARD_MONTHLY_PLAN_ID ||
      params.id == PAYMENT_ROUTING_STANDARD_YEARLY_PLAN_ID
    ) {
      return getStandardTier(t);
    } else if (
      params.id == PAYMENT_ROUTING_BUSINESS_MONTHLY_PLAN_ID ||
      params.id == PAYMENT_ROUTING_BUSINESS_YEARLY_PLAN_ID
    ) {
      return getBusinessTier(t);
    }
    return getStandardTier(t);
  }, [params, getSubscriptionPlanIdFromParams]);

  if (paymentSuccess) {
    return (
      <>
        <Helmet>
          <title>{t("payment_title")} | Uploadly</title>
        </Helmet>
        <Header />
        <img
          style={{ position: "absolute", top: "16px", left: "64px" }}
          height="48px"
          width="48px"
          src={UploadlyIcon}
          onClick={() => {
            navigate(ROOT);
          }}
        />
        <PaymentSuccessPage
          paiedPrice={getCurrentPriceToPay()}
          currency={getCurrencySymbol(localCurrency)}
          planRecurrence={selectedRecurrence}
          newPlanName={subscriptionPlanInformation.fullTitle}
        />
      </>
    );
  }
  return (
    <Box>
      <Helmet>
        <title>{t("payment_title")} | Uploadly</title>
      </Helmet>
      <Header />
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          px: theme.spacing(8),
          py: theme.spacing(8),
          [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse",
            px: theme.spacing(0),
          },
        }}
      >
        <img
          style={{ position: "absolute", top: "16px", left: "64px" }}
          height="48px"
          width="48px"
          src={UploadlyIcon}
          onClick={() => {
            navigate(ROOT);
          }}
        />
        <Box
          sx={{
            height: "100%",
            width: "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            [theme.breakpoints.down("md")]: {
              width: "80%",
              marginTop: theme.spacing(4),
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginBottom: theme.spacing(2),
              color: "gray",
            }}
          >
            {t("almost_complete_title")}
          </Typography>
          <Elements stripe={stripePromise} options={elementOptions}>
            <CheckoutForm
              priceToPay={getCurrentPriceToPayAsString()}
              onPaymentSuccess={() => {
                setPaymentSuccess(true);
              }}
              subscriptionPlanId={getSubscriptionPlanIdFromParams()}
              billAnually={selectedRecurrence == MonthlyAnnual.ANNUAL}
              currency={localCurrency}
            />
          </Elements>
        </Box>
        <Divider
          sx={{
            mx: theme.spacing(8),
            background: "#f2f0f0",
            width: "1px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
            [theme.breakpoints.down("md")]: {
              width: "80%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h3">
              {subscriptionPlanInformation.fullTitle}
            </Typography>

            <MonthlyAnnualPicker
              defaultSelected={
                shouldCreateAnnualSubscription()
                  ? MonthlyAnnual.ANNUAL
                  : MonthlyAnnual.MONTHLY
              }
              onChange={(value: MonthlyAnnual) => {
                setSelectedRecurrence(value);
              }}
            />
            <Card
              sx={{
                px: theme.spacing(2),
                py: theme.spacing(2),
                my: theme.spacing(2),
              }}
            >
              {selectedRecurrence == MonthlyAnnual.ANNUAL ? (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      variant="h3"
                    >
                      {getCurrencySymbol(localCurrency)}
                      {getYearlyPricePerMonthInLocalCurrency(
                        localCurrency,
                        subscriptionPlanInformation.id
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: theme.spacing(0.5),
                        color: "gray",
                        display: "flex",
                        justifyContent: "bottom",
                        flexDirection: "column",
                        height: "100%",
                        alignItems: "bottom",
                        textAlign: "bottom",
                      }}
                      variant="subtitle2"
                    >
                      /{t("month")}
                    </Typography>

                    <ArrowRightAltIcon
                      sx={{
                        color: "gray",
                        mx: theme.spacing(3),
                      }}
                    />

                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      variant="h3"
                    >
                      {getCurrencySymbol(localCurrency)}
                      {getPriceInLocalCurrency(
                        localCurrency,
                        subscriptionPlanInformation.id,
                        true
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: theme.spacing(1),
                        color: "gray",
                      }}
                      variant="subtitle2"
                    >
                      {" "}
                      /{t("year")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  ></Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "bottom",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      variant="h3"
                    >
                     {getCurrencySymbol(localCurrency)}
                      {getPriceInLocalCurrency(
                        localCurrency,
                        subscriptionPlanInformation.id,
                        false
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: theme.spacing(1),
                        color: "gray",
                      }}
                      variant="subtitle2"
                    >
                      {" "}
                      /{t("month")}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Card>
          </Box>
          <Box
            sx={{
              marginTop: theme.spacing(4),
            }}
          >
            <Typography
              sx={{
                marginBottom: theme.spacing(2),
              }}
            >
              {t("what_you_get_title")}
            </Typography>
            {subscriptionPlanInformation.description.map(
              (descriptionItem: any) => {
                return (
                  <Container
                    sx={{
                      display: "flex",
                    }}
                  >
                    <CheckCircleIcon
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography
                      sx={{
                        marginLeft: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                      }}
                    >
                      {descriptionItem}
                    </Typography>
                  </Container>
                );
              }
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

enum MonthlyAnnual {
  ANNUAL = "0",
  MONTHLY = "1",
}
interface MonthlyAnnualPickerProps {
  defaultSelected?: MonthlyAnnual;
  onChange: (value: MonthlyAnnual) => void;
}

const MonthlyAnnualPicker = ({
  defaultSelected = MonthlyAnnual.MONTHLY,
  onChange,
}: MonthlyAnnualPickerProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelected as string);
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="female"
      name="radio-buttons-group"
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        alignItems: "center",
      }}
      value={selectedValue}
      onChange={(e) => {
        setSelectedValue((e.target as HTMLInputElement).value);
        onChange((e.target as HTMLInputElement).value as any);
      }}
    >
      <FormControlLabel
        value={MonthlyAnnual.MONTHLY}
        control={<Radio />}
        label={t("monthly_title")}
      />
      <FormControlLabel
        value={MonthlyAnnual.ANNUAL}
        control={<Radio />}
        label={t("annual_title")}
        sx={{
          marginRight: "4px",
        }}
      ></FormControlLabel>
      <Typography
        sx={{
          background: "#86f7a4",
          color: "green",
          display: "flex",
          borderRadius: "8px",
          px: theme.spacing(1),
          py: theme.spacing(0.4),
          fontSize: ".7rem",
        }}
        variant="caption"
      >
        {" "}
        {t("save_money")} 22%
      </Typography>
    </RadioGroup>
  );
};

interface CheckoutForm {
  priceToPay: string;
  currency: string,
  onPaymentSuccess: () => void;
  subscriptionPlanId: number;
  billAnually: boolean;
}
const CheckoutForm = ({
  priceToPay,
  onPaymentSuccess,
  subscriptionPlanId,
  billAnually,
  currency,
}: CheckoutForm) => {
  const elements = useElements();
  const theme = useTheme();
  const { t } = useTranslation();
  const stripe = useStripe();
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const [displayPaymentError, setDisplayPaymentError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(t("payment_error_invalid_card"));

  const dispatch = useDispatch();

  const handlePayment = () => {
    if (!stripe || !elements) {
      return;
    }

    elements.submit().then((result) => {
      if (result.error) {
        return;
      }
      setDisplayPaymentError(false);
      setIsPaymentLoading(true);

      console.log("elements submit result is   %o", result);
      createSubscriptionForCustomer(currency, subscriptionPlanId, billAnually)
        .then((data: CreateSubscriptionResult) => {
          if (DEBUG) {
            console.log("createSubscriptionForCustomer: result is %o", data);
          }
          if (data.result == "0") {
            //here the subscription was already active
            //Success
            onPaymentSuccess();
          } else if (data.result == "1") {
            const clientSecret = data.clientSecret;
            stripe
              .confirmPayment({
                elements,
                clientSecret,
                redirect: "if_required", // To handle the result within the app
              })
              .then((result) => {
                console.log("confirm confirmed result %o", result);
                setIsPaymentLoading(false);
                if (result.error) {
                  //alert(result.error.message);
                  setErrorMsg(t("payment_error_failed_to_confirm_payment"));
                  setDisplayPaymentError(true);
                } else {
                  // Successful subscription payment
                  if (result.paymentIntent.status == "succeeded") {
                    //onSubscriptionSuccess();
                    onPaymentSuccess();
                  }
                }
              })
              .catch((e) => {
                if (DEBUG) {
                  console.log("confirmPayment: got an error %e", e);
                }
                setIsPaymentLoading(false);
              });
          } else if (data.result == "2") {
            //User is already subscribed and subscription is ok
            setIsPaymentLoading(false);
            setErrorMsg(t("payment_error_already_subscribed"));
            setDisplayPaymentError(true);
          } else if (data.result == "3") {
            //Payment passed but failed to provision the product
            setIsPaymentLoading(false);
            setErrorMsg(t("payment_error_failed_to_provision"));
            setDisplayPaymentError(true);
          }
        })
        .catch((err) => {
          console.log("caught an error %o", err);
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
          setIsPaymentLoading(false);

          setErrorMsg(t("payment_error_failed_toreach_backend"));
          setDisplayPaymentError(true);
          setIsPaymentLoading(false);
        });
    });
  };

  return (
    <>
      {displayPaymentError && (
        <Alert severity="error" style={{ marginBottom: "18px" }}>
          {errorMsg}
        </Alert>
      )}
      <PaymentElement />
      <LoadingButton
        variant="contained"
        sx={{
          marginTop: theme.spacing(2),
        }}
        onClick={handlePayment}
        loading={isPaymentLoading}
      >
        {t("pay_action") + " " + priceToPay}
      </LoadingButton>
    </>
  );
};

interface PaymentSuccessPageProps {
  paiedPrice: number;
  currency: string;
  planRecurrence: MonthlyAnnual;
  newPlanName: string;
}

const PaymentSuccessPage = ({
  paiedPrice,
  currency,
  planRecurrence,
  newPlanName,
}: PaymentSuccessPageProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Slide
      addEndListener={(node, done) => {
        node.addEventListener(
          "transitionend",
          (e) => {
            /*  if (!displayCheckoutForm) {
              setDisplayCheckIcon(true);
            } */
            done();
          },
          false
        );
      }}
      direction="down"
      in={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "40%",
            padding: "36px",
            border: "2px solid #f7f7f7",
          }}
        >
          <Grow in={true}>
            <CheckCircleIcon
              sx={{
                width: "128px",
                height: "128px",
                color: theme.palette.primary.main,
              }}
            />
          </Grow>
          <Typography
            style={{ marginBottom: "32px" }}
            component="h2"
            variant="h5"
            color={theme.palette.primary.main}
          >
            {t("payment_successful_msg")}
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ marginTop: "16px" }}
              variant="h6"
              color="text.secondary"
            >
              {t("payment_successful_paid_plan_title")}
            </Typography>

            <Typography
              style={{ marginTop: "16px" }}
              variant="h6"
              color="text.secondary"
            >
              {newPlanName}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ marginTop: "16px" }}
              variant="h6"
              color="text.secondary"
            >
              {t("payment_successful_amount_paid_title")}
            </Typography>

            <Typography
              style={{ marginTop: "16px" }}
              variant="h6"
              color="text.secondary"
            >
              {paiedPrice + currency}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ marginTop: "16px" }}
              variant="h6"
              color="text.secondary"
            >
              {t("payment_successful_next_debit_title")}
            </Typography>

            <Typography
              style={{ marginTop: "16px" }}
              variant="h6"
              color="text.secondary"
            >
              {planRecurrence == MonthlyAnnual.MONTHLY
                ? getDateInOneMonth()
                : getDateInOneYear()}
            </Typography>
          </Box>
          <Typography
            style={{ marginTop: "48px", textAlign: "center" }}
            component="h4"
            variant="h6"
            color="text.primary"
          >
            {t("payment_subscription_confirmed_msg")}
          </Typography>
          <Typography
            style={{ marginTop: "8px", textAlign: "center" }}
            component="h4"
            variant="h6"
            color="text.primary"
          >
            {t("payment_enjoy_msg")}
          </Typography>
          <Button
            sx={{
              marginTop: "32px",
            }}
            size="large"
            variant="outlined"
            onClick={() => {
              navigate(ROOT);
            }}
          >
            {t("payment_go_board_btn_msg")}
          </Button>
        </Paper>
      </div>
    </Slide>
  );
};
export default PaymentPage;
