import React from "react";
import { PRIVACY_POLICY } from "navigation/Constants";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link, Typography, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Header from "components/ui/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { fetchUserData } from "api/authApi";
import { UserObj } from "models/user";
import { loginUser, logoutUser } from "store/auth";
import UploadlyIcon from "res/icons/uploadly-logo.svg";

const MainContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding-right: 5%;
  padding-left: 15%;
  margin-bottom: 5%;
  @media (max-width: 780px) {
    padding-left: 8%;
  }
`;
const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 780px) {
    width: 90%;
  }
`;

const Space = styled.section`
  height: 7%;
`;

const TEXT_CONTENT = [
  {
    title: "",
    paragraphs: [
      "This page is used to inform visitors about our policies regarding the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. If you don't agree with the current policy as presently stated, please do not use any of our services.",
      " The present policy might be subject to changes in the future for various reasons. When the change is significant, we will make sure that you aware of it and eventually reach out for your consent when necessary.",
    ],
  },
  {
    title: "The information we collect about you",
    paragraphs: ["", "", ""],
  },
];

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  //const history = useTranslation();
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );
  const theme = useTheme();
  /*   React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []); */
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  React.useEffect(() => {
    if (!user && isUserLoggedIn) {
      fetchUserData()
        .then((user: UserObj) => {
          dispatch(loginUser(user));
        })
        .catch((err) => {
          console.log("Caught an error while fetch user data" + err);
          if (err?.message?.includes("403")) {
            dispatch(logoutUser());
          }
        });
    }
  }, [user]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2),
      }}
    >
      <Helmet>
        <title
          style={{
            textTransform: "capitalize",
          }}
        >
          {t("privacy_policy")} | Uploadly
        </title>
      </Helmet>
      <img
        style={{ position: "absolute", top: "16px", left: "64px" }}
        height="48px"
        width="48px"
        src={UploadlyIcon}
      />
      {isUserLoggedIn && <Header />}
      <Typography
        variant="h2"
        sx={{
          fontSize: "5rem",
          fontFamily: "Trebuchet MS, sans-serif",
          textAlign: "center",
          marginTop: "5%",
          marginBottom: "10%",
        }}
      >
        Our privacy policy
      </Typography>
      <Space style={{ height: "20%" }} />
      <MainContainer>
        {/* {TEXT_CONTENT.map((content) => {
          return ( 
            <>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "3rem",
                  fontWeight: 530,
                  fontFamily: "Trebuchet MS, sans-serif",
                }}
              >
                {content.title}
              </Typography>
              {content.paragraphs.map((paragraph) => {
                return (
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Trebuchet MS, sans-serif",
                      marginTop: "2%",
                      fontSize: "2rem",
                    }}
                  >
                    {paragraph}
                  </Typography>
                );
              })}
            </>
          );
        })} */}

        <BodyContainer>
          <Typography
            variant="h3"
            sx={{
              fontSize: "1.6rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            <span style={{ background: "white", color: "black" }}>
              Effective from
            </span>{" "}
            July 4th, 2024
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            This Privacy Policy describes how Uploadly ("we," "us," or "our")
            collects, uses, and discloses your information when you use our
            service ("Service"). It also describes the choices you have
            associated with your information and how you can contact us. If you
            don't agree with the current policy as presently stated, please do
            not use any of our services.
          </Typography>
          <br />
          <Typography
            variant="subtitle2"
            sx={{ fontFamily: "Trebuchet MS, sans-serif" }}
          >
            The present policy might be subject to changes in the future for
            various reasons. When the change is significant, we will make sure
            that you are aware of it and eventually reach out for your consent
            when necessary.
          </Typography>

          <Space style={{ marginTop: "1%" }} />
          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            The information we collect about you
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "2%",
            }}
          >
            We collect several different types of information for various
            purposes to improve our Service to you.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "2.4rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
            }}
          >
            User Information:{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • Account Information: When you create an Uploadly account, we
            collect your email, first name, last name, and a password you
            choose.
          </Typography>
          <br />
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • Payment Information: We use Stripe, a third-party payment
            processor, to process payments for our subscription plans. We do not
            directly store your credit card information. Please refer to
            Stripe's Privacy Policy{" "}
            <Link
              style={{
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://stripe.com/privacy", "_blank");
              }}
            >
              here
            </Link>{" "}
            for more information on how they handle your payment information.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              fontSize: "2.4rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
            }}
          >
            Upload Link Information:{" "}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • Uploaded Files: You can choose to allow uploaders to upload files
            directly to your cloud storage solution (e.g., Google Drive, Google Photos,
            Dropbox, OneDrive). We do not access or store the content of these
            files unless you choose to enable a copy to be stored within
            Uploadly.
          </Typography>
          <br />
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • Uploader Information: When someone uploads a file using an upload
            link, they may optionally provide their name, email address and
            eventually other information if the upload link creator requests it.
            This information is collected for the benefit of the upload link
            creator, and allows to identify who uploaded each file. This
            information is retained as long as the Uploaded file remains within
            the Upload link creator dashboard, until they choose to delete the
            specific uploaded item or delete their entire file.
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Third party storage access
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            Uploadly integrates with Google Drive, OneDrive, Google Photos, and Dropbox to
            provide seamless file uploads directly to your chosen cloud storage
            solution. Here’s how we manage these integrations:
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            Uploadly integrates with Google Drive, Google Photos, OneDrive, and Dropbox to
            provide seamless file uploads directly to your chosen cloud storage
            solution. Here’s how we manage these integrations:
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "2.4rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
            }}
          >
            Access Tokens and Permissions:{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            To upload files to your cloud storage account, we require permission
            to access your Google Drive, Google Photos, OneDrive, or Dropbox account. When you
            connect your cloud storage account to Uploadly, we securely store
            the access tokens and any necessary information needed to interact
            with the respective APIs. These tokens allow Uploadly to upload
            files to your cloud storage on your behalf
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "2.4rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
            }}
          >
            Secure Storage and Access:{" "}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • Access Tokens: Access tokens are securely stored using
            industry-standard encryption methods. This ensures that only
            authorized Uploadly systems can access your cloud storage accounts.
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • Minimal Permissions: We request the minimal permissions necessary
            to perform the upload operations. We do not access your files or
            folders unless you explicitly upload them through our service.
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • Revocation: You can revoke Uploadly’s access to your cloud storage
            at any time by managing your account settings in Google Drive, Google Photos,
            OneDrive, or Dropbox. Please refer to the respective provider’s
            instructions on how to revoke third-party access.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "2.4rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
            }}
          >
            Data Usage and Sharing:{" "}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • File Uploads: Files uploaded via Uploadly are sent directly to
            your connected cloud storage provider. A copy of the files may also
            be stored on Uploadly’s servers if you choose this option.
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • User Information: Information such as names and email addresses
            collected during the upload process is stored securely and only
            accessible to the user who created the upload link.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              fontSize: "2.4rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
            }}
          >
            Third Party Data Retention
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            Uploadly retains access tokens and any necessary data to facilitate
            file uploads to your Google Drive, Google Photos, OneDrive, Dropbox and other
            storage providers only as long as your account remains active or as
            needed to provide our services. Access token are retrieved each time
            you connect one of your external storages to Uploadly. When you
            grant Uploadly access to your external storage, you can at any time
            revoke that access through the following :
            <br />
            <br />
            • Account Deletion: If you delete your Uploadly account, we will
            delete all associated data, including access tokens and information
            to your external storages and any stored files in your Uploadly
            storage only within a reasonable timeframe.
            <br />
            • Revoking Access: You can revoke Uploadly’s access to your cloud storage
             or at any time through your Google or any other external storage
            account settings. Once access is revoked, Uploadly will no longer
            have the ability to upload files to your external storage, and will
            require again your permission to be able to do so.
            <br />• User-Initiated Deletion: Each external storage you connect
            to your Uploadly can be disconnected upon user action from the
            "Connected Storages" dashboard, which will lead to the complete
            deletion of access to that specific storage.You can also request the
            deletion of specific data at any time by contacting us at
            support@uploadly.io. We will process such requests in accordance
            with applicable laws and our data retention policies.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              fontSize: "2.4rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
            }}
          >
            Third-Party Privacy Policies:{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            By using our service, you acknowledge that your data will be handled
            in accordance with the privacy policies of Google Drive, Google Photos, OneDrive,
            and Dropbox. We encourage you to review their privacy policies to
            understand how they manage and protect your data. If you have any
            questions or concerns about our use of third-party APIs or how we
            handle your access tokens and information, please contact us at
            support@uploadly.io
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            How We Use Your Information
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            • Provide and maintain our services.
            <br />
            • Process transactions and send related information, including
            purchase confirmations and invoices.
            <br />
            • Communicate with you, including responding to your comments,
            questions, and requests.
            <br />
            • Monitor and analyze trends, usage, and activities in connection
            with our services.
            <br />
            • Personalize and improve our services, including providing content
            or features that match user profiles or interests.
            <br />
            • Enforce our terms and conditions and comply with legal
            requirements.
            <br />
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            How We Share Your Information
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            We do not sell or rent your personal information to third parties.
            We may share information about you as follows:
            <br />• For Legal Reasons: We may disclose your information if we
            believe it is necessary to comply with a legal obligation, protect
            our rights, or prevent fraud or other illegal activities.
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Data Storage and Security
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Trebuchet MS, sans-serif",
              paddingLeft: "2%",
            }}
          >
            We use industry-standard security measures to protect your
            information. However, no system is completely secure, and we cannot
            guarantee the absolute security of your information.{" "}
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontSize: "3rem",
              fontWeight: 530,
              fontFamily: "Trebuchet MS, sans-serif",
              marginTop: "3%",
              marginBottom: "3%",
              color: theme.palette.primary.main,
            }}
          >
            Contact Us
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Treb uchet MS, sans-serif",
              paddingLeft: "2%",
              marginBottom: theme.spacing(8),
            }}
          >
            If you have any questions or concerns about this Privacy Policy,
            please contact us at: <br />
            Email: support@uploadly.io <br />
            Thank you for choosing Uploadly. Your privacy is very important to
            us, and we are committed to protecting your personal information.
          </Typography>

          <Space />
          <Space />
          <Space />
        </BodyContainer>
      </MainContainer>
    </div>
  );
};

export default React.memo(PrivacyPolicy);
