import axios, { AxiosResponse } from "axios";
import { DEBUG, GEOIP_IP_API_KEY } from "configuration";


export interface CurrencyResult {
    result: string, 
}

export const fetchUserCurrency = async (): Promise<CurrencyResult> => {
    try {
        let res = await axios.get("https://api.ipgeolocation.io/ipgeo?apiKey=" +GEOIP_IP_API_KEY,{
           
        })
        if (DEBUG) {
            console.log("getLocalCurrency " + JSON.stringify(res));
        }
        console.log("getLocalCurrency " + JSON.stringify(res));
        return res.data

    } catch (e) {
        throw (e)
    }
}