import axios from "axios";
import { DEBUG, SERVER_URL } from "configuration";
import Cookies from "js-cookie";

const DROPBOX_AUTH_USER_API_SUBPATH = "/integrations/dropbox-auth";
const UPDATE_DROPBOX_DESTINATION_FOLDER_API_SUBPATH =
  "/integrations/dropbox-dest-folder";

export interface AuthenticateDropboxUserResponse {
  resultCode: number;
  integrationId: string;
}

export interface UpdateDropboxDestinationFolderResponse {
  resultCode: number;
  success: boolean;
}

export const updateDropboxDestinationFolder = async (
  integrationId: string,
  folderName: string,
  folderId: string,
  folderUrl: string
): Promise<UpdateDropboxDestinationFolderResponse> => {
  try {
    let res = await axios.post(
      SERVER_URL + UPDATE_DROPBOX_DESTINATION_FOLDER_API_SUBPATH,
      {
        id: integrationId,
        folder_name: folderName,
        folder_url: folderUrl,
        folder_id: folderId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log(
        "updateOnedriveDestinationFolder: result" + JSON.stringify(res)
      );
    }
    return {
      resultCode: res.status,
      success: res.data.success,
    };
  } catch (e) {
    throw e;
  }
};

export const authenticateDropboxUser = async (
  code: string
): Promise<AuthenticateDropboxUserResponse> => {
  try {
    let res = await axios.post(
      SERVER_URL + DROPBOX_AUTH_USER_API_SUBPATH,
      {
        code: code,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"), // Include CSRF token in headers
        },
        withCredentials: true,
      }
    );
    if (DEBUG) {
      console.log("authenticateOnedriveUser: result" + JSON.stringify(res));
    }
    return {
      resultCode: res.status,
      integrationId: res.data.id,
    };
  } catch (e) {
    console.log("Momo got an error here %o", e);
    throw e;
  }
};
